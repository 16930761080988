import { useState, useEffect,useRef } from "react";

import icon_comment from "../../assets/comment.png";
import deleteIcon from "../../assets/delete.png";
import plusIcon from "../../assets/plus.png";
import ".././node.css";
import PopUpHeader from '../components/popUpAlertHeader.js';
import { Position } from "reactflow";
import StatusAlert, { StatusAlertService } from "react-status-alert";
import PDF from '../../assets/PDF.png'; 

import MessageOutlinedIcon from '@mui/icons-material/MessageOutlined';
import UploadIcon from '@mui/icons-material/Upload';
import LinkIcon from '@mui/icons-material/Link';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';

//! add and remove
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import RemoveCircleRoundedIcon from '@mui/icons-material/RemoveCircleRounded';
//! options
import BlockIcon from '@mui/icons-material/Block';
import TextFieldsSharpIcon from '@mui/icons-material/TextFieldsSharp';
import InsertPhotoSharpIcon from '@mui/icons-material/InsertPhotoSharp';
import VideoLibrarySharpIcon from '@mui/icons-material/VideoLibrarySharp';
import FilePresentSharpIcon from '@mui/icons-material/FilePresentSharp';


const getId = () => {
  return `dndnode_${Math.random().toString(36).substr(2, 9)}`;
};
function AskButtonOptionNode({ onValueChange, editData }) {
  const [bodyText, setBodyText] = useState("");
  const [footerText, setFooterText] = useState("");
  const [headerType, setHeaderType] = useState("None");
  const [optionType, setSelectedOption] = useState("");

  // const [headerText, setSelectedHeaderText] = useState('');
  const [headerData, setSelectedHeaderData] = useState("");
  const [selectedMediaSource, setSelectedMediaSource] = useState("link");
  const [selectedFile, setSelectedFile] = useState(null);
  // const [selectedURL, setSelectedURL] = useState('');

  const [isBodyBold, setisBodyBold] = useState(false);
  const [isBodyItalic, setisBodyItalic] = useState(false);
  const [isBodyStrikethrough, setisBodyStrikethrough] = useState(false);
  const [isBodyMonoSpace, setIsMonoSpace] = useState(false);

  const [buttons, setButtons] = useState([{ id: getId(), text: "" }]);

  const textareaRef = useRef(null);


 //  add selectedMediaSource in onChange
 // call logic in slider save
 // validate button select
 //   parse the seletec file
 // todo : parse the seletec url
 // todo : get the flie & url in data
 // todo : validate in slider add add
 function getFileNameFromUrl(url) { 
  const urlObject = new URL(url); 
  const pathname = urlObject.pathname; 
  const fileName = pathname.split('/').pop();
  
  return fileName;
}

  useEffect(() => {
    // data has value
    const hasEditData = Boolean(editData);
    if (hasEditData) {
      const {
        headerType,
        headerData,
        bodyText,
        buttons,
        footerText,
        optionType,
      } = editData;
      console.log("editData :", editData);

      setFooterText(footerText);
      setBodyText(bodyText);
      setButtons(buttons);

      setHeaderType(headerType);
      setSelectedOption(optionType);
      setSelectedHeaderData(headerData);
      // setSelectedHeaderData(headerData);


      onValueChange({
        headerType: headerType,
        headerData :headerData,
        bodyText :bodyText,
        footerText:footerText,
        optionType:optionType,
        //  isBodyBold,
        //  isBodyItalic,
        //  isBodyStrikethrough,
        buttons:buttons,
        mediaSource:'link',
        file:selectedFile
      });
    }
  }, [editData]);

  const handleHeaderOptionChange = (event) => {
    setSelectedHeaderData('');
    // const value = event.currentTarget.getAttribute('value');
    const headerOption = event.currentTarget.getAttribute('value');
    setHeaderType(headerOption);
    onValueChange({
      headerType: headerOption,
      headerData,
      bodyText,
      footerText,
      optionType,
      //  isBodyBold,
      //  isBodyItalic,
      //  isBodyStrikethrough,
      buttons,
      mediaSource:selectedMediaSource,
      file:selectedFile
    });
  };

  const handleOptionChange = (event) => {
    const headerOptionChange = event.target.value;
    setSelectedOption(headerOptionChange);
    onValueChange({
      headerType,
      headerData,
      bodyText,
      footerText,
      optionType: headerOptionChange,
      //  isBodyBold,
      //  isBodyItalic,
      //  isBodyStrikethrough,
      buttons,
      mediaSource:selectedMediaSource,
      file:selectedFile
    });
  };

  const toggleBold = () => {
    const textarea = textareaRef.current;
    const start = textarea.selectionStart;
    const end = textarea.selectionEnd;

    //!
    // Check if cursor is inside a bold section
    if (bodyText[start - 1] === '*' && bodyText[end] === '*') {
      // Remove bold markers around the cursor
      const updatedText = bodyText.slice(0, start - 1) + bodyText.slice(start, end) + bodyText.slice(end + 1);
      setBodyText(updatedText);
      onValueChange({
        headerType,
        headerData,
        bodyText: updatedText ,
        footerText,
        optionType, 
        buttons,
        mediaSource:selectedMediaSource,
        file:selectedFile
      }); 
      textarea.focus();
      textarea.setSelectionRange(start - 1, end - 1);
    } else {
      // Add bold markers around the cursor
      const updatedText = bodyText.slice(0, start) + '*' + bodyText.slice(start, end) + '* ' + bodyText.slice(end);
      setBodyText(updatedText);
      onValueChange({
        headerType,
        headerData,
        bodyText: updatedText ,
        footerText,
        optionType, 
        buttons,
        mediaSource:selectedMediaSource,
        file:selectedFile
      });  
      textarea.focus();
      textarea.setSelectionRange(start + 1, end + 1);
    }
 
  };


  const toggleItalic = () => {

    const textarea = textareaRef.current;
    const start = textarea.selectionStart;
    const end = textarea.selectionEnd;

      // Check if cursor is inside a bold section
      if (bodyText[start - 1] === "_" && bodyText[end] === "_") {
        // Remove bold markers around the cursor
        const updatedText = bodyText.slice(0, start - 1) + bodyText.slice(start, end) + bodyText.slice(end + 1);
        setBodyText(updatedText);
        onValueChange({
          headerType,
          headerData,
          bodyText: updatedText ,
          footerText,
          optionType, 
          buttons,
          mediaSource:selectedMediaSource,
          file:selectedFile
        }); 
        textarea.focus();
        textarea.setSelectionRange(start - 1, end - 1);
      } else {
        // Add bold markers around the cursor
        const updatedText = bodyText.slice(0, start) + "_" + bodyText.slice(start, end) + "_ " + bodyText.slice(end);
        setBodyText(updatedText);
        onValueChange({
          headerType,
          headerData,
          bodyText: updatedText ,
          footerText,
          optionType, 
          buttons,
          mediaSource:selectedMediaSource,
          file:selectedFile
        });  
        textarea.focus();
        textarea.setSelectionRange(start + 1, end + 1);
      }
 
  };
  const toggleMonoSpace = () => {

    const textarea = textareaRef.current;
    const start = textarea.selectionStart;
    const end = textarea.selectionEnd;

     // Check if cursor is inside a bold section
     if (bodyText[start - 1] === "```" && bodyText[end] === "```") {
      // Remove bold markers around the cursor
      const updatedText = bodyText.slice(0, start - 1) + bodyText.slice(start, end) + bodyText.slice(end + 1);
      setBodyText(updatedText);
      onValueChange({
        headerType,
        headerData,
        bodyText: updatedText ,
        footerText,
        optionType, 
        buttons,
        mediaSource:selectedMediaSource,
        file:selectedFile
      }); 
      textarea.focus();
      textarea.setSelectionRange(start - 1, end - 1);
    } else {
      // Add bold markers around the cursor
      const updatedText = bodyText.slice(0, start) + "```" + bodyText.slice(start, end) + "``` " + bodyText.slice(end);
      setBodyText(updatedText);
      onValueChange({
        headerType,
        headerData,
        bodyText: updatedText ,
        footerText,
        optionType, 
        buttons,
        mediaSource:selectedMediaSource,
        file:selectedFile
      });  
      textarea.focus();
      textarea.setSelectionRange(start + 1, end + 1);
    }

  };
  const toggleStrikethrough = () => {
    const textarea = textareaRef.current;
    const start = textarea.selectionStart;
    const end = textarea.selectionEnd;

     // Check if cursor is inside a bold section
     if (bodyText[start - 1] === "~" && bodyText[end] === "~") {
      // Remove bold markers around the cursor
      const updatedText = bodyText.slice(0, start - 1) + bodyText.slice(start, end) + bodyText.slice(end + 1);
      setBodyText(updatedText);
      onValueChange({
        headerType,
        headerData,
        bodyText: updatedText ,
        footerText,
        optionType, 
        buttons,
        mediaSource:selectedMediaSource,
        file:selectedFile
      }); 
      textarea.focus();
      textarea.setSelectionRange(start - 1, end - 1);
    } else {
      // Add bold markers around the cursor
      const updatedText = bodyText.slice(0, start) + "~" + bodyText.slice(start, end) + "~ " + bodyText.slice(end);
      setBodyText(updatedText);
      onValueChange({
        headerType,
        headerData,
        bodyText: updatedText ,
        footerText,
        optionType, 
        buttons,
        mediaSource:selectedMediaSource,
        file:selectedFile
      });  
      textarea.focus();
      textarea.setSelectionRange(start + 1, end + 1);
    }
 
  };

  const onChange = (event) => {
    const newText = event.target.value;
    if (newText.length <= 1024) {
      setBodyText(newText);
      onValueChange({
        headerType,
        headerData,
        bodyText: newText,
        footerText,
        optionType,
        //  isBodyBold,
        //  isBodyItalic,
        //  isBodyStrikethrough,
        buttons,
        mediaSource:selectedMediaSource,
        file:selectedFile
      });
    }
  };
  const onChangeFooter = (event) => {
    const newText = event.target.value;
    if (newText.length <= 60) {
      setFooterText(newText);
      onValueChange({
        headerType,
        headerData,
        bodyText,
        footerText: newText,
        optionType,
        //  isBodyBold,
        //  isBodyItalic,
        //  isBodyStrikethrough,
        buttons,
        mediaSource:selectedMediaSource,
        file:selectedFile
      });
    }
  };
  const onChangeOption = (event, id) => {
    const newText = event.target.value;
    if (newText.length > 20) {
      return;
    }
    const updatedButtons = buttons.map((button) => {
      if (button.id === id) {
        return { ...button, text: newText };
      }
      return button;
    });
    setButtons(updatedButtons);
    onValueChange({
      headerType,
      headerData,
      bodyText,
      footerText,
      optionType,
      //  isBodyBold,
      //  isBodyItalic,
      //  isBodyStrikethrough,
      buttons: updatedButtons,
      mediaSource:selectedMediaSource,
      file:selectedFile
    });
  };

  //!

  const addOption = () => {
    const newButton = [...buttons, { id: getId(), text: "" }];
    setButtons(newButton);
    onValueChange({
      headerType,
      headerData,
      bodyText,
      footerText,
      optionType,
      //  isBodyBold,
      //  isBodyItalic,
      //  isBodyStrikethrough,
      buttons: newButton,
      mediaSource:selectedMediaSource,
      file:selectedFile
    });
  };

  const deleteOption = (id) => {
    const updatedButtons = buttons.filter((button) => button.id !== id);
    setButtons(updatedButtons);
    onValueChange({
      headerType,
      headerData,
      bodyText,
      footerText,
      optionType,
      //  isBodyBold,
      //  isBodyItalic,
      //  isBodyStrikethrough,
      buttons: updatedButtons,
      mediaSource:selectedMediaSource,
      file:selectedFile
    });
  };
  // ! options functions
  //
  const onChangeHeaderText = (event) => {
    // setSelectedHeaderText(event.target.value);
    if (event.target.value.length <= 20) {
    setSelectedHeaderData(event.target.value);
    onValueChange({
      headerType,
      headerData: event.target.value,
      bodyText,
      footerText,
      optionType,
      //  isBodyBold,
      //  isBodyItalic,
      //  isBodyStrikethrough,
      buttons,
      mediaSource:selectedMediaSource,
      file:selectedFile
    });
  }
  };
  const handleMediaSourceChange = (value) => {
    setSelectedMediaSource(value);
    // setSelectedHeaderData('');
    onValueChange({
      headerType,
      headerData,
      bodyText,
      footerText,
      optionType,
      //  isBodyBold,
      //  isBodyItalic,
      //  isBodyStrikethrough,
      buttons,
      mediaSource:value,
      file:selectedFile
    });
  };

  const getAcceptAttribute = () => {
    switch (headerType) {
      case 'image':
      case 'Image':
        return 'image/*';
      case 'video': 
      case 'Video': 
        return 'video/*';
      case 'audio':
      case 'Audio':
        return 'audio/*';
      case 'document':
      case 'Document':
        return '.pdf,.doc,.docx,.txt,.xls,.xlsx'; 
      default:
        return '';
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    console.log(' FILE NAME : ' ,file.name);
    
    console.log("FILE : ", file);
    console.log("FILE : ", file.name);
    // console.log("selectedOption : ",selectedOption);
    console.log("selectedOption : ",optionType);
    console.log("header type : ",headerType);

    let isValid = false;
    const fileSize = file.size; 
    switch (headerType) {
      case 'Image':
        const ImageExtension =  file.name.split('.').pop().toLowerCase(); 
              console.log('extension :' ,ImageExtension);
              const supportedImageExtensions = ['jpeg', 'png','jpg',];
              const maxSizeImage = 5 * 1024 * 1024;  // 5 mb
              if (fileSize > maxSizeImage) {
                StatusAlertService.showWarning("Maximum File Size: 5 MB");
              } else  if (supportedImageExtensions.includes(ImageExtension)) {
                isValid = true;
              } else {
                console.log("Unsupported image extension: ", ImageExtension);
                StatusAlertService.showWarning("Unsupported image format!");
              }
        
        break;
        case 'Video': 
        const VideoExtension = file.name.split('.').pop().toLowerCase(); 
        console.log('extension :' ,VideoExtension);
        const supportedVideoExtensions = ['3gp', 'mp4',];
        const maxSizeVideo = 16 * 1024 * 1024;  // 16 mb
        if (fileSize > maxSizeVideo) {
          StatusAlertService.showWarning("Maximum File Size: 16 MB");
        }  else if (supportedVideoExtensions.includes(VideoExtension)) {
          isValid = true;
        } else {
          console.log("Unsupported video extension: ", VideoExtension);
          StatusAlertService.showWarning("Unsupported video format!");
        }
      break;
  
      case 'Audio': 
        const AudioExtension = file.name.split('.').pop().toLowerCase(); 
        console.log('extension :' ,AudioExtension);
        const supportedAudioExtensions = ['mp3', 'arm', 'acc' , 'm4a' ,'ogg' ];
        const maxSizeAudio = 16 * 1024 * 1024;  // 16 mb
        if (fileSize > maxSizeAudio) {
          StatusAlertService.showWarning("Maximum File Size: 16 MB");
        } else if (supportedAudioExtensions.includes(AudioExtension)) {
           isValid = true;
        } else {
          console.log("Unsupported audio extension: ", AudioExtension);
          StatusAlertService.showWarning("Unsupported audio format!");
        }
      break;
  
      case 'Document': 
        const DocumentExtension = file.name.split('.').pop().toLowerCase(); 
        console.log('extension :' ,DocumentExtension);
        const supportedDocumentExtensions = ['txt', 'xls', 'xlsx' , 'doc' ,'docx' ,'ppt' , 'pptx' , 'pdf' ];
        const maxSizeDocument = 100 * 1024 * 1024;  // 16 mb
        if (fileSize > maxSizeDocument) {
          StatusAlertService.showWarning("Maximum File Size: 100 MB");
        }  else if (supportedDocumentExtensions.includes(DocumentExtension)) {
         isValid = true;
        } else {
         console.log("Unsupported document extension: ", DocumentExtension);
          StatusAlertService.showWarning("Unsupported document format!");
        }
      break;
  
      default: 
        isValid = false;
        break;
    }



    if(isValid){
      console.log(' FILE IS VALID');
      if (file) { 
        setSelectedFile(file);
        onValueChange({
          headerType,
          headerData,
          bodyText,
          footerText,
          optionType,
          //  isBodyBold,
          //  isBodyItalic,
          //  isBodyStrikethrough,
          buttons,
          mediaSource:selectedMediaSource,
          file:file
        });
      }
    }
  };
  const onChangeURLfield = (event) => {
    setSelectedHeaderData(event.target.value);
    onValueChange({
      headerType,
      headerData:event.target.value,
      bodyText,
      footerText,
      optionType, 
      buttons,
      mediaSource:selectedMediaSource,
      file:selectedFile
    });
  };
  const headerOptions = ["None", "Text", "Image", "Video", "Document"];

  return (
    <div className="text-updater-node"   style={{padding:'0' }}>
     {/* <PopUpHeader title= 'Button Message'  color= '#039855' Icon={MessageOutlinedIcon} /> */}
     {/* <PopUpHeader title= 'Button Message'  color= 'rgba(251, 159, 205, 1)' style={{Position:'fixed'}}  /> */}
      <div
        style={{
          marginLeft: "10px",
          marginRight: "10px",
          marginTop: "10px",
          padding: "0px",
         
        }}  >
        

        {/* //! RADIO BUTTONS    */}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            paddingBottom: "10px",
          }} >
          {headerOptions.map((option) => (
            // <label style={{ alignItems: "center", display: "flex" }}>
            //   <input
            //     type="radio"
            //     value={option}
            //     checked={headerType === option}
            //     onChange={handleHeaderOptionChange}
            //     style={{ marginTop: "0px", marginLeft: "0px" }}  />
            //   <span
            //     style={{
            //       color: headerType === option ? "blue" : "inherit",
            //       marginRight: "10px",
            //     }} >
            //     {option}
            //   </span>
            // </label>
            //!
            headerType === option? <div   value={option}  onClick={handleHeaderOptionChange} style={{paddingLeft:'5px', paddingRight:'8px',cursor:'pointer', height:'42px' ,backgroundColor:'#F9F5FF' ,border:'1px solid #7F56D9', borderRadius:'8px' , marginRight:'10px' }}>
            <div style={{height:'100%', display: "flex", alignItems: "center" , alignItems : 'center',justifyContent:'center' }}>
              <div
                style={{
                  width: "35px",
                  height: "35px",
                  borderRadius: "50%",
                  backgroundColor: "#F4EBFF",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  position: "relative",
                }}
              >
                <div
                  style={{
                    width: "25px",
                    height: "25px",
                    borderRadius: "50%",
                    backgroundColor: "#E7D9FE",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    position: "absolute",
                  }}
                >
                  <div
                    style={{
                      height: "10px",
                      width: "10px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {option ==='None'? <BlockIcon style={{ fontSize: "inherit" ,color:'#53389E' }} color="success" />:null}
                    {option ==='Text'? <TextFieldsSharpIcon style={{ fontSize: "inherit" ,color:'#53389E' }} color="success" />:null}
                    {option ==='Image'? <InsertPhotoSharpIcon style={{ fontSize: "inherit" ,color:'#53389E' }} color="success" />:null}
                    {option ==='Video'? <VideoLibrarySharpIcon style={{ fontSize: "inherit" ,color:'#53389E' }} color="success" />:null}
                    {option ==='Document'? <FilePresentSharpIcon style={{ fontSize: "inherit" ,color:'#53389E' }} color="success" />:null}
                  </div>
                </div>
              </div>
              <p
                style={{
                  color: "#344054",
                  margin: "0",
                  paddingLeft: "10px",
                  fontWeight: "bold",
                  fontSize:'15px'
    
                }}
              >
               {option}
              </p>
            </div>
            </div> :
                  <div value={option}  onClick={handleHeaderOptionChange}  style={{ cursor:'pointer', height:'42px' ,backgroundColor:'#fff' ,border:'1px solid #E4E7EC', borderRadius:'8px' , marginRight:'10px' , paddingLeft:'5px', paddingRight:'8px' }}>
                  <div style={{height:'100%', display: "flex", alignItems: "center" , alignItems : 'center',justifyContent:'center' }}>
                    <div
                      style={{
                        width: "35px",
                        height: "35px",
                        borderRadius: "50%",
                        backgroundColor: "#F4EBFF",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        position: "relative",
                      }}
                    >
                      <div
                        style={{
                          width: "25px",
                          height: "25px",
                          borderRadius: "50%",
                          backgroundColor: "#E7D9FE",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          position: "absolute",
                        }}
                      >
                        <div
                          style={{
                            height: "10px",
                            width: "10px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                        {option ==='None'? <BlockIcon style={{ fontSize: "inherit" ,color:'#53389E' }} color="success" />:null}
                    {option ==='Text'? <TextFieldsSharpIcon style={{ fontSize: "inherit" ,color:'#53389E' }} color="success" />:null}
                    {option ==='Image'? <InsertPhotoSharpIcon style={{ fontSize: "inherit" ,color:'#53389E' }} color="success" />:null}
                    {option ==='Video'? <VideoLibrarySharpIcon style={{ fontSize: "inherit" ,color:'#53389E' }} color="success" />:null}
                    {option ==='Document'? <FilePresentSharpIcon style={{ fontSize: "inherit" ,color:'#53389E' }} color="success" />:null}
                        </div>
                      </div>
                    </div>
                    <p
                      style={{
                        color: "#344054",
                        margin: "0",
                        paddingLeft: "10px",
                        fontWeight: "bold",
                        fontSize:'15px'
                      }}
                    >
                     {option}
                    </p>
                  </div>
                  </div>
            //!
          ))}
        </div>
        {/* //!  info */}

        <div>
     </div>


        {/* //! header type input fields */}
        {headerType === "None" ? null : null}
        {headerType === "Text" ? (
          <div> 
            <input
              name="text"
              placeholder="Type here..."
              onChange={onChangeHeaderText}
              value={headerData} 
              className="nodrag"
              style={{ 
                padding: "20px",
                backgroundColor: "#fff",
                border: "1px solid #E4E7EC",
                width: "100%",
                padding: "6px",
                borderRadius: "8px",
                paddingLeft:'10px',
                color:"#667085",
                width:'96%',
                height:'30px', 
              marginBottom: "8px",
                
              }}   />
             
          <p
            style={{
              textAlign: "initial",
              margin: "0px",
              fontSize: "11px",
              padding: "5px",
              textAlignLast: "end",
              color:'#475467',
              fontWeight:'bold' 
            }}
          >
            {headerData.length} / 20
          </p>
          </div>
        ) : null}
        {/* //! Buttons */}

        {headerType === "Image" ||
        headerType === "Video" ||
        headerType === "Document" ? (
          <>
           
          <div style={{display:'flex',  height:'40px'}} >
          <div 
           style={{
            borderRadius:'10px 10px 10px 0', 
            backgroundColor : selectedMediaSource==='upload'?'#7F56D9' :'#fff' ,
             paddingLeft:'10px' ,
             paddingRight:'10px', 
             display:'flex', 
             alignItems:'center',
             cursor:'pointer'}}
             
             value='upload'
             onClick={() => handleMediaSourceChange('upload')}
             >
          <UploadIcon style={{ fontSize: "inherit"  , color:selectedMediaSource==='upload'?'#fff':'#51369E' , paddingRight:'5px' }} color="success" />
            <p style={{margin:'0px' , color:selectedMediaSource==='upload'?'#fff':'#51369E'}}>Upload</p>
          </div>
          <div style={{borderRadius:'10px 10px 10px 0', 
            backgroundColor : selectedMediaSource==='link'?'#7F56D9' :'#fff'  , 
            paddingLeft:'10px' ,
            paddingRight:'10px', 
            display:'flex',
             alignItems:'center',
             cursor:'pointer' }}
             
             value='link'
             onClick={() => handleMediaSourceChange('link')}
             >
          <LinkIcon style={{ fontSize: "inherit" ,color:selectedMediaSource==='link'?'#fff':'#51369E', paddingRight:'5px' }} color="success" />
            <p style={{margin:'0px' , color:selectedMediaSource==='link'?'#fff':'#51369E'}}>Link</p>
          </div>
           
          
    </div>
          
            {/* //! selected media item selector */}
            {selectedMediaSource === "upload" ? (
              <label htmlFor="file-upload" >
              <div  
              htmlFor="file-upload" 
               style={{
                padding: '5px',
                backgroundColor: '#fff',
                color: '#000',
                border: '1px solid #7F56D9',
                borderRadius: '0  12px 12px 12px',
                marginRight: '10px', 
                marginBottom: '20px',
                height:'110px',
                display:'flex',
                flexDirection:'column',
                alignItems:'center'
              }}
              
              >
          
                     <div
                            style={{
                              width: "35px",
                              height: "35px",
                              borderRadius: "50%",
                              backgroundColor: "#F9FAFB",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              position: "relative",
                            }}
                          >
                            <div
                              style={{
                                width: "25px",
                                height: "25px",
                                borderRadius: "50%",
                                backgroundColor: "#F2F4F7",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                position: "absolute",
                              }}
                            >
                              <div
                                style={{
                                  height: "10px",
                                  width: "10px",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <CloudUploadOutlinedIcon style={{ fontSize: "inherit" ,color:'#475467' }} color="success" />
                              </div>
                            </div>
                          </div>
           
                <p style={{cursor: 'pointer' , color:'#7F56D9' , fontWeight:'bold', margin:'3px' }}>Click To Upload</p>
                
                {headerType=='Audio' ?  <p style={{fontSize:"12px",margin:"0",paddingBottom:"5px",color:"grey",}} >Allowed Media Type: .mp3 , .amr, .aac  , .m4a , .ogg  </p>  : <div></div>}
                {headerType=='Image' ?  <p style={{fontSize:"12px",margin:"0",paddingBottom:"5px",color:"grey",}} >Allowed media type: .jpeg , .png  </p>  : <div></div>}
                {headerType=='Video' ?  <p style={{fontSize:"12px",margin:"0",paddingBottom:"5px",color:"grey",}} >Allowed media type: .3gp , .mp4  </p>  : <div></div>}
                {headerType=='Document' ?  <p style={{fontSize:"12px",margin:"0",paddingBottom:"5px",color:"grey",}} >Allowed media type: .txt , .xls , .xlsx , .doc , .docx , .ppt , .pptx , .pdf  </p>  : <div></div>}

                {/* //! file size */}
                {headerType=='Audio' ?  <p style={{fontSize:"12px",margin:"0",paddingBottom:"5px",color:"grey",}} >Maximum File Size: 16 MB </p>  : <div></div>}
                {headerType=='Image' ?  <p style={{fontSize:"12px",margin:"0",paddingBottom:"5px",color:"grey",}} >Maximum File Size: 5 MB </p>  : <div></div>}
                {headerType=='Video' ?  <p style={{fontSize:"12px",margin:"0",paddingBottom:"5px",color:"grey",}} >Maximum File Size: 16 MB </p>  : <div></div>}
                {headerType=='Document' ?  <p style={{fontSize:"12px",margin:"0",paddingBottom:"5px",color:"grey",}} >Maximum File Size: 100 MB </p>  : <div></div>}

                 <input
                    id="file-upload"
                    type="file"
                    accept={getAcceptAttribute()}
                    onChange={handleFileChange}
                    style={{ display: 'none' }}
                  />
           
              {selectedFile && (
                <div>
                  <p style={{fontSize:"12px",margin:"0",paddingBottom:"5px",color:"#7F56D9",}} > {selectedFile.name}</p>
           
                </div>
              )}
            </div></label>
           
            ) : null}
            {selectedMediaSource === "link" ? (
              <div 
              style={{
                padding: '5px',
                backgroundColor: '#fff',
                color: '#000',
                border: '1px solid #7F56D9',
                borderRadius: '12px  12px 12px 12px',
                marginRight: '10px',
                // width:'100%',
                marginBottom: '20px',
                height:'110px',
                display:'flex',
                flexDirection:'row',
                alignItems:'start'}}
              >


{headerType === 'Image' && headerData ? (
  <img 
    src={headerData}
    alt="Link image" 
    style={{ 
      maxHeight: '110px', 
      maxWidth: '150px', 
      margin: "0",  
      marginRight: '6px', 
      borderRadius: "10px 10px 10px 10px" 
    }} 
  />
) : null}


                
{headerType === 'Video' && headerData ? (
 <video style={{
  maxHeight: '110px', 
      // maxWidth: '150px', 
      margin: "0",  
      marginRight: '6px', 
      borderRadius: "10px 10px 10px 10px" 
   }} controls>
 <source src={headerData} type="video/mp4" />
 Your browser does not support the video tag.
</video>
) : null}

{headerType === 'Document' && headerData ? (
  <a 
    href={headerData} 
    target="_blank" 
    rel="noopener noreferrer" 
    style={{ textDecoration: 'none',height: '100%',  }} // Prevents any default link styling
  >
    <div style={{ border: '1px solid #7F56D9', marginRight: '6px', height: '100%', borderRadius: "12px", cursor: 'pointer' }}>
      <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
        <img src={PDF} alt="PDF icon" style={{ margin: "0", borderRadius: "10px 10px 10px 10px" }} />
        <p style={{
          margin: "0",
          marginLeft: '15px',
          fontSize: "12px",
          paddingRight: "5px",
          color: 'black',
          maxWidth: '100%',
          overflow: 'hidden',
          whiteSpace: 'normal',
          wordWrap: 'break-word',
          textAlign: 'start'
        }}>
          {getFileNameFromUrl(headerData)}
        </p>
      </div>
      <hr style={{ border: 'none', borderTop: '1px solid grey', margin: '25px 0 1px 0', opacity: '0.5' }} />
    </div>
  </a>
) : null}




                 <textarea
      id="text"
      name="text"
      value={headerData}
      placeholder="URL here..." 
      onChange={onChangeURLfield}
      className="nodrag"
      style={{  
        width:"100%",
        maxWidth:'100%',
        // minWidth:'65%',
        height:'88px',
       
        border: '1px solid #7F56D9',
        borderRadius: "12px",
        padding: "10px",
        color:'#667085',
        marginBottom: '20px',
         resize: 'none'
             }} />
              </div>

              
            ) : null}
          </>
        ) : null}

        

        <div
          style={{ display: "flex", flexDirection: "row", alignItems: "start" }}  >
          <p  style={{
              margin: "0", 
              paddingRight: "5px",
              color: "#344054",
              fontSize:'11px',
              marginBottom: "10px",
              fontWeight:'bold'
            }}  >   Question Text   </p> 
          <p   style={{
              margin: "0",
              fontSize: "12px",
              paddingRight: "20px",
              color: "#344054",    fontWeight:'bold' }}   >  *  </p> 
        </div>

        {/* //! */}
        <textarea
          id="text1"
          name="text"
          placeholder="Enter Your Text"
          onChange={onChange}
          value={bodyText}
          ref={textareaRef}
          className="nodrag"
          style={{
            width: "95%",
            marginRight: "5px",
            minHeight: "50px",
            maxWidth:'95%',
            minWidth:'95%',
            maxHeight:'200px',
            border: "1px solid #E4E7EC",
            borderRadius: "8px",
            padding: "10px",
            color:'#667085' 
          }}  />
          {/* //! styles  */}
          <div
          style={{ display: "flex", flexDirection: "row",  justifyContent:'space-between' }}
        >
        <div
          style={{ display: "flex", flexDirection: "row", paddingTop: "5px" }}
        >
          <div
            class="rounded-button"
            onClick={toggleBold} 
          >
            <i class="fa-solid fa-bold"></i>{" "}
            <p
              style={{
                fontSize: "10px",
                fontWeight: "bold", 
              }}
            >
              B
            </p>
          </div>
          <div
            class="rounded-button"
            onClick={toggleItalic} 
          >
            <i class="fa-solid fa-italic"></i>{" "}
            <p
              style={{
                fontStyle: "italic",
                fontSize: "10px", 
              }}
            >
              I
            </p>
          </div>
          <div
            class="rounded-button"
            onClick={toggleStrikethrough} 
          >
            <span class="strikethrough">
              <p
                style={{
                  fontSize: "10px", 
                }}
              >
                S
              </p>
            </span>
          </div>
          <div
            class="rounded-button"
            onClick={toggleMonoSpace} 
          >
            <span class="fa-solid">
              <p
                style={{
                  fontSize: "10px", 
                }}
              >
                M
              </p>
            </span>
          </div>
          </div>
          <p
            style={{
              textAlign: "initial",
              margin: "0px",
              fontSize: "13px",
              fontWeight :'bold',
              padding: "5px",
              alignSelf: "flex-end",
              color:'#344054'
            }}
          >
            {bodyText.length} / 1024
          </p>
        </div>
          {/* //! */}
         

        {/* //!  FOOTER */}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            paddingBottom: "5px",
            paddingTop: "10px",
          }}
        >
          <p
            style={{
              margin: "0", 
              paddingRight: "5px",
              color: "#344054",
              fontSize:'11px', 
              fontWeight:'bold'
            }}
          >
            Footer{" "}
          </p>
          {/* <p
            style={{
              margin: "0",
              padding: "2px",
              fontSize: "8px",
              marginRight: "20px",
              color: "white",
              backgroundColor: "grey",
            }}
          >
            Optional
          </p> */}
        </div>
        <textarea
          id="text2"
          name="text"
          placeholder="Enter the footer text"
          onChange={onChangeFooter}
          className="nodrag"
          value={footerText}
          style={{
            width: "95%",
            maxWidth:'95%',
            minWidth:'95%',
            maxHeight:'200px',
            minHeight: "15px",
            border: "1px solid #E4E7EC",
            borderRadius: "8px", 
            marginRight: "5px",

            color:'#667085' ,
            padding: "10px",
          }}
        />

        <p
          style={{
            textAlign: "initial",
            margin: "0px",
            fontSize: "7px",
            padding: "5px",
            textAlignLast: "end", 

            textAlign: "initial",
            margin: "0px",
            fontSize: "13px",
            fontWeight :'bold',
            padding: "5px", 
            color:'#344054'
          }}
        >
          {footerText.length} / 60
        </p>

        {/* //! OPTIONS */}
        <div   style={{ display: "flex", flexDirection: "row", alignItems: "start" , marginBottom:'5px'}}>
          <p  style={{
              margin: "0",
              fontSize: "11px",
              paddingRight: "5px",
              color: "#344054",    fontWeight:'bold'
            }}  >  Button Name  </p> 
          <p  style={{
              margin: "0",
              fontSize: "12px",
              paddingRight: "20px",
              color: "#344054",    fontWeight:'bold' 
            }}   >   *   </p>
        </div>
      

       
        {/* //! options butttons */}
        {buttons.map((option) => (



// !
<div  key={option.id}  style={{ display:'flex' , flexDirection:'row' , alignItems: 'center', justifyContent:'space-between', marginRight:'10px' ,  marginBottom:'10px'}}>
  <div style={{ width:'90%',border:'1px solid #E4E7EC',borderRadius:'8px', display:'flex' , flexDirection:'row' , alignItems: 'center' ,justifyContent:'space-between',padding:'5px'}}> 
  <input
                  id={option.id}
                  name="text"
                  placeholder="Enter text here..."
                  onChange={(event) => onChangeOption(event, option.id)}
                  value={option.text}
                  className="nodrag"
                  style={{
                    border: "none",
                    width: "95%",
                    padding: "6px",
                    borderRadius: "5px",
                  }}   />

<p   style={{
                    textAlign: "initial",
                    margin: "0px",
                    fontSize: "11px",marginRight:'10px',
                    // padding: "5px",
                    textAlignLast: "end",width:'40px' , margin:'0' , 
                  }}   >  {option.text.length} / 20   </p>
    
</div> 
<RemoveCircleRoundedIcon    onClick={() => deleteOption(option.id)}   style={{ fontSize: "35px" ,color:'#7F56D9',    }} color="success" />
</div>
        ))}
        {buttons.length >= 3 ? (
          <div style={{ height: "20px" }}></div>
        ) : (
          <div onClick={addOption}  style={{display:'flex', marginTop:'10px' , cursor:'pointer' }}>
            <AddRoundedIcon   style={{ fontSize: "inherit" ,color:'#6941C6' }} color="success" />
       <p style={{margin:'0' , paddingLeft:'10px' , color:'#6941C6' , fontWeight:'bold' , fontSize:'11px' , alignItems:'center'}} > Add another</p>
       
            </div>
        )}
      </div>
    </div>
  );
}
export default AskButtonOptionNode;
