import { useRef, useState, useEffect ,useMemo } from "react";
import { Handle, Position } from "reactflow";
import { v4 as uuidv4 } from "uuid";

import playButton from "../../assets/play-button.png";
import deleteIcon from "../../assets/delete.png";
import plusIcon from "../../assets/plus.png";
import ".././node.css";
import PopUpHeader from '../components/popUpAlertHeader.js';
import StatusAlert, { StatusAlertService } from "react-status-alert";

import MessageOutlinedIcon from '@mui/icons-material/MessageOutlined';
import UploadIcon from '@mui/icons-material/Upload';
import LinkIcon from '@mui/icons-material/Link';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';

//! add and remove
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import RemoveCircleRoundedIcon from '@mui/icons-material/RemoveCircleRounded';
import RemoveCircleOutlineRoundedIcon from '@mui/icons-material/RemoveCircleOutlineRounded';


function AskListOptionNode({ onValueChange, editData }) {
  // const [headerText, setHeaderText] = useState("");
  const [questionText, SetQuestionText] = useState("");
  const [footerText, setFooterText] = useState("");
  const [buttonTitle, SetButtonTitle] = useState("");
  const [selectedOption, setSelectedOption] = useState("");
  // const [sessionName, setSessionName] = useState("");
  // const [options, setOptions] = useState([{ id: 'initial', title: '', description: '' }]);

  const [isBold, setIsBold] = useState(false);
  const [isItalic, setIsItalic] = useState(false);
  const [isStrikethrough, setIsStrikethrough] = useState(false);
  const [isMonoSpace, setIsMonoSpace] = useState(false);

  const textareaRef = useRef(null);


  // const [listItemCount, setSetMaximumListLimit] = useState(1);

  const [sections, setSections] = useState([
    {
      id: uuidv4(),
      sectionName: "",
      options: [{ newSectionId: uuidv4(), title: "", description: "" }],
    },
  ]);

  const totalOptionsCount = useMemo(() => {
    return sections.reduce((acc, section) => acc + section.options.length, 0);
  }, [sections]);


  useEffect(() => {
    // data has value
    const hasEditData = Boolean(editData);
    if (hasEditData) {
      const {
        buttonTitle,
        footerText,
        questionText,
        sections,
        selectedOption,
        type,
      } = editData;
      console.log("editData :", editData);

      SetQuestionText(questionText);
      setFooterText(footerText);
      SetButtonTitle(buttonTitle);
      setSelectedOption(selectedOption);
      setSections(sections); 
      onValueChange({
        questionText: questionText ,
        footerText :footerText,
        buttonTitle:buttonTitle,
        selectedOption :selectedOption,
        sections:sections,
      });
    }else{
      // here
      // const newSectionId = uuidv4();
      console.log('~~~~~~~~~~~~~~~ ID ~~~~~~~~~~~~~~~~');
      console.log('  newSectionId :',sections);
      // setSections([{
      //   id:'123',
      //   sectionName: "",
      //   options: [{ id: newSectionId, title: "", description: "" }],
      // }])
      // addSection();
    }
  }, [editData]);
  //!
  const updateState = () => {
    onValueChange({
      questionText,
      footerText,
      buttonTitle,
      selectedOption,
      sections,
    });
  };
  //!
  const  addSection = () => {
    console.log('totalOptionsCount : ',totalOptionsCount);
    if (totalOptionsCount>=10) {
      StatusAlertService.showWarning("Maximum Option Limit Reached!");
      return ;
    }
    const newSectionId = uuidv4();
    onValueChange({
      questionText,
      footerText,
      buttonTitle,
      selectedOption,
      sections: [...sections,
        {
          id: newSectionId,
          sectionName: "",
          options: [{ newSectionId, title: "", description: "" }],
        },

      ],
    });

    setSections((prevSections) => [
      ...prevSections,
      {
        id: newSectionId,
        sectionName: "",
        options: [{ newSectionId, title: "", description: "" }],
      },
    ]);
  };

  const testButton = () => {
    console.log("Section :", sections);
  };

  const deleteSection = (id) => {
    setSections((prevSections) =>
      prevSections.filter((section) => section.id !== id)
    );
    const updatedSections = sections.filter((section) => section.id !== id);

    onValueChange({
      questionText,
      footerText,
      buttonTitle,
      selectedOption,
      sections: updatedSections,
    });
  };

  const deleteAdditionalContent = (sectionId, contentId) => {
    setSections(prevSections => 
      prevSections
        .map((section) =>
          section.id === sectionId
            ? {
                ...section,
                options: section.options.filter(
                  (content) => content.newSectionId !== contentId
                ),
              }
            : section
        )
        .filter((section) => section.options.length > 0),
    );

    const updatedSections = sections.map((section) =>
        section.id === sectionId
          ? {
              ...section,
              options: section.options.filter(
                (content) => content.newSectionId !== contentId
              ),
            }
          : section
      )
      .filter((section) => section.options.length > 0);

    onValueChange({
      questionText,
      footerText,
      buttonTitle,
      selectedOption,
      sections: updatedSections,
    });
  };

 

  const addAdditionalContent = (id) => {
    console.log('totalOptionsCount : ',totalOptionsCount);
    if (totalOptionsCount>=10) {
      // todo : show limit alert
      StatusAlertService.showWarning("Maximum Option Limit Reached!");
      return ;
    }
    const newContent = {
      newSectionId: uuidv4(),
      title: "",
      description: "",
    };

    setSections((prevSections) =>
      prevSections.map((section) =>
        section.id === id
          ? { ...section, options: [...section.options, newContent] }
          : section
      )
    );
    updateState();
  };

  const handleSectionNameChange = (id, value) => {
 
    if (value.length>24) {
      return;
    }

    const updatedSections = sections.map((section) =>
      section.id === id ? { ...section, sectionName: value } : section
     );

    setSections(updatedSections);

    onValueChange({
      questionText,
      footerText,
      buttonTitle,
      selectedOption,
      sections : updatedSections,
    });
  };

  const handleTitleChange = (sectionId, contentId, value) => {
    if (value.length>24) {
      return;
    }
 
    const updatedSections =  sections.map((section) =>
      section.id === sectionId
        ? {
            ...section,
            options: section.options.map((content) =>
              content.newSectionId === contentId
                ? { ...content, title: value }
                : content
            ),
          }
        : section
    );

    setSections(updatedSections);

    onValueChange({
      questionText,
      footerText,
      buttonTitle,
      selectedOption,
      sections : updatedSections,
    });


  };

  const handleDescriptionChange = (sectionId, contentId, value) => {
    if (value.length>72) {
      return;
    }
    // setSections((prevSections) =>
    //   prevSections.map((section) =>
    //     section.id === sectionId
    //       ? {
    //           ...section,
    //           options: section.options.map((content) =>
    //             content.newSectionId === contentId
    //               ? { ...content, description: value }
    //               : content
    //           ),
    //         }
    //       : section
    //   )
    // );
    // updateState();
const updatedSections =  sections.map((section) =>
  section.id === sectionId
    ? {
        ...section,
        options: section.options.map((content) =>
          content.newSectionId === contentId
            ? { ...content, description: value }
            : content
        ),
      }
    : section
)

    setSections(updatedSections);

    onValueChange({
      questionText,
      footerText,
      buttonTitle,
      selectedOption,
      sections : updatedSections,
    });
  };

  // !

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
    onValueChange({
      questionText,
      footerText,
      buttonTitle,
      selectedOption: event.target.value,
      sections,
    });
    updateState();
  };

  const toggleBold = () => {
    const textarea = textareaRef.current;
    const start = textarea.selectionStart;
    const end = textarea.selectionEnd;

    // Check if cursor is inside a bold section
    if (questionText[start - 1] === '*' && questionText[end] === '*') {
      // Remove bold markers around the cursor
      const updatedText = questionText.slice(0, start - 1) + questionText.slice(start, end) + questionText.slice(end + 1);
      SetQuestionText(updatedText);
      onValueChange({
        questionText : updatedText  ,
        footerText,
        buttonTitle,
        selectedOption,
        sections,
      });
      textarea.focus();
      textarea.setSelectionRange(start - 1, end - 1);
    } else {
      // Add bold markers around the cursor
      const updatedText = questionText.slice(0, start) + '*' + questionText.slice(start, end) + '* ' + questionText.slice(end);
      SetQuestionText(updatedText);
      onValueChange({
        questionText : updatedText  ,
        footerText,
        buttonTitle,
        selectedOption,
        sections,
      });
      textarea.focus();
      textarea.setSelectionRange(start + 1, end + 1);
    }
 
  };

  const toggleItalic = () => {
    const textarea = textareaRef.current;
    const start = textarea.selectionStart;
    const end = textarea.selectionEnd;

     // Check if cursor is inside a bold section
     if (questionText[start - 1] === '_' && questionText[end] === '_') {
      // Remove bold markers around the cursor
      const updatedText = questionText.slice(0, start - 1) + questionText.slice(start, end) + questionText.slice(end + 1);
      SetQuestionText(updatedText);
      onValueChange({
        questionText : updatedText  ,
        footerText,
        buttonTitle,
        selectedOption,
        sections,
      });
      textarea.focus();
      textarea.setSelectionRange(start - 1, end - 1);
    } else {
      // Add bold markers around the cursor
      const updatedText = questionText.slice(0, start) + '_' + questionText.slice(start, end) + '_ ' + questionText.slice(end);
      SetQuestionText(updatedText);
      onValueChange({
        questionText : updatedText  ,
        footerText,
        buttonTitle,
        selectedOption,
        sections,
      });
      textarea.focus();
      textarea.setSelectionRange(start + 1, end + 1);
    }

 
  };

  const toggleStrikethrough = () => {
    const textarea = textareaRef.current;
    const start = textarea.selectionStart;
    const end = textarea.selectionEnd;

    // Check if cursor is inside a bold section
    if (questionText[start - 1] === '~' && questionText[end] === '~') {
      // Remove bold markers around the cursor
      const updatedText = questionText.slice(0, start - 1) + questionText.slice(start, end) + questionText.slice(end + 1);
      SetQuestionText(updatedText);
      onValueChange({
        questionText : updatedText  ,
        footerText,
        buttonTitle,
        selectedOption,
        sections,
      });
      textarea.focus();
      textarea.setSelectionRange(start - 1, end - 1);
    } else {
      // Add bold markers around the cursor
      const updatedText = questionText.slice(0, start) + '~' + questionText.slice(start, end) + '~ ' + questionText.slice(end);
      SetQuestionText(updatedText);
      onValueChange({
        questionText : updatedText  ,
        footerText,
        buttonTitle,
        selectedOption,
        sections,
      });
      textarea.focus();
      textarea.setSelectionRange(start + 1, end + 1);
    }
   
  };
  const toggleMonoSpace = () => {
    
    const textarea = textareaRef.current;
    const start = textarea.selectionStart;
    const end = textarea.selectionEnd;

    // Check if cursor is inside a bold section
    if (questionText[start - 1] === '```' && questionText[end] === '```') {
      // Remove bold markers around the cursor
      const updatedText = questionText.slice(0, start - 1) + questionText.slice(start, end) + questionText.slice(end + 1);
      SetQuestionText(updatedText);
      onValueChange({
        questionText : updatedText  ,
        footerText,
        buttonTitle,
        selectedOption,
        sections,
      });
      textarea.focus();
      textarea.setSelectionRange(start - 1, end - 1);
    } else {
      // Add bold markers around the cursor
      const updatedText = questionText.slice(0, start) + '```' + questionText.slice(start, end) + '``` ' + questionText.slice(end);
      SetQuestionText(updatedText);
      onValueChange({
        questionText : updatedText  ,
        footerText,
        buttonTitle,
        selectedOption,
        sections,
      });
      textarea.focus();
      textarea.setSelectionRange(start + 1, end + 1);
    }
  };

  const onChange = (event) => {
    const newText = event.target.value;
    if (newText.length <= 1024) {
      SetQuestionText(newText);
      onValueChange({
        // headerText,
        questionText: newText,

        footerText,
        buttonTitle,

        selectedOption,
        sections,
        // sessionName,
        // options
      });
    }
  };
  // const onChangeSessionName = (event) => {
  //   const newText = event.target.value;
  //     setSessionName(newText);
  //     onValueChange({
  //       // headerText,
  //       questionText,
  //       footerText,
  //       buttonTitle,
  //       selectedOption,
  //       sessionName:newText,
  //       options
  //       });
  // };
  const onChangeFooter = (event) => {
    const newText = event.target.value;
    if (newText.length <= 60) {
      setFooterText(newText);
      onValueChange({
        // headerText,
        questionText,

        footerText: newText,
        buttonTitle,

        selectedOption,
        sections,
        // sessionName,
        // options
      });
    }
  };
  const onChangeButtonText = (event) => {
    const newText = event.target.value;
    if (newText.length <= 20) {
      SetButtonTitle(newText);
      onValueChange({
        // headerText,
        questionText,

        footerText,
        buttonTitle: newText,

        selectedOption,
        sections,
        // sessionName,
        // options
      });
    }
  };
  // const onChangeHeaderText = (event) => {
  //   const newText = event.target.value;
  //   if (newText.length <= 60) {
  //     setHeaderText(newText);
  //     onValueChange({
  //       headerText:newText,
  //       questionText,

  //       footerText,
  //       buttonTitle,

  //       selectedOption,
  //       sessionName,
  //       options
  //       });
  //   }
  // };

  // !  LISTS
  // const onChangeOption = (event, id, field) => {
  //   const newValue = event.target.value;
  //   const updatedOptions = options.map(option => {
  //     if (option.id === id) {
  //       return { ...option, [field]: newValue };
  //     }
  //     return option;
  //   });
  //   setOptions(updatedOptions);
  //   onValueChange({
  //     // headerText,
  //     questionText,

  //     footerText,
  //     buttonTitle,

  //     selectedOption,
  //     sessionName,
  //     options:updatedOptions
  //     });
  // };

  // const addOption = () => {
  //   const newOption = { id: Math.random().toString(36).substring(7), title: '', description: '' };
  //   setOptions([...options, newOption]);
  //   onValueChange({
  //     // headerText,
  //     questionText,

  //     footerText,
  //     buttonTitle,

  //     selectedOption,
  //     sessionName,
  //     options:[...options, newOption]
  //     });
  // };

  // const deleteOption = (id) => {
  //   const updatedOptions = options.filter(option => option.id !== id);
  //   setOptions(updatedOptions);
  //   onValueChange({
  //     // headerText,
  //     questionText,

  //     footerText,
  //     buttonTitle,

  //     selectedOption,
  //     sessionName,
  //     options:updatedOptions
  //     });
  // };

  return (
    <div className="text-updater-node" style={{padding:'0'}}>
      {/* <PopUpHeader title= 'List Message' color= '#039855' Icon={MessageOutlinedIcon} /> */}
      


      <div
        style={{
          marginLeft: "10px",
          marginRight: "10px",
          marginTop: "10px",
          padding: "0px",
          
        }} >
     

        <div  style={{ display: "flex", flexDirection: "row", alignItems: "start" }}  >
          <p  style={{
              margin: "0",
              fontSize: "11px",
              paddingRight: "5px",
              marginBottom: "5px",
              color: "#344054", fontWeight:'bold'
            }}  >   Question Text  </p> 
          <p  style={{
              margin: "0",
              fontSize: "12px",
              paddingRight: "20px",
             color: "#344054", fontWeight:'bold'
            }}  >   *   </p> 
        </div>

        {/* //! */}
        <textarea
          id="text1"
          name="text"
          placeholder="What do you think?"
          onChange={onChange}
          value={questionText}
          ref={textareaRef}
          className="nodrag"
          style={{
            width: "95%",
            marginRight: "8px",
            minHeight: "50px",
            border: "1px solid #E4E7EC",
            borderRadius: "5px",
            padding: "10px", 
            color:'#667085' 
          }}
        />
        {/* //! */}
        <div
          style={{ display: "flex", flexDirection: "row",  justifyContent:'space-between' }}
        >
        <div
          style={{ display: "flex", flexDirection: "row", paddingTop: "5px" }}
        >
          <div
            class="rounded-button"
            onClick={toggleBold}
            // style={{ backgroundColor: isBold ? "blue" : "initial" }}
          >
            <i class="fa-solid fa-bold"></i>{" "}
            <p
              style={{
                fontSize: "10px",
                fontWeight: "bold",
                // color: isBold ? "white" : "black",
              }}
            >
              B
            </p>
          </div>
          <div
            class="rounded-button"
            onClick={toggleItalic} 
          >
            <i class="fa-solid fa-italic"></i>{" "}
            <p
              style={{
                fontStyle: "italic",
                fontSize: "10px", 
              }}
            >
              I
            </p>
          </div>
          <div
            class="rounded-button"
            onClick={toggleStrikethrough} 
          >
            <span class="strikethrough">
              <p
                style={{
                  fontSize: "10px", 
                }}
              >
                S
              </p>
            </span>
          </div>
          <div
            class="rounded-button"
            onClick={toggleMonoSpace} 
          >
            <span class="fa-solid">
              <p
                style={{
                  fontSize: "10px", 
                }}
              >
                M
              </p>
            </span>
          </div>
          </div>
          <p
            style={{
              textAlign: "initial",
              margin: "0px",
              fontSize: "13px",
              fontWeight :'bold',
              padding: "5px",
              alignSelf: "flex-end",
              color:'#344054'
            }}
          >
            {questionText.length} / 1024
          </p>
        </div>
        {/* //! */}
         

        {/* //!  FOOTER */}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            paddingBottom: "5px",
            paddingTop: "10px",
          }}  >
          <p
            style={{
              margin: "0",
              fontSize: "11px",
              paddingRight: "5px",
               color: "#344054",    fontWeight:'bold'
            }}  >  Footer  </p>
          {/* <p  style={{
              margin: "0",
              padding: "2px",
              fontSize: "8px",
              marginRight: "20px",
              color: "white",
              backgroundColor: "grey",
            }}  >  Optional  </p> */}
        </div>
        <textarea
          id="footerText"
          name="text"
          placeholder="Enter the footer text"
          onChange={onChangeFooter}
          value={footerText}
          className="nodrag"
          style={{
            width: "95%",
            minHeight: "15px",
            border: "1px solid #E4E7EC",
            borderRadius: "8px",
            padding: "10px",
            marginRight: "5px",
            color:'#667085' ,
          }}  />
        <p  style={{
            textAlign: "initial",
            margin: "0px",
            fontSize: "13px",
            padding: "5px",
            textAlignLast: "end", 
               color:'#344054',  fontWeight :'bold',
          }}   >  {footerText.length} / 60  </p>

        {/* //! BUTTON NAME */}
        <div   style={{ display: "flex", flexDirection: "row", alignItems: "start" }}   >
          <p  style={{
              margin: "0", 
              paddingRight: "5px", 
              fontSize: "12px",
            marginBottom: "5px",   color: "#344054",    fontWeight:'bold'
            }} >   Button name  </p> 
          <p  style={{
              margin: "0",
              fontSize: "12px",
              paddingRight: "20px",
                color: "#344054",    fontWeight:'bold'
            }}  >  *  </p>
        </div>
        <textarea
          id="buttonTitle"
          name="text"
          placeholder="Enter the Button Name"
          onChange={onChangeButtonText}
          className="nodrag"
          value={buttonTitle}
          style={{
            width: "95%",
            maxHeight: "15px",
            border: "1px solid #E4E7EC",
            borderRadius: "8px",
            padding: "10px",
            marginRight: "5px",
            color:'#667085' 
          }}  />
        <p  style={{
            textAlign: "initial",
            margin: "0px",
            fontSize: "13px",
            padding: "5px",
            textAlignLast: "end",  color:'#344054',  fontWeight :'bold',
            //   alignSelf: "flex-end",
          }}  >
          {buttonTitle.length} / 20
        </p>
     
       

        {/* //!    OPTIONS */}

        <p  style={{
            margin: "0",
            fontSize: "12px",
            marginBottom: "5px",
            paddingRight: "5px",
            
                color: "#344054",    fontWeight:'bold'
          }}  >   Options  </p>
        {/* //! options butttons */}
        <div>
          {sections.map((section) => (
            <div
              key={section.id}
              style={{
                marginTop: "10px",
                backgroundColor: "#F2F4F7",
                borderRadius: "5px",
                border: "1px",
                display: "flex",
                flexDirection: "column",
              }} >
                {/* //! */}
                <div style={{display:'flex' ,flexDirection:'row', alignItems:'center',marginTop: "10px", }}>
                <div style={{
                  width :'88%',
                  border:'1px solid #7F56D9',
                  borderRadius:'8px', 
                  display:'flex' , 
                  flexDirection:'row' , 
                  alignItems: 'center' ,
                  justifyContent:'space-between',
                  padding:'5px',
                   marginLeft: "8px",
                   
                   backgroundColor:'#F9F5FF'
                   }}> 
  <input
                 id={`sectionName-${section.id}`}
                 name={`sectionName-${section.id}`}
                  placeholder="Enter text here..."
                  value={section.sectionName}
                  onChange={(e) =>
                    handleSectionNameChange(section.id, e.target.value)
                  }
                  className="nodrag"
                  style={{
                    border: "none",
                    width: "95%",
                    padding: "6px",
                    borderRadius: "5px",
                    backgroundColor:'transparent'
                  }}   />

<p   style={{
                    textAlign: "initial",
                    margin: "0px",
                    fontSize: "11px",marginRight:'10px',
                    // padding: "5px",
                    textAlignLast: "end",width:'40px' , margin:'0' , fontWeight:'bold' ,color:'#475467'
                  }}   >  {section.sectionName.length} / 24   </p>
    
</div> 
<RemoveCircleRoundedIcon     onClick={() => deleteSection(section.id)} style={{ fontSize: "35px" ,color:'#7F56D9',  marginLeft:'10px'  }} color="success" />

</div> 
                {/* //! */}
              {/* <div
                style={{
                  marginLeft: "8px",
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                }} >
                <input
                  id={`sectionName-${section.id}`}
                  name={`sectionName-${section.id}`}
                  placeholder="Enter Section Name..."
                  className="nodrag"
                  value={section.sectionName}
                  onChange={(e) =>
                    handleSectionNameChange(section.id, e.target.value)
                  }
                  style={{
                    marginRight:'10px',
                    width:'97%',
                    border:'none',
                    borderRadius:'5px',
                    backgroundColor: "white",
                    marginTop: "10px",
                    fontSize: "13px",
                    marginBottom: "5px",
                    padding:'10px'
                  }}
                />
                 <p  style={{
                            width:'30px',
                            textAlign: "initial",
                            margin: "0px",
                            fontSize: "7px",
                            padding: "5px",
                            // textAlignLast: "end",
                            marginRight:'10px',
                            marginLeft:'10px'
                          }}  >  {section.sectionName.length} / 20 </p>
                <img
                  src={deleteIcon}
                  alt="Delete"
                  style={{
                    width: "15px",
                    height: "15px",
                    marginRight: "25px",
                    padding: "0px",
                    alignContent: "end",
                    cursor: "pointer",
                  }}
                  onClick={() => deleteSection(section.id)}
                />
              </div> */}
              {/* //! */}
              {section.options.map((content, index) => (
                <div key={index}>
                  <div style={{backgroundColor:'white' , padding:'10px' , margin:'8px', borderRadius:'12px'}} >
                   
                    <div style={{display:'flex' , alignItems:'center'}}>
                          <div style={{ width: "90%", display:'flex' , alignItems:'center',backgroundColor:'white',border :'1px solid #E4E7EC' , padding:'5px',borderRadius:'8px'}}>
                                      <input
                                      id={`title-${section.id}-${content.newSectionId}`}
                                      name={`title-${section.id}-${content.newSectionId}`}
                                      value={content.title}
                                      onChange={(e) =>
                                        handleTitleChange(
                                          section.id,
                                          content.newSectionId,
                                          e.target.value
                                        )
                                      }
                                      placeholder="Enter option title.."
                                      className="nodrag"
                                      style={{
                                        border: "none",
                                        width: "97%",
                                        padding: "6px",
                                        borderRadius: "5px",
                                        backgroundColor:'#fff'
                                      }}  />
                                    <p  style={{
                                      width:'40px',
                                        textAlign: "initial",
                                        margin: "0px",
                                        fontSize: "11px",
                                        padding: "5px",
                                        textAlignLast: "end",fontWeight:'bold' ,color:'#475467'
                                      }}  >  {content.title.length} / 24 </p>
                          </div>
                          <RemoveCircleOutlineRoundedIcon    onClick={() =>
                              deleteAdditionalContent(
                                section.id,
                                content.newSectionId
                              ) }   style={{ fontSize: "35px" ,color:'#7F56D9',   marginLeft:'10px' }} color="success" />

                    </div>

                   
                       <div style={{  display:'flex' , alignItems:'center',backgroundColor:'white',border :'1px solid #E4E7EC' , padding:'5px',borderRadius:'8px' , marginTop:'10px'}}>
                                      <input
                                      id={`description-${section.id}-${content.newSectionId}`}
                                      name={`description-${section.id}-${content.newSectionId}`}
                                      value={content.description}
                                      onChange={(e) =>
                                        handleDescriptionChange(
                                          section.id,
                                          content.newSectionId,
                                          e.target.value
                                        )
                                      }
                                       placeholder="Enter option description.."
                                      className="nodrag"
                                      style={{
                                        border: "none",
                                        width: "97%",
                                        padding: "6px",
                                        borderRadius: "5px",
                                        backgroundColor:'#fff'
                                      }}  />
                                    <p  style={{
                                      width:'40px',
                                        textAlign: "initial",
                                        margin: "0px",
                                        fontSize: "11px",
                                        padding: "5px",
                                        textAlignLast: "end", fontWeight:'bold' ,color:'#475467'
                                      }}  >  {content.description.length} / 72</p>
                          </div>

                  </div>
                  {/* <div
                    style={{
                      margin: "10px",
                      paddingRight: "10px",
                      backgroundColor: "rgba(220, 220, 220, 1)",
                      borderRadius: "5px",
                      display: "flex",
                      border: "none",
                      paddingTop: "10px",
                    }}  >
                    <div style={{ marginLeft: "8px", width: "100%" }}>
                      <div style={{}}>
                        <div style={{ display: "flex", justifyContent:'space-between' }}>
                          <p  style={{
                              fontSize: "11px",
                              margin: "0",
                            }}   >   ID :{content.newSectionId}   </p>
                          <img
                            src={deleteIcon}
                            alt="Delete"
                            style={{
                              width: "15px",
                              height: "15px",
                              marginRight: "10px",
                              padding: "0px",
                              alignContent: "end",
                              cursor: "pointer",
                            }}
                            onClick={() =>
                              deleteAdditionalContent(
                                section.id,
                                content.newSectionId
                              ) }   />
                        </div>
                        <input
                          id={`title-${section.id}-${content.newSectionId}`}
                          name={`title-${section.id}-${content.newSectionId}`}
                          value={content.title}
                          onChange={(e) =>
                            handleTitleChange(
                              section.id,
                              content.newSectionId,
                              e.target.value
                            )
                          }
                          placeholder="Enter option title.."
                          className="nodrag"
                          style={{
                            marginTop: "5px",
                            border: "1px solid #ccc",
                            width: "97%",
                            padding: "6px",
                            borderRadius: "5px",
                          }}  />
                        <p  style={{
                            textAlign: "initial",
                            margin: "0px",
                            fontSize: "7px",
                            padding: "5px",
                            textAlignLast: "end",
                          }}  >  {content.title.length} / 20 </p>
                        <input
                          id={`description-${section.id}-${content.newSectionId}`}
                          name={`description-${section.id}-${content.newSectionId}`}
                          value={content.description}
                          onChange={(e) =>
                            handleDescriptionChange(
                              section.id,
                              content.newSectionId,
                              e.target.value
                            )
                          }
                          placeholder="Enter option description.."
                          className="nodrag"
                          style={{
                            marginTop: "5px",
                            border: "1px solid #ccc",
                            width: "97%",
                            padding: "6px",
                            borderRadius: "5px",
                          }} />
                        <p   style={{
                            textAlign: "initial",
                            margin: "0px",
                            fontSize: "7px",
                            padding: "5px",
                            textAlignLast: "end",
                          }} >  {content.description.length} / 72  </p>
                      </div>
                    </div>
                  </div> */}
                </div>
              ))}

<div onClick={() => addAdditionalContent(section.id)}  style={{display:'flex', marginTop:'10px' , cursor:'pointer' , alignItems:'center', marginBottom:'10px', marginLeft:'10px'}}>
            <AddRoundedIcon   style={{ fontSize: "inherit" ,color:'#6941C6' }} color="success" />
       <p style={{margin:'0' , paddingLeft:'10px' , color:'#6941C6' , fontWeight:'bold' , fontSize:'11px' , alignItems:'center'}} > Add another</p>
       
            </div>
 
            </div>
          ))}
        {/* { totalOptionsCount< <p style={{color:'red', textAlign:'center' }} >Maximum Option Limit Reached</p>} */}
          <button
            style={{
              width: "100%",
              marginTop: "10px",
              fontSize:'14px',
              backgroundColor: "#fff",
              color: "#7F56D9",
              padding: "10px 20px",
              border: "none",
              borderRadius: "8px",
              cursor: "pointer",
              border:'1px solid #D0D5DD', fontWeight:'bold'
            }}
            onClick={addSection}  >  Add Sections  </button>
        </div>
      </div>
    </div>
  );
}
export default AskListOptionNode;
// todo : add hover style to add section button
