import { useCallback, useState ,useEffect} from "react";
import { Handle, Position } from "reactflow";
import PDF from '../assets/PDF.png'; 
import icon_image from "../assets/insert-picture-icon.png";
import "./node.css";
import PopUpHeader from './components/popUpAlertHeader.js';
import axios from 'axios';
import { useIdProvider } from "../nodes/provider/idProvider.jsx";
import StatusAlert, { StatusAlertService } from "react-status-alert";

import MessageOutlinedIcon from '@mui/icons-material/MessageOutlined';
import UploadIcon from '@mui/icons-material/Upload';
import LinkIcon from '@mui/icons-material/Link';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
// import PopUpMediaDisplay from '../nodes/components/mediaDisplayComponent.js'
import AudioMessage from '../nodes/components/audioMessage.jsx';


import InsertPhotoSharpIcon from '@mui/icons-material/InsertPhotoSharp';
import VideoLibrarySharpIcon from '@mui/icons-material/VideoLibrarySharp';
import FilePresentSharpIcon from '@mui/icons-material/FilePresentSharp';
import AudiotrackIcon from '@mui/icons-material/Audiotrack';
import { height, maxHeight } from "@mui/system";

function SentMediaNode({ onValueChange ,editData}) {
  const [text, setText] = useState("");
  const [selectedOption, setSelectedOption] = useState('image');
  const [selectedMediaSource, setSelectedMediaSource] = useState('link');
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedURL, setSelectedURL] = useState(''); 

  function getFileNameFromUrl(url) { 
    const urlObject = new URL(url); 
    const pathname = urlObject.pathname; 
    const fileName = pathname.split('/').pop();
    
    return fileName;
  }
  useEffect(() => { 
    const hasEditData = Boolean(editData);
    if (hasEditData) {
      const { text ,mediaType,media_url,type} = editData;
      console.log("editData :", editData); 
      setText(text);
      setSelectedURL(media_url);
      setSelectedOption(mediaType);

      onValueChange({ 
        text:text , 
        mediaType: mediaType , 
        media_url: media_url,
        mediaSource: 'link',
        file:selectedFile
      });
    }
  }, [editData]);

  const getAcceptAttribute = () => {
    switch (selectedOption) {
      case 'image':
        return 'image/*';
      case 'video':
        return 'video/*';
      case 'audio':
        return 'audio/*';
      case 'document':
        return '.pdf,.doc,.docx,.txt,.xls,.xlsx'; 
      default:
        return '';
    }
  };


  const handleFileChange = async (event) => {
    const file = event.target.files[0];
 
    let isValid = false;
    const fileSize = file.size; 
    switch (selectedOption) {
      case 'image':
        
        const ImageExtension =  file.name.split('.').pop().toLowerCase(); 
              console.log('extension :' ,ImageExtension);
             
              const maxSizeImage = 5 * 1024 * 1024;  // 5 mb
              const supportedImageExtensions = ['jpeg', 'png','jpg'];
              if (fileSize > maxSizeImage) {
                StatusAlertService.showWarning("Maximum File Size: 5 MB");
              } else if (supportedImageExtensions.includes(ImageExtension)) {
                isValid = true;
              } else {
                console.log("Unsupported image extension: ", ImageExtension);
                StatusAlertService.showWarning("Unsupported image format!");
              }
 
        
        break;
        case 'video': 
        const VideoExtension = file.name.split('.').pop().toLowerCase(); 
        console.log('extension :' ,VideoExtension);
        const supportedVideoExtensions = ['3gp', 'mp4',];
        const maxSizeVideo = 16 * 1024 * 1024;  // 16 mb
        if (fileSize > maxSizeVideo) {
          StatusAlertService.showWarning("Maximum File Size: 16 MB");
        }  else if (supportedVideoExtensions.includes(VideoExtension)) {
          isValid = true;
        } else {
          console.log("Unsupported video extension: ", VideoExtension);
          StatusAlertService.showWarning("Unsupported video format!");
        }
      break;
  
      case 'audio': 
        const AudioExtension = file.name.split('.').pop().toLowerCase(); 
        console.log('extension :' ,AudioExtension);
        const supportedAudioExtensions = ['mp3', 'arm', 'acc' , 'm4a' ,'ogg' ];
        const maxSizeAudio = 16 * 1024 * 1024;  // 16 mb
        if (fileSize > maxSizeAudio) {
          StatusAlertService.showWarning("Maximum File Size: 16 MB");
        }  else   if (supportedAudioExtensions.includes(AudioExtension)) {
           isValid = true;
        } else {
          console.log("Unsupported audio extension: ", AudioExtension);
          StatusAlertService.showWarning("Unsupported audio format!");
        }
      break;
  
      case 'document': 
        const DocumentExtension = file.name.split('.').pop().toLowerCase(); 
        console.log('extension :' ,DocumentExtension);
        const supportedDocumentExtensions = ['txt', 'xls', 'xlsx' , 'doc' ,'docx' ,'ppt' , 'pptx' , 'pdf' ];
        const maxSizeDocument = 100 * 1024 * 1024;  // 16 mb
        if (fileSize > maxSizeDocument) {
          StatusAlertService.showWarning("Maximum File Size: 100 MB");
        }  else  if (supportedDocumentExtensions.includes(DocumentExtension)) {
         isValid = true;
        } else {
         console.log("Unsupported document extension: ", DocumentExtension);
          StatusAlertService.showWarning("Unsupported document format!");
        }
      break;
    
      default: 
        isValid = false;
        break;
    }

   

    if (isValid) {
    if (file) {
      setSelectedFile(file);
      onValueChange({ 
        text:text , 
        mediaType: selectedOption , 
        media_url: selectedURL, 
         mediaSource: selectedMediaSource,
         file:file
      });
      
      if (!file) {
        console.log('Please select a file to upload.');
        return;
      }
    }}
  };

   
  

  const handleOptionChange = (event) => {
    const value = event.currentTarget.getAttribute('value');

    console.log('option :', value ); 
    setSelectedOption(value);
    setSelectedFile(null);
    setSelectedURL('');
    onValueChange({ 
      text:text , 
      mediaType: value , 
      media_url: selectedURL,
       mediaSource: selectedMediaSource,
      file:selectedFile

    });
  };
  const handleMediaSourceChange = (value) => {
    setSelectedMediaSource(value);
    onValueChange({ 
      text:text , 
      mediaType: selectedOption , 
      media_url: selectedURL,
      mediaSource: value,
      file:selectedFile

    });
  };


  const onChangeURLfield = async (event) => {
    setSelectedURL(event.target.value);
    console.log("value : ", event.target.value)
    onValueChange({ 
      text:text , 
      mediaType: selectedOption , 
      media_url: event.target.value,
      mediaSource: selectedMediaSource,
      file:selectedFile

    });
  };


  const onChange = (event) => {
    const newText = event.target.value;
    if (newText.length <= 1024) {
      setText(newText);
    }
    onValueChange({ 
      text:newText , 
      mediaType: selectedOption , 
      media_url: selectedURL,
      mediaSource: selectedMediaSource,
      file:selectedFile
    });
  };

  return (
    <div className="text-updater-node" style={{padding:'0' , marginLeft:'10px' , marginRight:'10px' }}>
      {/* <PopUpHeader title= 'Send Media'  color= '#039855'  Icon={MessageOutlinedIcon}/>   */}
      <div
        style={{
          marginLeft: "10px",
          marginRight: "10px",
          marginTop: "10px",
          padding: "0px",
        }}
      >
        {/* //! RADIO BUTTONS    */}
        <div style={{ display:'flex',flexDirection:"row" ,paddingBottom:"10px" ,}}>
        {/* //! ------------------------------ */}

        {selectedOption === 'audio'?
         <div  value="audio"  onClick={handleOptionChange} style={{width :'100px' ,cursor:'pointer', height:'42px' ,backgroundColor:'#F9F5FF' ,border:'1px solid #7F56D9', borderRadius:'8px' , marginRight:'10px' }}>
        <div style={{height:'100%', display: "flex", alignItems: "center" , alignItems : 'center',justifyContent:'center' }}>
          <div
            style={{
              width: "35px",
              height: "35px",
              borderRadius: "50%",
              backgroundColor: "#F4EBFF",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position: "relative",
            }}
          >
            <div
              style={{
                width: "25px",
                height: "25px",
                borderRadius: "50%",
                backgroundColor: "#E7D9FE",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "absolute",
              }}
            >
              <div
                style={{
                  height: "10px",
                  width: "10px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <AudiotrackIcon style={{ fontSize: "inherit" ,color:'#53389E' }} color="success" />
              </div>
            </div>
          </div>
          <p
            style={{
              color: "#344054",
              margin: "0",
              paddingLeft: "10px",
              fontWeight: "bold",
              fontSize:'15px'

            }}
          >
           Audio
          </p>
        </div>
        </div> :
              <div value="audio"  onClick={handleOptionChange} style={{width :'100px' ,cursor:'pointer', height:'42px' ,backgroundColor:'#fff' ,border:'1px solid #E4E7EC', borderRadius:'8px' , marginRight:'10px' }}>
              <div style={{height:'100%', display: "flex", alignItems: "center" , alignItems : 'center',justifyContent:'center' }}>
                <div
                  style={{
                    width: "35px",
                    height: "35px",
                    borderRadius: "50%",
                    backgroundColor: "#F4EBFF",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    position: "relative",
                  }}
                >
                  <div
                    style={{
                      width: "25px",
                      height: "25px",
                      borderRadius: "50%",
                      backgroundColor: "#E7D9FE",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      position: "absolute",
                    }}
                  >
                    <div
                      style={{
                        height: "10px",
                        width: "10px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <AudiotrackIcon style={{ fontSize: "inherit" ,color:'#53389E' }} color="success" />
                    </div>
                  </div>
                </div>
                <p
                  style={{
                    color: "#344054",
                    margin: "0",
                    paddingLeft: "10px",
                    fontWeight: "bold",
                    fontSize:'15px'
                  }}
                >
                 Audio
                </p>
              </div>
              </div>
        } 
        {selectedOption === 'image'?
         <div   value="image"  onClick={handleOptionChange}  style={{width :'100px' ,cursor:'pointer', height:'42px' ,backgroundColor:'#F9F5FF' ,border:'1px solid #7F56D9', borderRadius:'8px' , marginRight:'10px' }}>
        <div style={{height:'100%', display: "flex", alignItems: "center" , alignItems : 'center',justifyContent:'center' }}>
          <div
            style={{
              width: "35px",
              height: "35px",
              borderRadius: "50%",
              backgroundColor: "#F4EBFF",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position: "relative",
            }}
          >
            <div
              style={{
                width: "25px",
                height: "25px",
                borderRadius: "50%",
                backgroundColor: "#E7D9FE",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "absolute",
              }}
            >
              <div
                style={{
                  height: "10px",
                  width: "10px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <InsertPhotoSharpIcon style={{ fontSize: "inherit" ,color:'#53389E' }} color="success" />
              </div>
            </div>
          </div>
          <p
            style={{
              color: "#344054",
              margin: "0",
              paddingLeft: "10px",
              fontWeight: "bold",
              fontSize:'15px'

            }}
          >
           Image
          </p>
        </div>
        </div> :
              <div value="image"  onClick={handleOptionChange} style={{width :'100px' ,cursor:'pointer', height:'42px' ,backgroundColor:'#fff' ,border:'1px solid #E4E7EC', borderRadius:'8px' , marginRight:'10px' }}>
              <div style={{height:'100%', display: "flex", alignItems: "center" , alignItems : 'center',justifyContent:'center' }}>
                <div
                  style={{
                    width: "35px",
                    height: "35px",
                    borderRadius: "50%",
                    backgroundColor: "#F4EBFF",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    position: "relative",
                  }}
                >
                  <div
                    style={{
                      width: "25px",
                      height: "25px",
                      borderRadius: "50%",
                      backgroundColor: "#E7D9FE",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      position: "absolute",
                    }}
                  >
                    <div
                      style={{
                        height: "10px",
                        width: "10px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <InsertPhotoSharpIcon style={{ fontSize: "inherit" ,color:'#53389E' }} color="success" />
                    </div>
                  </div>
                </div>
                <p
                  style={{
                    color: "#344054",
                    margin: "0",
                    paddingLeft: "10px",
                    fontWeight: "bold",
                    fontSize:'15px'
                  }}
                >
                 Image
                </p>
              </div>
              </div>
        } 
        {selectedOption === 'video'?
         <div   value="video"  onClick={handleOptionChange}  style={{width :'100px' ,cursor:'pointer', height:'42px' ,backgroundColor:'#F9F5FF' ,border:'1px solid #7F56D9', borderRadius:'8px' , marginRight:'10px' }}>
        <div style={{height:'100%', display: "flex", alignItems: "center" , alignItems : 'center',justifyContent:'center' }}>
          <div
            style={{
              width: "35px",
              height: "35px",
              borderRadius: "50%",
              backgroundColor: "#F4EBFF",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position: "relative",
            }}
          >
            <div
              style={{
                width: "25px",
                height: "25px",
                borderRadius: "50%",
                backgroundColor: "#E7D9FE",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "absolute",
              }}
            >
              <div
                style={{
                  height: "10px",
                  width: "10px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <VideoLibrarySharpIcon style={{ fontSize: "inherit" ,color:'#53389E' }} color="success" />
              </div>
            </div>
          </div>
          <p
            style={{
              color: "#344054",
              margin: "0",
              paddingLeft: "10px",
              fontWeight: "bold",
              fontSize:'15px'

            }}
          >
           Video
          </p>
        </div>
        </div> :
              <div value="video"  onClick={handleOptionChange} style={{width :'100px' ,cursor:'pointer', height:'42px' ,backgroundColor:'#fff' ,border:'1px solid #E4E7EC', borderRadius:'8px' , marginRight:'10px' }}>
              <div style={{height:'100%', display: "flex", alignItems: "center" , alignItems : 'center',justifyContent:'center' }}>
                <div
                  style={{
                    width: "35px",
                    height: "35px",
                    borderRadius: "50%",
                    backgroundColor: "#F4EBFF",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    position: "relative",
                  }}
                >
                  <div
                    style={{
                      width: "25px",
                      height: "25px",
                      borderRadius: "50%",
                      backgroundColor: "#E7D9FE",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      position: "absolute",
                    }}
                  >
                    <div
                      style={{
                        height: "10px",
                        width: "10px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <VideoLibrarySharpIcon style={{ fontSize: "inherit" ,color:'#53389E' }} color="success" />
                    </div>
                  </div>
                </div>
                <p
                  style={{
                    color: "#344054",
                    margin: "0",
                    paddingLeft: "10px",
                    fontWeight: "bold",
                    fontSize:'15px'
                  }}
                >
                Video
                </p>
              </div>
              </div>
        } 
        {selectedOption === 'document'?
         <div   value="document"  onClick={handleOptionChange}  style={{width :'130px' , height:'42px' ,backgroundColor:'#F9F5FF' ,border:'1px solid #7F56D9', borderRadius:'8px' , marginRight:'10px' , cursor:'pointer' }}>
        <div style={{height:'100%', display: "flex", alignItems: "center" , alignItems : 'center',justifyContent:'center' }}>
          <div
            style={{
              width: "35px",
              height: "35px",
              borderRadius: "50%",
              backgroundColor: "#F4EBFF",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position: "relative",
            }}
          >
            <div
              style={{
                width: "25px",
                height: "25px",
                borderRadius: "50%",
                backgroundColor: "#E7D9FE",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "absolute",
              }}
            >
              <div
                style={{
                  height: "10px",
                  width: "10px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <FilePresentSharpIcon style={{ fontSize: "inherit" ,color:'#53389E' }} color="success" />
              </div>
            </div>
          </div>
          <p
            style={{
              color: "#344054",
              margin: "0",
              paddingLeft: "10px",
              fontWeight: "bold",
              fontSize:'15px'

            }}
          >
           Document
          </p>
        </div>
        </div> :
              <div value="document"  onClick={handleOptionChange} style={{width :'130px' , height:'42px' ,backgroundColor:'#fff' ,border:'1px solid #E4E7EC', borderRadius:'8px' , marginRight:'10px' ,cursor:'pointer'}}>
              <div style={{height:'100%', display: "flex", alignItems: "center" , alignItems : 'center',justifyContent:'center' }}>
                <div
                  style={{
                    width: "35px",
                    height: "35px",
                    borderRadius: "50%",
                    backgroundColor: "#F4EBFF",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    position: "relative",
                  }}
                >
                  <div
                    style={{
                      width: "25px",
                      height: "25px",
                      borderRadius: "50%",
                      backgroundColor: "#E7D9FE",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      position: "absolute",
                    }}
                  >
                    <div
                      style={{
                        height: "10px",
                        width: "10px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <FilePresentSharpIcon style={{ fontSize: "inherit" ,color:'#53389E' }} color="success" />
                    </div>
                  </div>
                </div>
                <p
                  style={{
                    color: "#344054",
                    margin: "0",
                    paddingLeft: "10px",
                    fontWeight: "bold",
                    fontSize:'15px'
                  }}
                >
                Document
                </p>
              </div>
              </div>
        } 
        
      
    </div>
 
   
    
        {/* //! Buttons */}
        {/* //! UPLOAD */}
        <div style={{display:'flex',  height:'40px'}} >
          <div 
           style={{
            borderRadius:'10px 10px 10px 0', 
            backgroundColor : selectedMediaSource==='upload'?'#7F56D9' :'#fff' ,
             paddingLeft:'10px' ,
             paddingRight:'10px', 
             display:'flex', 
             alignItems:'center',
             cursor:'pointer'}}
             
             value='upload'
             onClick={() => handleMediaSourceChange('upload')}
             >
          <UploadIcon style={{ fontSize: "inherit"  , color:selectedMediaSource==='upload'?'#fff':'#51369E' , paddingRight:'5px' }} color="success" />
            <p style={{margin:'0px' , color:selectedMediaSource==='upload'?'#fff':'#51369E'}}>Upload</p>
          </div>
          <div style={{borderRadius:'10px 10px 10px 0', 
            backgroundColor : selectedMediaSource==='link'?'#7F56D9' :'#fff'  , 
            paddingLeft:'10px' ,
            paddingRight:'10px', 
            display:'flex',
             alignItems:'center',
             cursor:'pointer' }}
             
             value='link'
             onClick={() => handleMediaSourceChange('link')}
             >
          <LinkIcon style={{ fontSize: "inherit" ,color:selectedMediaSource==='link'?'#fff':'#51369E', paddingRight:'5px' }} color="success" />
            <p style={{margin:'0px' , color:selectedMediaSource==='link'?'#fff':'#51369E'}}>Link</p>
          </div>
           
          
    </div>

         
    {/* //! selected media item selector */}
    {selectedMediaSource==='upload'? 
    <label htmlFor="file-upload" >
    <div  
    htmlFor="file-upload" 
     style={{
      padding: '5px',
      backgroundColor: '#fff',
      color: '#000',
      border: '1px solid #7F56D9',
      borderRadius: '0  12px 12px 12px',
      marginRight: '10px',
      // width:'100%',
      marginBottom: '20px',
      height:'110px',
      display:'flex',
      flexDirection:'column',
      alignItems:'center'
    }}
    
    >

<div
                  style={{
                    width: "35px",
                    height: "35px",
                    borderRadius: "50%",
                    backgroundColor: "#F9FAFB",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    position: "relative",
                  }}
                >
                  <div
                    style={{
                      width: "25px",
                      height: "25px",
                      borderRadius: "50%",
                      backgroundColor: "#F2F4F7",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      position: "absolute",
                    }}
                  >
                    <div
                      style={{
                        height: "10px",
                        width: "10px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <CloudUploadOutlinedIcon style={{ fontSize: "inherit" ,color:'#475467' }} color="success" />
                    </div>
                  </div>
                </div>
 
      <p style={{cursor: 'pointer' , color:'#7F56D9' , fontWeight:'bold', margin:'3px' }}>Click To Upload</p>
      
    {selectedOption=='audio' ?  <p style={{fontSize:"12px",margin:"0",paddingBottom:"5px",color:"grey",}} >Allowed Media Type: .mp3 , .amr, .aac  , .m4a , .ogg  </p>  : <div></div>}
    {selectedOption=='image' ?  <p style={{fontSize:"12px",margin:"0",paddingBottom:"5px",color:"grey",}} >Allowed media type: .jpeg , .png  </p>  : <div></div>}
    {selectedOption=='video' ?  <p style={{fontSize:"12px",margin:"0",paddingBottom:"5px",color:"grey",}} >Allowed media type: .3gp , .mp4  </p>  : <div></div>}
    {selectedOption=='document' ?  <p style={{fontSize:"12px",margin:"0",paddingBottom:"5px",color:"grey",}} >Allowed media type: .txt , .xls , .xlsx , .doc , .docx , .ppt , .pptx , .pdf  </p>  : <div></div>}

    {/* //! file size */}
    {selectedOption=='audio' ?  <p style={{fontSize:"12px",margin:"0",paddingBottom:"5px",color:"grey",}} >Maximum File Size: 16 MB </p>  : <div></div>}
    {selectedOption=='image' ?  <p style={{fontSize:"12px",margin:"0",paddingBottom:"5px",color:"grey",}} >Maximum File Size: 5 MB </p>  : <div></div>}
    {selectedOption=='video' ?  <p style={{fontSize:"12px",margin:"0",paddingBottom:"5px",color:"grey",}} >Maximum File Size: 16 MB </p>  : <div></div>}
    {selectedOption=='document' ?  <p style={{fontSize:"12px",margin:"0",paddingBottom:"5px",color:"grey",}} >Maximum File Size: 100 MB </p>  : <div></div>}

  
       <input
          id="file-upload"
          type="file"
          accept={getAcceptAttribute()}
          onChange={handleFileChange}
          style={{ display: 'none' }}
        />
 
    {selectedFile && (
      <div>
        <p style={{fontSize:"12px",margin:"0",paddingBottom:"5px",color:"#7F56D9",}} > {selectedFile.name}</p>
 
      </div>
    )}
  </div></label>


    :null }
    {selectedMediaSource==='link'? 
      <div  
       style={{
        padding: '5px',
        backgroundColor: '#fff',
        color: '#000',
        border: '1px solid #7F56D9',
        borderRadius: '12px  12px 12px 12px',
        marginRight: '10px',
        // width:'100%',
        marginBottom: '20px',
        height:'110px',
        display:'flex',
        flexDirection:'row',
        alignItems:'start'
      }} > 

{selectedOption === 'image' && selectedURL ? (
  <img 
    src={selectedURL}
    alt="Link image" 
    style={{ 
      maxHeight: '110px', 
      maxWidth: '150px', 
      margin: "0",  
      marginRight: '6px', 
      borderRadius: "10px 10px 10px 10px" 
    }} 
  />
) : null}

{selectedOption === 'audio' && selectedURL ? (
  <div  style={{display:'flex' ,alignItems:'center' }}>
  <AudioMessage audioUrl={selectedURL} />
</div>
) : null}

{selectedOption === 'video' && selectedURL ? (
 <video style={{
  maxHeight: '110px', 
      // maxWidth: '150px', 
      margin: "0",  
      marginRight: '6px', 
      borderRadius: "10px 10px 10px 10px" 
   }} controls>
 <source src={selectedURL} type="video/mp4" />
 Your browser does not support the video tag.
</video>
) : null}


{selectedOption === 'document' && selectedURL ? (
  <a 
    href={selectedURL} 
    target="_blank" 
    rel="noopener noreferrer" 
    style={{ textDecoration: 'none',height: '100%',  }} // Prevents any default link styling
  >
    <div style={{ border: '1px solid #7F56D9', marginRight: '6px', height: '100%', borderRadius: "12px", cursor: 'pointer' }}>
      <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
        <img src={PDF} alt="PDF icon" style={{ margin: "0", borderRadius: "10px 10px 10px 10px" }} />
        <p style={{
          margin: "0",
          marginLeft: '15px',
          fontSize: "12px",
          paddingRight: "5px",
          color: 'black',
          maxWidth: '100%',
          overflow: 'hidden',
          whiteSpace: 'normal',
          wordWrap: 'break-word',
          textAlign: 'start'
        }}>
          {getFileNameFromUrl(selectedURL)}
        </p>
      </div>
      <hr style={{ border: 'none', borderTop: '1px solid grey', margin: '25px 0 1px 0', opacity: '0.5' }} />
    </div>
  </a>
) : null}



     
  
 
     <textarea
      id="text"
      name="text"
      value={selectedURL}
      placeholder="URL here..." 
      onChange={onChangeURLfield}
      className="nodrag"
      style={{  
        width:"100%",
        maxWidth:'100%',
        // minWidth:'65%',
        height:'88px',
       
        border: '1px solid #7F56D9',
        borderRadius: "12px",
        padding: "10px",
        color:'#667085',
        marginBottom: '20px',
         resize: 'none'
             }} />
   
      
    </div>
    :null }
        {/* //! */}
        <div
          style={{ display: "flex", flexDirection: "row", alignItems: "center",paddingBottom:'5px' }}
        >
          <p
            style={{
              margin: "0",
              fontSize: "10px",
              paddingRight: "5px",
              color: "grey",
            }}
          >Caption </p>
          <p
            style={{
              margin: "0",
              padding: "2px",
              fontSize: "6px",
              marginRight: "20px",
              color: "white",
              backgroundColor:"grey",
            }} >Optional</p>
        </div>
        
         <textarea
          id="text"
          name="text"
          value={text}
          placeholder="Enter caption" 
          onChange={onChange}
          className="nodrag"
          style={{  
            width:"95%",
            maxWidth:'95%',
            minWidth:'95%',
            maxHeight:'200px',
            marginRight:'10px',
            minHeight: "60px",
            border: "1px solid #D0D5DD",
            borderRadius: "12px",
            padding: "10px",
            color:'#667085'
                 }} />
          <p
            style={{
              textAlign: "initial",
              margin: "0px",
              fontSize: "7px",
              padding: "5px",
              textAlignLast:"end"
            }}
          >
            {text.length} / 1024
          </p>
      </div>
    </div>
  );
}
export default SentMediaNode;
// todo : change header icon