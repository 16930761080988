import { useCallback, useState } from "react";
import { Handle, Position } from "reactflow";

import speechBubble from "../assets/speech-bubble.png";
import "./node.css";

function SendTemplateNode() {
    const [selectedOption, setSelectedOption] = useState('');
    const optionsList = ["Mesg 1", "Mesg 2", "Mesg 3"];
  
    const handleOptionChange = (event) => {
      setSelectedOption(event.target.value);
    };

  const [text, setText] = useState("");
  const [isBold, setIsBold] = useState(false);
  const [isItalic, setIsItalic] = useState(false);
  const [isStrikethrough, setIsStrikethrough] = useState(false);

  const toggleBold = () => {
    setIsBold(!isBold);
  };
  const toggleItalic = () => {
    setIsItalic(!isItalic);
  };
  const toggleStrikethrough = () => {
    setIsStrikethrough(!isStrikethrough);
  };

  const onChange = (event) => {
    const newText = event.target.value;
    // Check if the length of the new text is within the limit
    if (newText.length <= 4096) {
      setText(newText);
    }
  };

  return (
    <div className="text-updater-node">
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          // justifyContent: "center",
          alignItems: "center",
          paddingTop: "5px",
          // paddingBottom: "5px",
          // width:"140px",
        }}
      >
        <img
          src={speechBubble}
          alt="Image description"
          style={{
            width: "20px",
            height: "20px",
            marginRight: "5px",
          }}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "baseline",
            paddingTop: "5px",
            paddingBottom: "5px",
          }}
        >
          <p style={{ margin: "0", fontSize: "15px" }}>Send Template</p>{" "}
          <p
            style={{
              margin: "0",
              fontSize: "8px",
              paddingLeft: "10px",
              paddingRight: "20px",
              color: "blue",
            }}
          >
            Learn more
          </p>{" "}
        </div>
      </div>

      <hr style={{ borderTop: "0.5px solid #ccc", opacity: "0.3" }} />

      <div
        style={{
          marginLeft: "10px",
          marginRight: "10px",
          marginTop: "0px",
          padding: "0px",
        }}
      >
        <div
          style={{ display: "flex", flexDirection: "row", alignItems: "start" }}
        >
          <p
            style={{
              margin: "0",
              fontSize: "8px",
              paddingRight: "5px",
              color: "grey",
            }}
          >
            Template
          </p>{" "}
          <p
            style={{
              margin: "0",
              fontSize: "12px",
              paddingRight: "20px",
              color: "red",
            }}
          >
            *
          </p>{" "}
        </div>
        {/* //! sent text */}
        <div>
      <select value={selectedOption} onChange={handleOptionChange} style={{
          width: '100%',
          borderColor: 'blue',
          color: 'black', 
           borderRadius: '5px', 
        }}>
        <option value="">Select an option</option>
        {optionsList.map((option, index) => (
          <option key={index} value={option} style={{ color: 'blue' ,height: '40px' }}>{option}</option>
        ))}
      </select>
    </div>
       
        
      </div>

      <div
        style={{
          width: "100%",
          paddingBottom: "10px",
          borderRadius: "5px",
        }}  >
        <div style={{ margin: "0", position: "relative" }}>
          <Handle
            type="source"
            position={Position.Right}
            id="b"
            style={{
              position: "absolute",
              right: 0,
              top: "50%",
              transform: "translateY(-50%)",
            }}
          />
        </div>
      </div>
    </div>
  );
}
export default SendTemplateNode;
