import React from 'react';

const TextWithStyles = ({ text }) => {
  const parseText = (text) => {
    const regex = /(\*[^*]+\*|_[^_]+_|```[^`]+```|~[^~]+~)/g;  
    const parts = text.split(regex);  

    return parts.map((part, index) => {
      if (part.startsWith('*') && part.endsWith('*')) {
        return <strong key={index}>{parseText(part.slice(1, -1))}</strong>;  
      }
      if (part.startsWith('_') && part.endsWith('_')) {
        return <em key={index}>{parseText(part.slice(1, -1))}</em>; 
      }
      if (part.startsWith('```') && part.endsWith('```')) {
        return <code key={index} style={{ fontFamily: 'monospace' }}>{parseText(part.slice(3, -3))}</code>;  
      }
      if (part.startsWith('~') && part.endsWith('~')) {
        return <s key={index}>{parseText(part.slice(1, -1))}</s>;  
      }
      return part;  
    });
  };

  return <div>{parseText(text)}</div>;
};

export default TextWithStyles;
