import { useCallback, useState } from "react";
import { Handle, Position } from "reactflow";
import { v4 as uuidv4 } from "uuid";


import messageIcon from "../../assets/icons/media-icon.png";
import copyIcon from "../../assets/svg/copy_1.svg";
import deleteIcon from "../../assets/svg/delete_icon.svg"; // svg/delete_icon.svg
import editIcon from "../../assets/svg/edit_icon.svg"; // svg/edit_icon.svg
import addIcon from "../../assets/svg/add_icon.svg";
import "../node.css";
import MediaComponent from '../components/mediaDisplayComponent.js'
//! Provider
import { useMyData } from '../provider/dataProvider';
import { useIdProvider} from '../provider/idProvider';
import './css/textNodeStyle.css';

import ContentCopyIcon from '@mui/icons-material/ContentCopy'; 
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import StarIcon from '@mui/icons-material/Star';

function SentMediaNode({ data, id  }) {
  const { text, type, media_url } = data;
  const [isExpanded, setIsExpanded] = useState(false);
  const [isSelected, setIsSelected] = useState(false);
  const toggleReadMore = () => {
    setIsExpanded(!isExpanded);
  };

  const generateUniqueId = () => {
    return uuidv4();
  };

  const {  
    keyWordList, setKeywordsList,   
     lastUniqueId, setLastUniqueId , 
     canvasTitle, setCanvasTitle , 
     uniqueToken, setUniqueToken, 
     flowId, setFlowId,
      // ! Nodes 
      nodes ,setNodes, onNodesChange,
      // ! edges
      edges, setEdges, onEdgesChange,
      //! initial node id
    initialNodeId, 
    setInitialNodeId ,
      //
  } = useIdProvider();

  const deleteNode=()=>{
    const nodeIndex = nodes.findIndex(node => node.id === id);
       if (nodeIndex === -1) {
       console.warn(`Node with ID "${id}" not found for deletion.`);
       return;
     }

     const updatedNodes = nodes.filter(node => node.id !== id);
     setNodes(updatedNodes); 

     const updatedEdges = edges.filter(edge => edge.source !== id && edge.target !== id);
     setEdges(updatedEdges);
  }


  const { boolValue, setBoolValue, Editdata, setData, editNodeType, setEditNodeType , itemId, setItemId,positionX, setPositionX,positionY, setPositionY} = useMyData();

  const toggleBoolValue = () => {
    console.log("===================== CHECK BOOL  ========================");
    console.log(data);
    console.log(type);
    console.log(boolValue);
    console.log(id);
    console.log("===================== CHECK BOOL  ========================");
    setEditNodeType("sentMeadiaMessage");
    setData(data);
    setItemId(id);
    setBoolValue(true);
  };

  const getId = () => {
    const id = lastUniqueId + 1;
    setLastUniqueId(id);
    console.log('UNIQUE ID : ', id );
    console.log('PREVIOUS UNIQUE ID : ', lastUniqueId );
    return `${id}`; //dndnode_
  };

  const createDuplicate=()=>{
    let nodIde;
    let randomX;
    let randomY; 
    nodIde = getId();
    console.log('nodIde: ',nodIde);

    const nodeWithId = nodes.find((node) => node.id === id);
    const positionDetails = nodeWithId.position;
      console.log("Position details:", positionDetails);
      randomX = positionDetails.x+ 350;
      randomY = positionDetails.y;

    console.log('id: ',id);

    const  newNode = {
      id: nodIde,
      type: "sentMeadiaMessage",
      position: { x: randomX, y: randomY },
      data: {
        ...data,
        type: data.mediaType,
      },
    };
 
    console.log('all previous nodes : ', nodes );

    console.log('new node : ', newNode );
    setNodes([...nodes,newNode]);

  }

  const makeNodeInitial = () => {
    // setPlay(true);
    if(isSelected){
      setInitialNodeId('');
    }else{
      setInitialNodeId(id);
    }
    setIsSelected(!isSelected);
    console.log("===================== CHECK BOOL  ========================");
  };

  return (

    <div className="chat-container">
    <div className="hover-circles">
    <div className="circle" onClick={makeNodeInitial}>
          {initialNodeId==id ? (
            <StarIcon fontSize="small" sx={{ color: 'violet' }} />
          ) : (
            <StarBorderIcon fontSize="small" sx={{ color: 'violet' }} />
          )}
        </div>
      <div className="circle"   onClick={() => toggleBoolValue()}> 
      <BorderColorOutlinedIcon fontSize="small" color="success" />
 
      </div>
      <div className="circle"  onClick={() => createDuplicate()}>
        <ContentCopyIcon  fontSize="small" color="inherit"/>
      </div>
      <div className="circle"   onClick={deleteNode}>
        <DeleteOutlinedIcon  fontSize="medium"  color="warning"/>
      </div>
    </div>
    <div className="message received box3 sb14">
       <Handle
          type="target"
          isConnectable={true}
          position={Position.Top}
          id={generateUniqueId()}
          style={{
            width: 20,
            height: 20, 
            top: -10,
            left:10,
            backgroundColor:'#09D999' 
          }}  />
      <div className="message-bubble">
      <MediaComponent mediaType={type} mediaUrl={media_url} />
        {/* <p className="commen-text">{text}</p> */}
        <p className="commen-text">
        {isExpanded ? text : `${text.substring(0, 300)}...`}
      </p>
      {text.length > 300 && (
        <p onClick={toggleReadMore} style={{color:'blue' , cursor:'pointer' , margin:'0' , fontWeight:'normal', textAlign:'left'}}>  {isExpanded ? `Read Less` : `Read More`} </p>
      )}

        <span className="timestamp"> </span>
      </div>
      <Handle
          type="source"
          isConnectable={true}
          position={Position.Bottom}
          id={generateUniqueId()}
          style={{
            width: 20,
            height: 20,
            bottom: 10,  
            backgroundColor:'#8286F9',
            transform: "translateX(625%)",
          }}  />
    </div> 
  </div>
  );
}
export default SentMediaNode;
