import { useCallback, useState ,useEffect , useRef } from "react";
import { Handle, Position } from "reactflow";

import icon_pen from "../assets/pen.png";
import "./node.css";

import PopUpHeader from './components/popUpAlertHeader.js';
import MessageOutlinedIcon from '@mui/icons-material/MessageOutlined';

function SentTextNode({ onValueChange, editData }) {
  const [text, setText] = useState("");
  const [isBold, setIsBold] = useState(false);
  const [isItalic, setIsItalic] = useState(false);
  const [isStrikethrough, setIsStrikethrough] = useState(false);
  const [isMonoSpace, setIsMonoSpace] = useState(false);

  const textareaRef = useRef(null);


  const toggleBold = () => {
    //!

    const textarea = textareaRef.current;
    const start = textarea.selectionStart;
    const end = textarea.selectionEnd;

    // Check if cursor is inside a bold section
    if (text[start - 1] === '*' && text[end] === '*') {
      // Remove bold markers around the cursor
      const updatedText = text.slice(0, start - 1) + text.slice(start, end) + text.slice(end + 1);
      setText(updatedText);
      onValueChange({ text:  updatedText });
      textarea.focus();
      textarea.setSelectionRange(start - 1, end - 1);
    } else {
      // Add bold markers around the cursor
      const updatedText = text.slice(0, start) + '*' + text.slice(start, end) + '*' + text.slice(end);
      setText(updatedText);
      onValueChange({ text:  updatedText });
      textarea.focus();
      textarea.setSelectionRange(start + 1, end + 1);
    }
  };

  const toggleItalic = () => {


    const textarea = textareaRef.current;
    const start = textarea.selectionStart;
    const end = textarea.selectionEnd;

     // Check if cursor is inside a bold section
     if (text[start - 1] === '_' && text[end] === '_') {
      // Remove bold markers around the cursor
      const updatedText = text.slice(0, start - 1) + text.slice(start, end) + text.slice(end + 1);
      setText(updatedText);
      onValueChange({ text:  updatedText });
      textarea.focus();
      textarea.setSelectionRange(start - 1, end - 1);
    } else {
      // Add bold markers around the cursor
      const updatedText = text.slice(0, start) + '_' + text.slice(start, end) + '_ ' + text.slice(end);
      setText(updatedText);
      onValueChange({ text:  updatedText });
      textarea.focus();
      textarea.setSelectionRange(start + 1, end + 1);
    }
    
  };
  const toggleMonoSpace = () => {

    
    const textarea = textareaRef.current;
    const start = textarea.selectionStart;
    const end = textarea.selectionEnd;

     // Check if cursor is inside a bold section
     if (text[start - 1] === '```' && text[end] === '```') {
      // Remove bold markers around the cursor
      const updatedText = text.slice(0, start - 1) + text.slice(start, end) + text.slice(end + 1);
      setText(updatedText);
      onValueChange({ text:  updatedText });
      textarea.focus();
      textarea.setSelectionRange(start - 1, end - 1);
    } else {
      // Add bold markers around the cursor
      const updatedText = text.slice(0, start) + "```" + text.slice(start, end) + "``` " + text.slice(end);
      setText(updatedText);
      onValueChange({ text:  updatedText });
      textarea.focus();
      textarea.setSelectionRange(start + 1, end + 1);
    }

  };

  const toggleStrikethrough = () => {

    const textarea = textareaRef.current;
    const start = textarea.selectionStart;
    const end = textarea.selectionEnd;

    // Check if cursor is inside a bold section
    if (text[start - 1] === '~' && text[end] === '~') {
      // Remove bold markers around the cursor
      const updatedText = text.slice(0, start - 1) + text.slice(start, end) + text.slice(end + 1);
      setText(updatedText);
      onValueChange({ text:  updatedText });
      textarea.focus();
      textarea.setSelectionRange(start - 1, end - 1);
    } else {
      // Add bold markers around the cursor
      const updatedText = text.slice(0, start) + '~' + text.slice(start, end) + '~' + text.slice(end);
      setText(updatedText);
      onValueChange({ text:  updatedText });
      textarea.focus();
      textarea.setSelectionRange(start + 1, end + 1);
    }
 
  };

  const onChange = (event) => {
    const newText = event.target.value;
    if (newText.length <= 4096) {
      setText(newText);
       
        
        onValueChange({ text: newText });
      
    }
  };

  useEffect(() => {
    // data has value
    const hasEditData = Boolean(editData);
    if (hasEditData) {
      const { text } = editData;
      console.log("editData :", editData);
      console.log("EditText :", text);
      setText(text);
      // const isTextBold = text.startsWith('*') && text.endsWith('*');
      // setIsBold(isTextBold);
    }
  }, [editData]);

  return (
    <div className="text-updater-node" style={{padding:'0', marginLeft:'10px' , marginRight : '10px' ,  }}>
      {/* <PopUpHeader title= 'Send Text'  color= '#039855'  Icon={MessageOutlinedIcon}/> */}
    
      <div
        style={{
          marginLeft: "10px",
          marginRight: "10px",
          marginTop: "10px",
          padding: "0px",
        }} >
 
        <textarea
          id="text"
          name="text"
          value={text}
          placeholder="Enter your text"
          ref={textareaRef}
          onChange={onChange}
          className="nodrag"
          style={{  
            width:"95%",
            maxWidth:'95%',
            minWidth:'95%',
            maxHeight:'200px',
            marginRight:'10px',
            minHeight: "60px",
            border: "1px solid #D0D5DD",
            borderRadius: "12px",
            padding: "10px",
            color:'#667085'
                 }} />
        <div
          style={{ display: "flex", flexDirection: "row",  justifyContent:'space-between' }}
        >
        <div
          style={{ display: "flex", flexDirection: "row", paddingTop: "5px" }}
        >
          <div
            class="rounded-button"
            onClick={toggleBold}
            // style={{ backgroundColor: isBold ? "blue" : "initial" }}
          >
            <i class="fa-solid fa-bold"></i>{" "}
            <p
              style={{
                fontSize: "10px",
                fontWeight: "bold",
                // color: isBold ? "white" : "black",
              }}
            >
              B
            </p>
          </div>
          <div
            class="rounded-button"
            onClick={toggleItalic} 
          >
            <i class="fa-solid fa-italic"></i>{" "}
            <p
              style={{
                fontStyle: "italic",
                fontSize: "10px", 
              }}
            >
              I
            </p>
          </div>
          <div
            class="rounded-button"
            onClick={toggleStrikethrough} 
          >
            <span class="strikethrough">
              <p
                style={{
                  fontSize: "10px", 
                }}
              >
                S
              </p>
            </span>
          </div>
          <div
            class="rounded-button"
            onClick={toggleMonoSpace} 
          >
            <span class="fa-solid">
              <p
                style={{
                  fontSize: "10px", 
                }}
              >
                M
              </p>
            </span>
          </div>
          </div>
          <p
            style={{
              textAlign: "initial",
              margin: "0px",
              fontSize: "13px",
              fontWeight :'bold',
              padding: "5px",
              alignSelf: "flex-end",
              color:'#344054'
            }}
          >
            {text.length} / 4096
          </p>
        </div>
      </div>
    </div>
  );
}
export default SentTextNode;


// todo : make styles icons
// todo : add style functionality