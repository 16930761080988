import React, { useState, useEffect } from "react";
import icon_back from "./assets/icon_back.png";
import icon_close from "./assets/close.png";
import envelope from "./assets/envelope.png";
import question from "./assets/speech-bubble.png";
import axios from "axios";

import lettert from "./assets/letter-t.png";
import sent from "./assets/paper-plane.png";
import planning from "./assets/planning.png";
import speechBubble from "./assets/speech-bubble.png";
import playButton from "./assets/play-button.png";

//! Provider
import { useMyData } from "./nodes/provider/dataProvider.jsx";
import { useIdProvider } from "./nodes/provider/idProvider.jsx";
import Popover from '@mui/material/Popover';
import Backdrop from '@mui/material/Backdrop';
import Snackbar from '@mui/material/Snackbar';

//! MESSAE
import SentTextNode from "./nodes/sentTextNode.js";
import SentMediaNode from "./nodes/sentMediaNode.js";
//! question
import AskButtonOptionNode from "./nodes/askQuestionNode/askButtonOptionNode.js";
import AskListOptionNode from "./nodes/askQuestionNode/askListOptionNode.js";
import SentQuestionNode from "./nodes/askQuestionNode/questionNode.js";
import SentCatalogNode from "./nodes/askQuestionNode/sendCatalog.js";
import AssignStaffNode from "./nodes/askQuestionNode/assignStaffNode.js";
import AskLocationNode from "./nodes/askQuestionNode/askLocationNode.js";

import Alert from "@mui/material/Alert";
import StatusAlert, { StatusAlertService } from "react-status-alert";
import "react-status-alert/dist/status-alert.css";

import PopUpHeader from './nodes/components/popUpAlertHeader.js';
import MessageOutlinedIcon from '@mui/icons-material/MessageOutlined';
import CloseIcon from "@mui/icons-material/Close";


const colors = [
  '#FD8893', 
  '#6495ED', 
  '#FB9FCD', 
  '#8FBEEF', 
  '#62B5B1', 
  '#FEAB56',  
  '#8FBEEF', 
  '#FD8893', 
  '#6495ED', 
  '#FB9FCD', 
  '#8FBEEF', 
  '#62B5B1', 
  '#FEAB56',  
  '#8FBEEF', 
  '#FD8893', 
  '#6495ED', 
  '#FB9FCD', 
  '#8FBEEF', 
  '#62B5B1', 
  '#FEAB56',  
  '#8FBEEF', 
];

const NestedItems = ({
  items,
  onBackClick,
  addQuestionNodeFromOutside,
  addNodeFromSlider,
}) => {
  const [selectedItem, setSelectedItem] = useState(null);
  const [showCustomWidget, setShowCustomWidget] = useState(null);
  const [selectedType, setSelectedType] = useState("");

  // const [ketitems, setItems] = useState([]);
  const [newItem, setNewItem] = useState("");
  const handleTitleChange = (event) => {
    setCanvasTitle(event.target.value);
  };

  //! POP-UP
  const handleClosepopUp = () => {
    setShowCustomWidget(null);
  };
  const handleClosepopUp2 = () => {
    setShowCustomWidget(null);
  };
  //! ============================

  const {
    keyWordList,
    setKeywordsList,
    lastUniqueId,
    setLastUniqueId,
    canvasTitle,
    setCanvasTitle,
    uniqueToken,
    setUniqueToken,
    flowId,
    setFlowId,
    //! url
    paramUrl,
    setParamUrl,
    // ! Nodes
    nodes,
    setNodes,
    onNodesChange,
    // ! edges
    edges,
    setEdges,
    onEdgesChange,
    //! initialNodeId
    initialNodeId
  } = useIdProvider();

  console.log('paramUrl :' ,paramUrl);
  console.log('uniqueToken :' ,uniqueToken);


  // !
 async function createNestedStructure(nodes1, edges1) {
    // todo : check any duplicated edges  (TO AVOID SAME NODE 2 EDGE)
    // Function to recursively build nextNodeData for a given node ID
    console.log('<><><><><>><>><><><<><><><><><><>><><><>><><><><>><><><><><><><><><>><><><><<><><>');
    console.log('nodes : ',nodes);
    if (nodes.length == 0) {
      StatusAlertService.showWarning("Not Added Any Nodes!");
      return;
    }
    if (keyWordList.length === 0) {
      StatusAlertService.showWarning("Add Keywords To Save");
      return;
    }
    if (!initialNodeId) {
      StatusAlertService.showWarning("Give Star To Initial Node");
      return;
    }

    // keyWordList.forEach((keyword) => {
    //   console.log(" keyword :", keyword);
    //   const found = keyWordList.some((keyWord) => keyword.includes(keyWord));
    //   if (found) {
    //     StatusAlertService.showWarning("Keyword Alredy Exist");
    //   }
    //   return;
    // });
    const processedNodeIds = new Set();

       function buildNextNodeData(nodeId) {
      const node = nodes.find((n) => n.id === nodeId);
      if (!node) return null;

console.log("----------------------------- Function called -----------------------------");
console.log('nodeId :' ,nodeId);

      const nextNodeData = {
        // ...node
        type: node.type,
        // id: node.id,
        ...node.data,
        // text: node.data.text,
        // media_url: node.data.media_url
      };

      if (node.type === "button") {
        nextNodeData.buttons = [];
      
        node.data.buttons.forEach((button) => {
          const buttonData = {
            id: button.id,
            text: button.text,
          };
      
          const targetEdge = edges.find((edge) => edge.sourceHandle === button.id);
      
          console.log("targetEdge :", targetEdge);
      
          if (targetEdge) {
            const targetNodeId = targetEdge.target;
      
            const nextNode = buildNextNodeData(targetNodeId);
            
            if (processedNodeIds.has(targetNodeId)) {
              // Mark as repeated node and show the actual ID of the repeated node
              buttonData.nextNodeData = [{
                type: "repeatedNode",
                id: targetNodeId,  // Show the ID of the repeated node
              }];
            } else {
              // Add node ID to the set to mark it as processed
              processedNodeIds.add(targetNodeId);
      
              if (nextNode) {
                buttonData.nextNodeData = [nextNode];
              }
            }
          }
      
          nextNodeData.buttons.push(buttonData);
        });
      } else if (node.type === "listOption") {
        nextNodeData.sections = [];
      
        node.data.sections.forEach((section) => {
          const sectionData = {
            id: section.id,
            sectionName: section.sectionName,
          };
          sectionData.options = [];
      
          section.options.forEach((option) => {
            const optionsData = {
              id: option.newSectionId,
              title: option.title,
              description: option.description,
            };
      
            const targetEdge = edges.find(
              (edge) => edge.sourceHandle === option.newSectionId
            );
            console.log("targetEdge :", targetEdge);
      
            if (targetEdge) {
              const targetNodeId = targetEdge.target;
      
              // Check if the node is repeated
              if (processedNodeIds.has(targetNodeId)) {
                // Mark as repeated node
                optionsData.nextNodeData = [{
                  type: "repeatedNode",
                  id: targetNodeId, // Use the actual target node ID
                }];
              } else {
                // Add node ID to the set to mark it as processed
                processedNodeIds.add(targetNodeId);
      
                // Build next node data
                const nextNode = buildNextNodeData(targetNodeId);
                if (nextNode) {
                  optionsData.nextNodeData = [nextNode];
                }
              }
            }
      
            sectionData.options.push(optionsData);
          });
          
          nextNodeData.sections.push(sectionData);
        });
      
      
      }else {
        const outgoingEdges = edges.filter((e) => e.source === nodeId);
        if (outgoingEdges.length === 0) return nextNodeData;
      
        nextNodeData.nextNodeData = [];
      
        outgoingEdges.forEach((edge) => {
          const targetNodeId = edge.target;
          console.log('targetNodeId :', targetNodeId);
      
          if (targetNodeId) {
            const repeatedNodeData = {
              type: '',
              id: '',
            };
      
            if (processedNodeIds.has(targetNodeId)) {
              // Mark as repeated node and show the actual ID
              repeatedNodeData.type = "repeatedNode";
              repeatedNodeData.id = targetNodeId;
      
              // Push repeated node data into nextNodeData
              nextNodeData.nextNodeData.push(repeatedNodeData);
            } else {
              // Add node ID to the set to mark it as processed
              processedNodeIds.add(targetNodeId);
      
              // Build next node data
              const nextNode = buildNextNodeData(targetNodeId);
              if (nextNode) {
                nextNode.targetNodeId = targetNodeId;
                nextNodeData.nextNodeData.push(nextNode);
              }
            }
          }
        });
      }
      

      // }
      return nextNodeData;
    }
    console.log("uniqueToken :", uniqueToken);
    console.log("flowId :", flowId);
    console.log("keywords :", keyWordList);
    console.log("nodes :", nodes);
    console.log("keyWordList :", keyWordList);

    // Find the starting node ID (assuming it's the first node in the list)
    // const startingNodeId1 =   ; 

// Step 1: Create a set to keep track of nodes with incoming edges
const nodesWithIncomingEdges = new Set();

// Step 2: Populate the set with target nodes from the edges
for (const edge of edges) {
    nodesWithIncomingEdges.add(edge.target);
}

// Step 3: Find the initial node (a node with no incoming edges)
// let initialNodeId = null;
// for (const node of nodes) {
//     if (!nodesWithIncomingEdges.has(node.id)) {
//         initialNodeId = node.id;
//         break;  // We found the initial node, so we can exit the loop
//     }
// }


// console.log("initialNodeId :", initialNodeId);

//     const startingNodeId =initialNodeId;  // todo : instred of taking 0 th find the initial node using the edjes which the node which have no input edjes

    // Build the nested structure starting from the initial node
    const nestedStructure =  buildNextNodeData(initialNodeId);
    console.log("nestedStructure :", nestedStructure);

    const transformedArray = keyWordList.map((keyword) => {
      return { keyword: keyword };
    });
    console.log("transformedArray :", transformedArray);
    console.log("canvasTitle :", canvasTitle);
    //! API CALLING
    // ! post
    const data = {
      token: uniqueToken,
      title: canvasTitle,
      flow_id: flowId,
      keywords: transformedArray,
      data: nestedStructure,
      nodes: nodes,
      edges: edges,
    }

    console.log("data :", data);

    // axios.post(`https://myaccount.login2.in/index.php/Webhook/flow_chart`, { // todo : confrim url change
    axios.post(`https://${paramUrl}/index.php/Webhook/flow_chart`, data).then((res) => {
        console.log("-------------------POSTED DATA-----------------");
        console.log(res);
        console.log(res.data.message);
        if (res.data.message == "Success") {
          // Simple alert
          StatusAlertService.showSuccess("Successfully Saved Project");
        } else if (res.data.message == "keyword already exists!") {
          StatusAlertService.showError("keyword already exists!");
        }
      })
      .catch((error) => {
        if (error.response) {
          console.log("Response data:", error.response.data);
          console.log("Status code:", error.response.status);
        } else if (error.request) {
          console.log("No response received:", error.request);
        } else {
          console.log("Error:", error.message);
        }
      });
  }

  const handleInputChange = (e) => {
    setNewItem(e.target.value);
  };

  const handleAddItem = () => {
    if (newItem.trim() !== "") {
      // setKeywordsList([...keyWordList, newItem.trim()]);
      setKeywordsList([...keyWordList, newItem.trim()]);
      setNewItem("");
    }
  };

  const removeKeyword = (index) => {
    const updatedKeywords = [...keyWordList];
    updatedKeywords.splice(index, 1); // Remove the item at the specified index
    setKeywordsList(updatedKeywords); // Update the state with the new list
  };
  //! provider
  const {
    boolValue,
    setBoolValue,
    Editdata,
    setData,
    editNodeType,
    setEditNodeType,
    itemId,
    setItemId,
    setPositionX,
    setPositionY,
  } = useMyData();

  let data = {};

  const saveCanvasEdit = () => {
    console.log("===================== SAVE  ========================");
    console.log(boolValue);
    console.log("data : ", data);
    console.log("itemId : ", itemId);
    console.log("editNodeType : ", editNodeType);
    // TODO : UPDATE NODE
    setSelectedType(editNodeType);
    console.log("selectedType : ", selectedType);

    handleAddNode({ id: itemId, type: editNodeType });
    // // todo : parse id
    
    // console.log("=============================================");
  };

  useEffect(() => {
    // setItems(keyWordList);
    console.log("selectedType (updated):", selectedType);
  }, [selectedType]);

  const cancelCanvasEdit = () => {
    setBoolValue(false);
    setData(null);
    setEditNodeType("");
    setItemId("");
    setPositionY("");
    setPositionX("");
  };
  //! UI STATE

  const handleSentTextChange = ({ text }) => {
    data = { text };
  };
  const handleAskLocationChange = ({ location }) => {
    data = { location };
  };
  const handleCatalogChange1 = ({ catalog_id, catalog_title , footerText,  headerText, buttonText, messageText  }) => {
    data = { catalog_id, catalog_title ,footerText,  headerText, buttonText, messageText };
    console.log("data 1 : ", data);
    console.log("id 1 : ", catalog_id);
    console.log("id 12 : ", catalog_title);
  };
  const handleStaffAssign = ({ staff_id, staff_list , sentMessageToStaff , staffMessage , lead_category,lead_category_id}) => {
    data = { staff_id, staff_list , sentMessageToStaff , staffMessage ,lead_category,lead_category_id };
    console.log("data 1 : ", data);
    console.log("staff_list : ", staff_list);
    console.log("staff_list: ", staff_list);
  };
  const handleAskQuestionChange = ({ question }) => {
    data = { question };
  };
  let selectedMediaFile=null;
  const handleSentMediaChange = ({ text, mediaType, media_url ,mediaSource , file}) => {
    data = { text, mediaType, media_url ,mediaSource};
    selectedMediaFile=file;
  };
  const handleSentListOptionChange = ({
    questionText,
    footerText,
    buttonTitle,
    selectedOption,
    sections,
  }) => {
    data = {
      questionText,
      footerText,
      buttonTitle,
      selectedOption,
      sections,
    };
  };

  const handleAskButtonQuestion = ({
    headerType,
    headerData,
    bodyText,
    footerText,
    optionType,
    buttons,
    mediaSource,
    file
  }) => {
    const selectedMmediaSource = mediaSource;
    data = {
      headerType,
      headerData,
      bodyText,
      footerText,
      optionType,
      buttons,
      selectedMmediaSource,
    };
    selectedMediaFile=file;
  };

  const handleClick = (item) => {
    setSelectedItem(item);
  };

  const handleBackClick = () => {
    setSelectedItem(null);
    onBackClick();
  };

  const handleCloseWidget = () => {
    setShowCustomWidget(false);
  };

  const onDragStart = (event, nodeType) => {
    console.log("===================== test 2  ========================");
    console.log(nodeType);
    console.log(event);

    console.log("===================== test 2  ========================");
    event.dataTransfer.setData("application/reactflow", nodeType);
    event.dataTransfer.effectAllowed = "move";
  };

  const handleDivClick = (typeS) => {
    switch (typeS) {
      case "text":
        setShowCustomWidget(true);
        setSelectedType("text");
        break;
      case "sentMeadiaMessage":
        setShowCustomWidget(true);
        setSelectedType("sentMeadiaMessage");
        break;
      case "button":
        setShowCustomWidget(true);
        setSelectedType("button");
        break;
      case "listOption":
        setShowCustomWidget(true);
        setSelectedType("listOption");
        break;
      case "question":
        setShowCustomWidget(true);
        setSelectedType("question");
        break;
      case "catalog":
        setShowCustomWidget(true);
        setSelectedType("catalog");
        break;
      case "staff":
        setShowCustomWidget(true);
        setSelectedType("staff");
        break;
      case "location":
        setShowCustomWidget(true);
        setSelectedType("location");
        break;
      default:
        setShowCustomWidget(false);
        setSelectedType("");
        break;
    }
  };

  const handleAddNode = async ({ id, type } = {}) => {
    // todo : check condition which  node
    console.log("===== add/ edit ========");
    console.log("selectedType : ", selectedType);
    console.log("type : ", type);
    console.log("id : ", id);
    var t = boolValue === true ? type : selectedType;
    switch (t) {
      case "text":
        const { text } = data;
        if (text === null || text === undefined || text === "") {
          StatusAlertService.showWarning("Enter Message to Add!");

          //!
          setOpenSnackBar(true);
          //!
        } else {
          addNodeFromSlider(text, "text", id);
          // addNodeFromOutside(sentText ,id);
          setShowCustomWidget(false);
          setSelectedType("");
          cancelCanvasEdit();
        }
        break;
      case "sentMeadiaMessage":

        const { mediaType, media_url ,mediaSource } = data;
 
        let isValid = false;
        if (mediaSource==='link') {

         
          switch (mediaType) {
            case 'image': 
              const ImageExtension = media_url.split('.').pop().toLowerCase(); 
              console.log('extension :' ,ImageExtension);
              const supportedImageExtensions = ['jpeg', 'png','jpg'];
              if (supportedImageExtensions.includes(ImageExtension)) {
                isValid = true;
              } else {
                console.log("Unsupported image extension: ", ImageExtension);
                StatusAlertService.showWarning("Unsupported image format!");
              }
            break;
         
            case 'video': 
              const VideoExtension = media_url.split('.').pop().toLowerCase(); 
              console.log('extension :' ,VideoExtension);
              const supportedVideoExtensions = ['3gp', 'mp4',];
              if (supportedVideoExtensions.includes(VideoExtension)) {
                isValid = true;
              } else {
                console.log("Unsupported video extension: ", VideoExtension);
                StatusAlertService.showWarning("Unsupported video format!");
              }
            break;
        
            case 'audio': 
              const AudioExtension = media_url.split('.').pop().toLowerCase(); 
              console.log('extension :' ,AudioExtension);
              const supportedAudioExtensions = ['mp3', 'arm', 'acc' , 'm4a' ,'ogg' ];
              if (supportedAudioExtensions.includes(AudioExtension)) {
                 isValid = true;
              } else {
                console.log("Unsupported audio extension: ", AudioExtension);
                StatusAlertService.showWarning("Unsupported audio format!");
              }
            break;
        
            case 'document': 
              const DocumentExtension = media_url.split('.').pop().toLowerCase(); 
              console.log('extension :' ,DocumentExtension);
              const supportedDocumentExtensions = ['txt', 'xls', 'xlsx' , 'doc' ,'docx' ,'ppt' , 'pptx' , 'pdf' ];
              if (supportedDocumentExtensions.includes(DocumentExtension)) {
               isValid = true;
              } else {
               console.log("Unsupported document extension: ", DocumentExtension);
                StatusAlertService.showWarning("Unsupported document format!");
              }
            break;
        
            default: 
              isValid = false;
              break;
          }
          if (isValid) {
            
         
          const formData = new FormData();
          formData.append('url', media_url);
          formData.append('token', uniqueToken); 
          formData.append('type',mediaType);

          try {
            const response = await axios.post( //
              // 'https://myaccount.login2.in/index.php/Webhook/uploadUrl',
              `https://${paramUrl}/index.php/Webhook/uploadUrl`,
              formData,
              {
                headers: {
                  'Content-Type': 'multipart/form-data',
                },
              }
            );
            console.log('File uploaded successfully:', response.data);
            console.log('File uploaded successfully:', response.data['data']); 
            const media_id =  response.data['data'];

            data = {...data , media_id  };
            isValid=true;
             
          } catch (error) {
            console.error('Error uploading file:', error); 
            StatusAlertService.showWarning("Error uploading file!");
            return ;
          }
       }
        }else{
          const formData = new FormData();
          formData.append('file', selectedMediaFile);
          formData.append('token', uniqueToken);  
      
          try {
            const response = await axios.post(
              `https://${paramUrl}/index.php/Webhook/uploadFile`,
              formData,
              {
                headers: {
                  'Content-Type': 'multipart/form-data',
                },
              }
            );
            console.log('File uploaded successfully:', response.data);
            console.log('File uploaded successfully:', response.data['data']);
            console.log('File uploaded successfully:', response.data['data']['media_id']);
            console.log('File uploaded successfully:', response.data['data']['url']); 
            console.log('File uploaded sytatus:', response.data['status']); 
            if (response.data['status']===true) {
              const media_id = response.data['data']['media_id'];
              const media_url = response.data['data']['url'];
              data = {...data , media_id , media_url};
              isValid=true;
            }else{
              isValid=false;
              StatusAlertService.showWarning(response.data['message']);
            }
            
          } catch (error) {
            console.error('Error uploading file:', error);
            StatusAlertService.showWarning("Error uploading file!"); 
          }
        }



        // if (!media_url) {
        //   console.log("media_url : ",  media_url);
        //   console.log("data : ",  data);
        //   // StatusAlertService.showWarning("Media Not Added!");
        // } else{
          
        


          if (isValid) {
          addNodeFromSlider(data, "sentMeadiaMessage", id);
          setShowCustomWidget(false);
          setSelectedType("");
          cancelCanvasEdit();
        // }
        }
        break;
      case "button":
        const {
          headerType,
          headerData,
          bodyText,
          footerText,
          optionType,
          buttons,
          selectedMmediaSource
        } = data;

        console.log('buttons.length : ', buttons.length);


        if (   headerType === null ||   headerType === "") {
          console.log('type : ', headerType);
           StatusAlertService.showWarning("Select Header Type!");
        } else if (  headerType !== "None" &&  headerType == "Text" &&   (!headerData || headerData === "") ) {
          StatusAlertService.showWarning("Add Header!");
        } else if (  bodyText === null ||  bodyText === undefined ||  bodyText === ""  ) {
          StatusAlertService.showWarning("Add Body Message!");
        } else if (buttons.length === 0) {
          StatusAlertService.showWarning("Add Minimum One Button!");
        } else {
          let isValid = false;
          if(buttons.length>0){
            const emptyButtons = buttons.filter(button => !button.text.trim());
            if (emptyButtons.length > 0) {
              StatusAlertService.showWarning("All buttons must have a non-empty text value.");
              isValid=false;
              return;
            } 
          }

          if (headerType!='None' ||headerType!='Text' ) {

         if (selectedMmediaSource==='link') {
          switch (headerType) {
          
            case 'Image': 
              const ImageExtension = headerData.split('.').pop().toLowerCase(); 
              console.log('extension :' ,ImageExtension);
              const supportedImageExtensions = ['jpeg', 'png','jpg'];
              if (supportedImageExtensions.includes(ImageExtension)) {
                isValid = true;
              } else {
                console.log("Unsupported image extension: ", ImageExtension);
                StatusAlertService.showWarning("Unsupported image format!");
              }
            break;
         
            case 'Video': 
              const VideoExtension = headerData.split('.').pop().toLowerCase(); 
              console.log('extension :' ,VideoExtension);
              const supportedVideoExtensions = ['3gp', 'mp4',];
              if (supportedVideoExtensions.includes(VideoExtension)) {
                isValid = true;
              } else {
                console.log("Unsupported video extension: ", VideoExtension);
                StatusAlertService.showWarning("Unsupported video format!");
              }
            break;
        
            case 'Audio': 
              const AudioExtension = headerData.split('.').pop().toLowerCase(); 
              console.log('extension :' ,AudioExtension);
              const supportedAudioExtensions = ['mp3', 'arm', 'acc' , 'm4a' ,'ogg' ];
              if (supportedAudioExtensions.includes(AudioExtension)) {
                 isValid = true;
              } else {
                console.log("Unsupported audio extension: ", AudioExtension);
                StatusAlertService.showWarning("Unsupported audio format!");
              }
            break;
        
            case 'Document': 
              const DocumentExtension = headerData.split('.').pop().toLowerCase(); 
              console.log('extension :' ,DocumentExtension);
              const supportedDocumentExtensions = ['txt', 'xls', 'xlsx' , 'doc' ,'docx' ,'ppt' , 'pptx' , 'pdf' ];
              if (supportedDocumentExtensions.includes(DocumentExtension)) {
               isValid = true;
              } else {
               console.log("Unsupported document extension: ", DocumentExtension);
                StatusAlertService.showWarning("Unsupported document format!");
              }
            break;
        
            default: 
              isValid = true;
              break;
          }

          // todo : api call

            if (isValid) {
              const formData = new FormData();
              formData.append('url', headerData);
              formData.append('token', uniqueToken); 
              formData.append('type',optionType);

              try {
                const response = await axios.post(
                  `https://${paramUrl}/index.php/Webhook/uploadUrl`,
                  formData,
                  {
                    headers: {
                      'Content-Type': 'multipart/form-data',
                    },
                  }
                );
                console.log('File uploaded successfully:', response.data);
                console.log('File uploaded successfully:', response.data['data']); 
                const media_id =  response.data['data'];
    
                data = {...data , media_id  };
                isValid=true;
                 
              } catch (error) {
                isValid=false;
                console.error('Error uploading file:', error); 
                StatusAlertService.showWarning("Error uploading file!");
                return ;
              }
          }
         }else{
          console.log('paramUrl :' ,paramUrl);
          const formData = new FormData();
          formData.append('file', selectedMediaFile);
          formData.append('token', uniqueToken); 

          try {
            const response = await axios.post(
              `https://${paramUrl}/index.php/Webhook/uploadFile`,
              formData,
              {
                headers: {
                  'Content-Type': 'multipart/form-data',
                },
              }
            );
            console.log('File uploaded successfully:', response.data);
            console.log('File uploaded successfully:', response.data['data']);
            console.log('File uploaded successfully:', response.data['data']['media_id']);
            console.log('File uploaded successfully:', response.data['data']['url']); 
            console.log('File uploaded sytatus:', response.data['status']); 
            if (response.data['status']===true) {
              const media_id = response.data['data']['media_id'];
              const headerData = response.data['data']['url'];
              data = {...data , media_id , headerData};
              isValid=true;
            }else{
              isValid=false;
              StatusAlertService.showWarning(response.data['message']);
            }
            
          } catch (error) {
            isValid=false;
            console.error('Error uploading file:', error);
            StatusAlertService.showWarning("Error uploading file!"); 
          }


         }
   
          }else{
            isValid=true;
          }
         if (isValid) {
          addQuestionNodeFromOutside(data, id);
          setShowCustomWidget(false);
          cancelCanvasEdit();
          selectedMediaFile=null;
         }
        }
        break;
      case "listOption":
        const {
          questionText,
          footerText2,
          buttonTitle,
          selectedOption,
          sections,
        } = data;
        console.log('sections : ',sections);
        if (
          questionText === null ||
          questionText === undefined ||
          questionText === ""
        ) {
          StatusAlertService.showWarning("Enter Question Message!");
        } else if (
          buttonTitle === null ||
          buttonTitle === undefined ||
          buttonTitle === ""
        ) {
          StatusAlertService.showWarning("Enter Button Name!");
        } else if (sections.length == 0) {
            StatusAlertService.showWarning("Add Minimum One Section!");
        } else {
 
          console.log('sections : => ',sections);
          const allSectionsHaveNames = sections.every(section => section.sectionName && section.sectionName.trim() !== "");
          if (!allSectionsHaveNames) {
            StatusAlertService.showWarning("Enter Section Name!");
            return;
          }

          const allOptionsHaveTitles = sections.every(section => 
            section.options.every(option => option.title && option.title.trim() !== "")
          );
          
          if (!allOptionsHaveTitles) {
            StatusAlertService.showWarning("Enter Option Titles!");
            return;
          }
          

          addNodeFromSlider(data, "listOption", id);
          setShowCustomWidget(false);
          cancelCanvasEdit();
        }
        break;
      case "question":
        const { question } = data;
        console.log("question : ", data);
        if (question === null || question === undefined || question === "") {
          StatusAlertService.showWarning("Enter Question to Add!");
        } else {
          addNodeFromSlider(data, "question", id);
          setShowCustomWidget(false);
          setSelectedType("");
          cancelCanvasEdit();
        }
        break;

      case "catalog":
        const { catalog_id } = data;
        console.log("catalog : ", data);
        if (
          catalog_id === null ||
          catalog_id === undefined ||
          catalog_id === ""
        ) {
          StatusAlertService.showWarning("Select Catalog to Add!");
        } else {
          addNodeFromSlider(data, "catalog", id);
          setShowCustomWidget(false);
          setSelectedType("");
          cancelCanvasEdit();
        }

        break;
      case "staff":
        console.log("catalog : ", data);
        const { staff_id, staff_list , sentMessageToStaff , staffMessage ,lead_category,lead_category_id  } = data;
        if (!staff_id || staff_id.length === 0) {
          StatusAlertService.showWarning("Staff list is empty!");
        } else if(lead_category === null || lead_category === undefined || lead_category === ""){
          StatusAlertService.showWarning("Select Category!");
        } else if (sentMessageToStaff===true && (staffMessage === null || staffMessage === undefined || staffMessage === "")) {
          StatusAlertService.showWarning("Enter Message!");
        }  else {
          addNodeFromSlider(data, "staff", id);
          setShowCustomWidget(false);
          setSelectedType("");
          cancelCanvasEdit();
        }

        break;
      case "location":
        let { location } = data;
        console.log("location : ", data);
        if (location === null || location === undefined || location === "") {
          StatusAlertService.showWarning("Enter Message to Add!");
        } else {
          addNodeFromSlider(data, "location", id);
          setShowCustomWidget(false);
          setSelectedType("");
          cancelCanvasEdit();
        }

        break;
      default:
        break;
    }
  };

  const [openSnackBar, setOpenSnackBar] =  useState(false);

  const handleCloseSnackBar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackBar(false);
  };


  return (
    <div> 
      <div>
      <Backdrop
              sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={boolValue}>
          < Popover 
              open={!!boolValue}
              anchorEl={null}
              onClose={cancelCanvasEdit}
              anchorOrigin={{
               vertical: 'center',
               horizontal: 'center',   }}
             transformOrigin={{
               vertical: 'center',
               horizontal: 'center',  }} 
               >
          <div style={{  maxHeight: '80vh', overflowY: 'auto' }} >
                   {/* //!  ======  */}
                   <div className="canvas_header_main_div_popUp" style={{ marginLeft: "10px", marginRight:'10px', marginTop:'10px' }}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <div
            style={{
              width: "35px",
              height: "35px",
              borderRadius: "50%",
              backgroundColor: "#ECFDF3",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position: "relative",
            }}
          >
            <div
              style={{
                width: "25px",
                height: "25px",
                borderRadius: "50%",
                backgroundColor: "#D1FADF",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "absolute",
              }}
            >
              <div
                style={{
                  height: "10px",
                  width: "10px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <MessageOutlinedIcon style={{ fontSize: "inherit" }} color="success" />
              </div>
            </div>
          </div>
          <p
            style={{
              color: "#344054",
              margin: "0",
              paddingLeft: "10px",
              fontWeight: "bold",
            }}
          >
            {editNodeType == "text" ?'Text Message' :''}
            {editNodeType == "sentMeadiaMessage" ?'Send Media' :''}
            {editNodeType == "button" ?'Button Message' :''}
            {editNodeType == "listOption" ?'List Message' :''}
            {editNodeType == "question" ?'Ask Question' :''}
            {editNodeType == "catalog" ?'Send Catalog' :''}
            {editNodeType == "staff" ?'Assign Staff' :''}
            {editNodeType == "location" ?'Ask Location' :''}
         
          </p>
        </div>
        <div style={{ marginRight: "10px" }}>
          <CloseIcon  onClick={() => cancelCanvasEdit()} style={{cursor:'pointer'}} />
        </div>
      </div>
    </div>
          {/* <PopUpHeader title= 'Button Message'  color= '#039855' Icon={MessageOutlinedIcon} /> */}
            {editNodeType == "text" ? (  <SentTextNode   onValueChange={handleSentTextChange}  editData={Editdata}  />  ) : (  <div></div>   )}
            {editNodeType == "sentMeadiaMessage" ? (   <SentMediaNode  onValueChange={handleSentMediaChange} editData={Editdata}  />  ) : (  <div></div>   )}
            {editNodeType == "button" ? (  <AskButtonOptionNode   onValueChange={handleAskButtonQuestion}  editData={Editdata}  /> ) : (  <div></div>  )}
            {editNodeType == "listOption" ? (  <AskListOptionNode   onValueChange={handleSentListOptionChange}   editData={Editdata}  />  ) : (  <div></div>  )}
            {editNodeType == "question" ? (   <SentQuestionNode   onValueChange={handleAskQuestionChange}  editData={Editdata}  /> ) : (  <div></div>   )}
            {editNodeType == "catalog" ? (   <SentCatalogNode   onChange={handleCatalogChange1}   editData={Editdata}  />  ) : (  <div></div> )}
            {editNodeType == "staff" ? (   <AssignStaffNode  onValueChange={handleStaffAssign}  editData={Editdata}  />  ) : (   <div></div>   )}
            {editNodeType == "location" ? (   <AskLocationNode  onValueChange={handleAskLocationChange}   editData={Editdata}  />  ) : (   <div></div>  )}
          <div style={{ margin : "0", marginRight:'20px', marginLeft:'20px' , display:'flex' , flexDirection:'row' , marginTop:'20px' , marginBottom: "20px",}}>
           
            <button
              onClick={() => cancelCanvasEdit()}
              style={{
                backgroundColor: "#fff",
                color: "#344054",
                width: "100%",
                fontWeight:'bold',
                padding: "10px 20px", 
                borderRadius: "10px",
                border: "1px solid #D0D5DD",
                cursor: "pointer", 
                marginRight:'10px',
                fontSize:'16px'
              }}  >   Cancel   </button>
               <button
              onClick={() => saveCanvasEdit()}
              style={{
                backgroundColor: "#7F56D9",
                border: "1px solid #7F56D9",
                color: "white",
                width: "100%",
                padding: "10px 20px", 
                borderRadius: "10px",
                border: "none",
                cursor: "pointer", 
                fontSize:'16px'
              }} >  Save  </button>
              </div>
          </div>
          </Popover>
          </Backdrop>
          <div>
          <Backdrop
              sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={showCustomWidget}>
              < Popover 
              open={!!showCustomWidget}
              anchorEl={null}
              onClose={handleClosepopUp}
              anchorOrigin={{
               vertical: 'center',
               horizontal: 'center',
             }}
             transformOrigin={{
               vertical: 'center',
               horizontal: 'center',
             }}  
             
             
             >
              <div   style={{  maxHeight: '80vh', overflowY: 'auto' }}  >
                {/* //!  ======  */}
              <div className="canvas_header_main_div_popUp" style={{ marginLeft: "10px", marginRight:'10px', marginTop:'10px' }}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <div
            style={{
              width: "35px",
              height: "35px",
              borderRadius: "50%",
              backgroundColor: "#ECFDF3",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position: "relative",
            }}
          >
            <div
              style={{
                width: "25px",
                height: "25px",
                borderRadius: "50%",
                backgroundColor: "#D1FADF",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "absolute",
              }}
            >
              <div
                style={{
                  height: "10px",
                  width: "10px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <MessageOutlinedIcon style={{ fontSize: "inherit" }} color="success" />
              </div>
            </div>
          </div>
          <p
            style={{
              color: "#344054",
              margin: "0",
              paddingLeft: "10px",
              fontWeight: "bold",
            }}
          >
            {selectedType == "text" ?'Text Message' :''}
            {selectedType == "sentMeadiaMessage" ?'Send Media' :''}
            {selectedType == "button" ?'Button Message' :''}
            {selectedType == "listOption" ?'List Message' :''}
            {selectedType == "question" ?'Ask Question' :''}
            {selectedType == "catalog" ?'Send Catalog' :''}
            {selectedType == "staff" ?'Assign Staff' :''}
            {selectedType == "location" ?'Ask Location' :''}
         
          </p>
        </div>
        <div style={{ marginRight: "10px" }}>
          <CloseIcon  onClick={ handleCloseWidget} style={{cursor:'pointer'}} />
        </div>
      </div>
    </div>
    {/* //!  ======  */}
                {selectedType == "text" ? (   <SentTextNode onValueChange={handleSentTextChange} />  ) : (   <div></div>  )}
                {selectedType == "sentMeadiaMessage" ? (  <SentMediaNode onValueChange={handleSentMediaChange} />  ) : (   <div></div>   )}
                {selectedType == "button" ? (   <AskButtonOptionNode  onValueChange={handleAskButtonQuestion}  />  ) : (   <div></div> )}
                {selectedType == "listOption" ? (  <AskListOptionNode  onValueChange={handleSentListOptionChange}  />  ) : (   <div></div>  )}
                {selectedType == "question" ? (   <SentQuestionNode onValueChange={handleAskQuestionChange} />   ) : (   <div></div>  )}
                {selectedType == "catalog" ? (  <SentCatalogNode onChange={handleCatalogChange1} />  ) : (   <div></div>  )}
                {selectedType == "staff" ? (  <AssignStaffNode onValueChange={handleStaffAssign} />   ) : (  <div></div>)}
                {selectedType == "location" ? (  <AskLocationNode onValueChange={handleAskLocationChange} />  ) : (  <div></div>   )}
              <div style={{ margin : "0", marginRight:'20px', marginLeft:'20px' , display:'flex' , flexDirection:'row' , marginTop:'20px' , marginBottom: "20px",}}>
                
                <button
                  onClick={ handleCloseWidget}
                  style={{
                    backgroundColor: "#fff",
                    color: "#344054",
                    width: "100%",
                    fontWeight:'bold',
                    padding: "10px 20px",
                    borderRadius: "10px",
                    border: "1px solid #D0D5DD",
                    cursor: "pointer",
                    marginRight:'10px',
                    fontSize:'16px'
                  }}>Cancel</button>
                  <button
                  onClick={() => handleAddNode()}
                  style={{
                    backgroundColor: "#7F56D9",
                    border: "1px solid #7F56D9",
                    color: "white",
                    width: "100%",
                    padding: "10px 20px",
                    borderRadius: "10px",
                    border: "none",
                    cursor: "pointer",
                    fontSize:'16px'
                  }}>Save</button>
                  </div>
              </div>
              </Popover> 
              </Backdrop>
              <div style={{marginBottom:'20px'}}>
                <div>
                  <div style={{ display: "flex", marginBottom:'5px' }}>
                    <input
                      type="text"
                      placeholder="Enter Group Name"
                      style={{
                        width:'100%',
                        height:'30px',
                        border: "1px solid #C3C2C2", 
                        borderRadius:'5px',
                        marginRight:'5px'
                      }}
                      value={canvasTitle}
                      onChange={handleTitleChange} />
                    <button
                      onClick={() => createNestedStructure(nodes, edges)}
                      style={{
                        backgroundColor: "#58B6F9",
                        color: "#fff",
                        padding: "8px 16px",
                        border: "none",
                        borderRadius: "4px",
                        cursor: "pointer",
                        boxShadow: "0 2px 4px rgba(0, 123, 255, 0.2)",
                        transition: "background-color 0.3s",
                        marginRight: "10px",
                        marginLeft: "auto",
                        marginBottom: "10px",
                      }}  >  SAVE  </button>
                  </div>
                  <div  style={{ borderRadius: "10px", border: "1px solid #ccc" }}  >
                    <div style={{ height: "50px", overflowY: "auto" }}>
                      {keyWordList.map((item, index) => (
                        <div
                          key={index}
                          style={{ display: "inline-block", margin: "1px" ,  }}  >
                          <div
                            style={{
                              display: "inline-block",
                              backgroundColor: "#e0e0e0",
                              color: "#333",
                              padding: "5px 10px",
                              borderRadius: "20px",
                              margin: "5px",
                            }}>
                            {item}
                            <span
                              style={{
                                cursor: "pointer",
                                marginLeft: "5px",
                                color: "indigo",
                              }}
                              onClick={() => removeKeyword(index)}>x</span>
                          </div>
                        </div>
                      ))}
                    </div>
                    <div
                      style={{
                        marginBottom: "10px",
                        marginTop: "4px",
                        marginLeft: "4px",
                        display: "flex",  }}  >
                      <input
                        type="text"
                        value={newItem}
                        onChange={handleInputChange}
                        placeholder="Add new Keyword..."
                        style={{
                           marginRight: "10px", 
                           border: "1px solid #C3C2C2", 
                           width:'100%',
                           height:'30px',
                           borderRadius:'5px',
                           marginRight:'5px'
                           }}   />
                      <button
                        onClick={handleAddItem}
                        style={{
                           backgroundColor: '#4BB4A6',
                          color: "indigo",
                          padding: "8px 16px",
                          border: "1px",
                          borderRadius: "4px",
                          cursor: "pointer", 
                          marginRight: "10px",
                          marginLeft: "auto",
                          color:'white'
                        }} >  Add   </button>
                    </div>
                  </div>
                </div>
                {items.map((item, index) => (
                  <div
                    key={index}
                    onClick={() => handleDivClick(item.type)}   >
                    <div>
                      <div
                        key={index}
                        style={{
                          background:colors[index],
                          borderRadius : "6px",
                          display: "flex",
                          flexDirection: "row",
                          paddingTop: "10px",
                          paddingBottom: "10px",
                          height: "55px",
                          marginTop:'6px',  }} >
                        {/* <img  src={item.icon}  alt="logo" style={{ padding: "8px" }}  /> */}
                        <div
                          style={{
                            padding: "5px",
                            display: "flex",
                            flexDirection: "column",
                            marginLeft:'20px'
                          }}   >
                          <p style={{ margin: "0", fontSize: "18px" ,color:'white'}}>   {item.label}  </p>
                          <p  style={{
                              margin: "0",
                              paddingTop: "5px",
                              fontSize: "12px",
                              color:'white'
                            }}   >  {item.subTitle}  </p>
                        </div>
                      </div>
                    </div>
                  </div>
                ))} 
              </div> 
          </div>
      </div>
     
    </div>
  );
};

export default ({ addQuestionNodeFromOutside, addNodeFromSlider }) => {
  const items = [
    // {
    //   label: "Send a message",
    //   subTitle: "If you dont require any input from \nuser use this card",
    //   icon: envelope,
    //   children: [
    {
      label: "Sent Text",
      subTitle: "Sent Text message",
      type: "text",
      icon: lettert,
    },
    {
      label: "Sent Media",
      subTitle: "Sent Image, Video, Audio, Document",
      type: "sentMeadiaMessage",
      icon: playButton,
    },
    {
      label: "Button Message",
      subTitle: "Sent Button Message",
      type: "button",
      icon: lettert,
    },
    {
      label: "List Message",
      subTitle: "Sent List Message",
      type: "listOption",
      icon: playButton,
    },
    {
      label: "Ask Question",
      subTitle: "Ask Question Message",
      type: "question",
      icon: question,
    },
    {
      label: "Send Catalog",
      subTitle: "Send Your Product Catalog",
      type: "catalog",
      icon: planning,
    },
    {
      label: "Assign Staff",
      subTitle: "Assign Staff To Customers",
      type: "staff",
      icon: sent,
    },
    {
      label: "Ask Location",
      subTitle: "Ask Location Of Your Customers",
      type: "location",
      icon: question,
    },
    // {
    //   label: "Sent Collection",
    //   subTitle: "Sent a collection message to user",
    //   type: "sent-collection-message",
    //   icon: planning,
    // },
    // {
    //   label: "Sent Products",
    //   subTitle: "Sent a products message to user",
    //   type: "sent-products-message",
    //   icon: sent,
    // },
    // {
    //   label: "Sent Template",
    //   subTitle: "Sent a template message to user",
    //   type: "sendTemplateMessage",
    //   icon: speechBubble,
    // },
  ];
  // },
  // {
  //   label: "Ask questions",
  //   subTitle: "Use this card to ask and collect input from users",
  //   type: "initialTrigger",
  //   icon: question,
  //   children: [
  //     {
  //       label: "Ask Button Option",
  //       subTitle: "Ask user to select from a list of questions",
  //       type: "button",
  //       icon: lettert,
  //     },
  //     {
  //       label: "Ask List Option",
  //       subTitle: "Ask user to select from a list of options",
  //       type: "listOption",
  //       icon: playButton,
  //     },
  //   ],
  // },
  // ];

  return (
    <aside
      style={{
        position: "fixed",
        top: 0,
        right: 0,
        height: "100%",
        overflowY: "auto",
      }}
    >
      <NestedItems
        items={items}
        onBackClick={() => {}}
        addQuestionNodeFromOutside={addQuestionNodeFromOutside}
        addNodeFromSlider={addNodeFromSlider}
      />
    </aside>
  );
};
