import { useCallback, useState, useEffect } from "react";
import { Handle, Position } from "reactflow";
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import plane from "../../assets/paper-plane.png";
import "../node.css";

import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";

import axios from "axios";

import { useIdProvider } from "../provider/idProvider.jsx";
import PopUpHeader from '../components/popUpAlertHeader.js';
import MessageOutlinedIcon from '@mui/icons-material/MessageOutlined';


function AssignStaffNode({ onValueChange, editData }) {  

  const [staffList, setStaffList] = useState([]);

  const [selectedStaffIds, setSelectedStaffIds] = useState([]);
  
  const [selectedStaffList, setSelectedStaffList] = useState([]);


  const [isChecked, setIsChecked] = useState(false);

  const [text, setText] = useState("");

  const [staffCategoryList, setStafCategoryList] = useState([]);

  const [selectedCatalogId, setSelectedCatalogId] = useState("");
  const [selectedCatalogTitle, setSelectedCatalogTitle] = useState("");


  const handleCategoryChange = (event) => {
    const catalog_id = event.target.value;

    const catalog1 = staffCategoryList.find(catalog => catalog.lead_category_id === catalog_id);
    setSelectedCatalogId(catalog_id);
    console.log('Selected Catalog ID:', catalog_id);
    console.log('Selected Catalog :', catalog1.lead_category);
    // onValueChange({catalog_id:catalog_id });
    const category_title= catalog1.lead_category;
    // onChange({ catalog_id , catalog_title });
    setSelectedCatalogTitle(category_title);
    onValueChange({ 
      staff_id : [...selectedStaffIds] ,
      staff_list : [...selectedStaffList, ], 
      sentMessageToStaff:  isChecked,
      staffMessage: text,
      lead_category: category_title,
      lead_category_id: catalog_id,
      });
 };



  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
    onValueChange({ 
      staff_id : [...selectedStaffIds] ,
      staff_list : [...selectedStaffList, ], 
      sentMessageToStaff: event.target.checked,
      staffMessage:  event.target.checked===false ?"":text,
      lead_category: selectedCatalogTitle,
      lead_category_id: selectedCatalogId,
      });
  };

  const handleCheckboxChangefromText = () => {
    const checked = !isChecked;
    setIsChecked(checked);
    onValueChange({ 
      staff_id : [...selectedStaffIds] ,
      staff_list : [...selectedStaffList, ], 
      sentMessageToStaff: checked,
      staffMessage: checked===false ?"": text,
      lead_category: selectedCatalogTitle,
      lead_category_id: selectedCatalogId,
      });
  };

  const onChange = (event) => {
    const newText = event.target.value;
   
    setText(newText);

    onValueChange({ 
      staff_id : [...selectedStaffIds] ,
      staff_list : [...selectedStaffList, ], 
      sentMessageToStaff: isChecked,
      staffMessage:  newText,
      lead_category: selectedCatalogTitle,
      lead_category_id: selectedCatalogId,
      });
    
  };

  const handleClick = (staff) => {
    if (selectedStaffIds.includes(staff.user_id)) {
      setSelectedStaffIds(selectedStaffIds.filter(id => id !== staff.user_id));
      setSelectedStaffList(selectedStaffList.filter(selectedStaff => selectedStaff.user_id !== staff.user_id));
      
      const staff_id = selectedStaffIds.filter(id => id !== staff.user_id);
      const staff_list = selectedStaffList.filter(selectedStaff => selectedStaff.user_id !== staff.user_id);

      onValueChange({ 
        staff_id , 
        staff_list ,
        sentMessageToStaff: isChecked,
        staffMessage: text,
        lead_category: selectedCatalogTitle,
        lead_category_id: selectedCatalogId,
      });
    } else {
      setSelectedStaffIds([...selectedStaffIds, staff.user_id]);
      setSelectedStaffList([...selectedStaffList, { staff_name: staff.staff_name, user_id: staff.user_id , staff_profile :staff.pro_pic_thumb }]);

      const staff_id = [...selectedStaffIds, staff.user_id];
      const staff_list = [...selectedStaffList, { staff_name: staff.staff_name, user_id: staff.user_id ,staff_profile :staff.pro_pic_thumb}];

      onValueChange({ 
        staff_id , 
        staff_list ,
        sentMessageToStaff: isChecked,
        staffMessage: text,
        lead_category: selectedCatalogTitle,
        lead_category_id: selectedCatalogId,
      });
    }
  };

  const { uniqueToken, paramUrl } = useIdProvider();

  useEffect(() => {
    // data has value
    fetchData();
    fetchStaffListData();
    const hasEditData = Boolean(editData);
    if (hasEditData) {
      const { staff_id, staff_list ,sent_message_to_staff , staff_message ,lead_category,lead_category_id} = editData;
      console.log("staff_list :", staff_list);
      console.log("staff_id :", staff_id);
      setSelectedStaffIds(staff_id);
      setSelectedStaffList(staff_list); 
      setIsChecked(sent_message_to_staff);
      setText(staff_message);
      // lead_category,lead_category_id
      setSelectedCatalogId(lead_category_id);
      setSelectedCatalogTitle(lead_category);

    }
  }, [editData]);

  const fetchStaffListData = async () => {
    const urlParams = new URLSearchParams(window.location.search);
    const tokenParam = urlParams.get("token");
    try {
      console.log(" uniqueToken :", uniqueToken);
      const response = await axios.get(
        `https://${paramUrl}/index.php/Webhook/getCategoryData?token=${tokenParam}`
      );
      console.log(" Initial Function responce");
      console.log(" data :", response.data);

      if (response.data && Array.isArray(response.data.catalogList)) {
        const parsedStaffList = response.data.catalogList.map(item => ({
          lead_category_id: item.lead_category_id,
          lead_category: item.lead_category
        }));
        setStafCategoryList(parsedStaffList); 
    }
    } catch (error) {
      console.log(" Initial Function error");
      console.log(" ERROR :", error);
    }
  };

  const fetchData = async () => {
    const urlParams = new URLSearchParams(window.location.search);
    const tokenParam = urlParams.get("token");
    try {
      console.log(" uniqueToken :", uniqueToken);
      const response = await axios.get(
        `https://${paramUrl}/index.php/Webhook/getStaffData?token=${tokenParam}`
        // `https://myaccount.login2.in/index.php/Webhook/getStaffData?token=${tokenParam}`
        // `https://myaccount.login2.in/index.php/Webhook/getStaffData?token=CZkipLWmbYoweY3WVhLMow%3D%3D`
      );
      console.log(" Initial Function responce");
      console.log(" staff data :", response.data);

      if (response.data && Array.isArray(response.data.staffList)) {
        setStaffList(response.data.staffList); 
      }
      // setStaffList(parsedCatalogList);
    } catch (error) {
      console.log(" Initial Function error");
      console.log(" ERROR :", error);
    }
  };

  return (
    <div className="text-updater-node" style={{padding:'0'}}>
      {/* <PopUpHeader title= 'Assign Staff' color= '#039855'  Icon={MessageOutlinedIcon}  /> */}
      <div
        style={{
          marginLeft: "10px",
          marginRight: "10px",
          marginTop: "10px",
          padding: "0px",
        }}  >
        <div   style={{ display: "flex", flexDirection: "row", alignItems: "start" , marginLeft:'10px'}} >
          <p style={{
              margin: "0",
              fontSize: "11px",
              paddingRight: "5px",
              color: "grey",
              marginBottom:'5px', fontWeight:'bold' , color:'#344054'
            }}  >  Select Staffs{" "}   </p>{" "}
          <p  style={{
              margin: "0",
              fontSize: "15px",
              paddingRight: "20px",
              color: "red", fontWeight:'bold' , color:'#344054'
            }}  >  {" "}  *  </p>{" "}
        </div>
        {
          staffList.length === 0 ?
         ( <p style={{color:'red',textAlign:'center'}}>No staff available</p>)  :
          (
            <Stack direction="row" style={{ flexWrap: "wrap" , marginLeft:'10px'}}>
              {staffList.map((staff) => (
                <Chip
                  style={{ marginRight: "5px" ,  color:"black"  , borderRadius:'7px', border :'1px solid #7F56D9',
                    color: selectedStaffIds.includes(staff.user_id) ? "#fff" : "#344054",
                    '&:hover':  {color: selectedStaffIds.includes(staff.user_id) ? "#fff" : "#344054",
              }    }}
                  label={staff.staff_name}
                  key={staff.user_id}
                  value={staff.user_id}
                  onClick={() => handleClick(staff)} 
                  sx={{
                    borderRadius: '0px',
                    // margin: '5px',
                    backgroundColor: selectedStaffIds.includes(staff.user_id) ? "#7F56D9" : "#fff",
                    color: selectedStaffIds.includes(staff.user_id) ? "#fff" : "#344054",
                    '&:hover': {
                      backgroundColor: selectedStaffIds.includes(staff.user_id) ? "#F9F5FF" : "#fff",
                      color: selectedStaffIds.includes(staff.user_id) ? "#fff" : "#344054",
                    }
                  }}
                />
              ))}
            </Stack>
         )
        }

        {/* //!  SELECT CATEGORY -- DROPDOWN */}
        <div
        style={{
          marginLeft: "10px",
          marginRight: "10px",
          marginTop: "10px",
          padding: "0px",
        }}>
        <div  style={{ display: "flex", flexDirection: "row", alignItems: "start" }}>
          <p
            style={{
              margin: "0",
              fontSize: "11px",
              paddingRight: "5px",
              color: "grey",
              marginBottom:'5px',fontWeight:'bold' , color:'#344054'
            }}>Select Category </p>{" "}
          <p style={{
              margin: "0",
              fontSize: "15px",
              paddingRight: "20px",
              color: "red",fontWeight:'bold' , color:'#344054'
            }}> *</p>{" "}
        </div>
        {
          staffCategoryList.length === 0 ?
          ( <p style={{color:'red',textAlign:'center'}}>No data in category</p>)  :
          (<Box sx={{ minWidth: 120 }}>
          <FormControl fullWidth>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              onChange={handleCategoryChange}
              value={selectedCatalogId}>
              {staffCategoryList.map(catalog => (
                        <MenuItem key={catalog.lead_category_id} value={catalog.lead_category_id}>
                            {catalog.lead_category}
                        </MenuItem>
                    ))}
            </Select>
          </FormControl>
        </Box>)
        }
        
      </div>


        <div style={{ display:'flex',marginTop:'5px'  }}>
        <Checkbox checked={isChecked} onChange={handleCheckboxChange} />
          <p style={{ margin:'5', color:'grey', cursor:'pointer'  }}  onClick={handleCheckboxChangefromText}> Sent Message To Staff </p>
        </div>
        {isChecked && 
         <textarea
         id="text"
         name="text"
         value={text}
         placeholder="Message"
         onChange={onChange}
         className="nodrag"
         style={{
           width:"93%",
           minHeight: "60px",
           border: "1px solid #ccc",
           borderRadius: "5px",
           padding: "10px",
           marginLeft:'10px',
           marginRight:'10px'
         }}
       />  }
      </div>
    </div>
  );
}
export default AssignStaffNode;
// todo : show loading on api call