import React from 'react';
import PDF from '../../assets/PDF.png'; 
import AudioMessage from './audioMessage';

const MediaComponent = ({ mediaType, mediaUrl }) => {
  console.log(' mediaType :',mediaType);

  function getFileNameFromUrl(url) { 
    const urlObject = new URL(url); 
    const pathname = urlObject.pathname; 
    const fileName = pathname.split('/').pop();
    
    return fileName;
  }
  const renderMedia = () => {
    switch (mediaType) {
      case 'image' :
      case 'Image' :
        return <img src={mediaUrl} alt="Image description" style={{ width: "100%",maxHeight:'220px', margin: "0" ,  borderRadius: "10px 10px 10px 10px " }} />;
      case 'video':
      case 'Video':
        return (
          <video style={{ width: "100%", margin: "0" }} controls>
            <source src={mediaUrl} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        );
      case 'text':
      case 'Text':
        return (
          <p  style={{
            margin: "0",
            fontSize: "12px",
            paddingRight: "5px",
            maxWidth: '100%',
            overflow: 'hidden',  
            whiteSpace: 'normal',  
            wordWrap: 'break-word', 
            textAlign:'start',
            color:'black'
          }}> {mediaUrl}</p>  
        );
      case 'audio':
      case 'Audio':
        const audioUrl = mediaUrl;
        const fileNameaudio = getFileNameFromUrl(mediaUrl);
        return (
          <div  style={{display:'flex' ,alignItems:'center' }}>
             <AudioMessage audioUrl={audioUrl} />
             <p  style={{
            margin: "0",
            marginLeft:'15px',
            fontSize: "12px",
            paddingRight: "5px",
            color:'black',
            maxWidth: '100%',
            overflow: 'hidden',  
            whiteSpace: 'normal',  
            wordWrap: 'break-word', 
            textAlign:'start'
          }}> {fileNameaudio}</p> 
          </div>

        );
      case 'document':
      case 'Document':
        const fileName = getFileNameFromUrl(mediaUrl);
        return (
          <div>
          <div style={{display:'flex' ,alignItems:'center'  }}>
             <img src={PDF} alt="Image description" style={{  margin: "0" ,  borderRadius: "10px 10px 10px 10px " }} />
             <p  style={{
            margin: "0",
            marginLeft:'15px',
            fontSize: "12px",
            paddingRight: "5px",
            color:'black',
            maxWidth: '100%',
            overflow: 'hidden',  
            whiteSpace: 'normal',  
            wordWrap: 'break-word', 
            textAlign:'start'
          }}> {fileName}</p> 
          </div>
          <hr style={{ border: 'none', borderTop: '1px solid grey', margin: '15px 0 1px  0 ', opacity:'0.5' }} />
          </div>
           
        );
      default:
        return <a href={mediaUrl} target="_blank" rel="noopener noreferrer" style={{fontSize:'9px' ,marginLeft:'5px', maxWidth: '100%',
        overflow: 'hidden',  
        whiteSpace: 'normal',  
        wordWrap: 'break-word', }}>{mediaUrl}</a>;
    }
  };

  return (
    <div style={{ display: 'flex', paddingBottom: "10px" }}>
      {/* <p style={{ margin: "0", fontSize: "11px", color: "grey", paddingBottom: "10px", marginRight:'10px' }}>
        Media:
      </p> */}
      {renderMedia()}
    </div>
  );
};

export default MediaComponent;


// TODO : CHANGE AUDIO UI