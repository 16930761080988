import { Handle, Position } from "reactflow";
import { v4 as uuidv4 } from 'uuid';
import {  useState   } from "react";


 
import ".././node.css";
//! Provider
import { useMyData } from '../provider/dataProvider';
import { useIdProvider} from '../provider/idProvider';

import MediaComponent from '../components/mediaDisplayComponent.js' 

import ContentCopyIcon from '@mui/icons-material/ContentCopy'; 
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import './css/textNodeStyle.css';
import TextWithStyles from '../components/textStyling.jsx';

import StarBorderIcon from '@mui/icons-material/StarBorder';
import StarIcon from '@mui/icons-material/Star';


function QuestionButtonOptionCanvasNode({ data, id}) {
  const { headerType, headerData,bodyText, footerText, optionType,buttons} = data;

  const [isExpanded, setIsExpanded] = useState(false);
  const [isSelected, setIsSelected] = useState(false);


  const toggleReadMore = () => {
    setIsExpanded(!isExpanded);
  };

  const generateUniqueId = () => {
    return uuidv4();
  };

  const {  
    keyWordList, setKeywordsList,   
     lastUniqueId, setLastUniqueId , 
     canvasTitle, setCanvasTitle , 
     uniqueToken, setUniqueToken, 
     flowId, setFlowId,
      // ! Nodes 
      nodes ,setNodes, onNodesChange,
      // ! edges
      edges, setEdges, onEdgesChange,
      //! initial node id
    initialNodeId, 
    setInitialNodeId ,
      //
  } = useIdProvider();

  const deleteNode=()=>{
    const nodeIndex = nodes.findIndex(node => node.id === id);
       if (nodeIndex === -1) {
       console.warn(`Node with ID "${id}" not found for deletion.`);
       return;
     }

     const updatedNodes = nodes.filter(node => node.id !== id);
     setNodes(updatedNodes); 

     const updatedEdges = edges.filter(edge => edge.source !== id && edge.target !== id);
     setEdges(updatedEdges);
  }

  const { boolValue, setBoolValue, Editdata, setData, editNodeType, setEditNodeType , itemId, setItemId,positionX, setPositionX,positionY, setPositionY} = useMyData();
  const toggleBoolValue = () => {
    console.log("===================== CHECK BOOL  ========================");
    console.log(data);
    console.log(boolValue);
    console.log(id);
    console.log("===================== CHECK BOOL  ========================");
    setEditNodeType("button");
    setData(data);
    setItemId(id);
    setBoolValue(true);
  };

  const getId = () => {
    const id = lastUniqueId + 1;
    setLastUniqueId(id);
    console.log('UNIQUE ID : ', id );
    console.log('PREVIOUS UNIQUE ID : ', lastUniqueId );
    return `${id}`;  
  };

  const createDuplicate=()=>{
    let nodIde;
    let randomX;
    let randomY; 
    nodIde = getId();
    console.log('nodIde: ',nodIde);

    const nodeWithId = nodes.find((node) => node.id === id);
    const positionDetails = nodeWithId.position;
      console.log("Position details:", positionDetails);
      randomX = positionDetails.x+ 350;
      randomY = positionDetails.y;

    console.log('id: ',id);
    console.log('data : ',data);

    // const _dataValues = data;
    const _dataValues = JSON.parse(JSON.stringify(data)); 

    _dataValues.buttons.forEach(button => {
      button.id = generateUniqueId();
  });
    console.log('data 2 : ',data);

    const newNode = {
      id: nodIde,
      type: "button",
      position: { x: randomX, y: randomY },
      data: {
        ..._dataValues,
      },
    };
    console.log('all previous nodes : ', nodes );

    console.log('new node : ', newNode );
    setNodes([...nodes,newNode]);

  }

  const makeNodeInitial = () => {
    // setPlay(true);
    if(isSelected){
      setInitialNodeId('');
    }else{
      setInitialNodeId(id);
    }
    setIsSelected(!isSelected);
    console.log("===================== CHECK BOOL  ========================");
  };


  return (
    <div className="chat-container" style={{display:'flex' ,flexDirection:'column'}}>
    <div className="hover-circles">
    <div className="circle" onClick={makeNodeInitial}>
          {initialNodeId==id ? (
            <StarIcon fontSize="small" sx={{ color: 'violet' }} />
          ) : (
            <StarBorderIcon fontSize="small" sx={{ color: 'violet' }} />
          )}
        </div>

      <div className="circle"   onClick={() => toggleBoolValue()}> 
      <BorderColorOutlinedIcon fontSize="small" color="success" />
 
      </div>
      <div className="circle"  onClick={() => createDuplicate()}>
        <ContentCopyIcon  fontSize="small" color="inherit"/>
      </div>
      <div className="circle"   onClick={deleteNode}>
        <DeleteOutlinedIcon  fontSize="medium"  color="warning"/>
      </div>
    </div>
    <div className="message received box3 sb14 no-shadow" style={{marginBottom :'0px'}}>
       <Handle
          type="target"
          isConnectable={true}
          position={Position.Top}
          id={generateUniqueId()}
          style={{
            width: 20,
            height: 20, 
            top: -10,
            left:10,
            backgroundColor:'#09D999' 
          }}  />
      <div className="message-bubble">
      { headerType==='None' ?null: <MediaComponent mediaType={headerType} mediaUrl={headerData} />   }
          {/* <p className="commen-text">{bodyText}</p>  */}
          <p className="commen-text">
        {/* {isExpanded ? bodyText : `${bodyText.substring(0, 300)}...`} */}
        {isExpanded ?  <TextWithStyles text={bodyText} />   : <TextWithStyles text={`${bodyText.substring(0, 300)}`} /> }
      </p>
      {bodyText.length > 300 && (
        <p onClick={toggleReadMore} style={{color:'blue' , cursor:'pointer' , margin:'0' , fontWeight:'normal', textAlign:'left'}}>  {isExpanded ? `Read Less` : `Read More`} </p>
      )}
        <span className="timestamp">{footerText}</span> 
      </div> 
    </div> 
    <div  style={{display:'flex' ,flexDirection:'column'}} > 
    {buttons.map(button=>(
          <div  className="buttons-1" style={
            {display:'flex',
             alignItems:'center',
             marginTop: "5px",
          }}> 
          <div
            style={{ 
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              width: "100%",
            }}  >
            <div style={{ width: "100%" }}> 
              <p style={{
                  border: "none",
                  width: "92%",
                  padding: "13px",
                  borderRadius: "10px",
                  backgroundColor:"white",
                  textAlign:'center',
                  fontWeight:'bold',
                  margin:'0',

                }}>  {button.text} </p>
            </div>
          </div> 
        <div   style={{   position: 'relative',   display: 'inline-block',}}> 
             <Handle
          type="source"
          isConnectable={true}
          position={Position.Bottom} 
          id={button.id}
          style={{
            width: 20,
            height: 20,
            bottom: 10,  
            backgroundColor:'#8286F9',
            transform: "translateX(-145%)",
            top: -10
          }}  /> 
        </div> 
        </div>
        )) }
        </div> 
  </div> 
  );
  
}
export default QuestionButtonOptionCanvasNode;
