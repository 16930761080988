import { useCallback, useState } from "react";
import { Handle, Position } from "reactflow";
import { v4 as uuidv4 } from "uuid";
import { useIdProvider } from "../provider/idProvider";

import plane from "../../assets/paper-plane.png";
import messageIcon from "../../assets/icons/assign-staff-icon.png";
import deleteIcon from "../../assets/svg/delete_icon.svg";
import editIcon from "../../assets/svg/edit_icon.svg";
import addIcon from "../../assets/svg/add_icon.svg";
import copyIcon from "../../assets/svg/copy_1.svg";
import "../node.css";
//! Provider
import { useMyData } from "../provider/dataProvider";
import { Chip, Stack } from "@mui/material";

import "./css/textNodeStyle.css";

import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import demoImage from "../../assets/demo-image.jpg";

function AssignStaffCanvasNode({ id, type, data }) {
  const {
    staff_id,
    staff_list,
    sent_message_to_staff,
    staff_message,
    lead_category,
    lead_category_id,
  } = data;

  // const [isEditing, setIsEditing] = useState(false);
  //! provider
  const {
    boolValue,
    setBoolValue,
    Editdata,
    setData,
    editNodeType,
    setEditNodeType,
    itemId,
    setItemId,
    positionX,
    setPositionX,
    positionY,
    setPositionY,
  } = useMyData();

  const generateUniqueId = () => {
    return uuidv4();
  };

  const {
    keyWordList,
    setKeywordsList,
    lastUniqueId,
    setLastUniqueId,
    canvasTitle,
    setCanvasTitle,
    uniqueToken,
    setUniqueToken,
    flowId,
    setFlowId,
    // ! Nodes
    nodes,
    setNodes,
    onNodesChange,
    // ! edges
    edges,
    setEdges,
    onEdgesChange,
    //
  } = useIdProvider();

  const deleteNode = () => {
    const nodeIndex = nodes.findIndex((node) => node.id === id);
    if (nodeIndex === -1) {
      console.warn(`Node with ID "${id}" not found for deletion.`);
      return;
    }

    const updatedNodes = nodes.filter((node) => node.id !== id);
    setNodes(updatedNodes);

    const updatedEdges = edges.filter(
      (edge) => edge.source !== id && edge.target !== id
    );
    setEdges(updatedEdges);
  };

  const toggleBoolValue = () => {
    console.log("===================== CHECK BOOL  ========================");
    console.log(data);
    console.log(type);
    console.log(boolValue);
    console.log(id);
    console.log("===================== CHECK BOOL  ========================");
    setEditNodeType("staff");
    setData(data);
    setItemId(id);
    setBoolValue(true);
  };

  const getId = () => {
    const id = lastUniqueId + 1;
    setLastUniqueId(id);
    console.log("UNIQUE ID : ", id);
    console.log("PREVIOUS UNIQUE ID : ", lastUniqueId);
    return `${id}`; //dndnode_
  };

  const createDuplicate = () => {
    let nodIde;
    let randomX;
    let randomY;
    nodIde = getId();
    console.log("nodIde: ", nodIde);

    const nodeWithId = nodes.find((node) => node.id === id);
    const positionDetails = nodeWithId.position;
    console.log("Position details:", positionDetails);
    randomX = positionDetails.x + 350;
    randomY = positionDetails.y;

    console.log("id: ", id);

    const newNode = {
      id: nodIde,
      type: "staff",
      position: {
        x: randomX,
        y: randomY,
      },
      data: {
        staff_id: data.staff_id,
        staff_list: data.staff_list,
        sent_message_to_staff: data.sent_message_to_staff,
        staff_message: data.staff_message,
        lead_category: data.lead_category,
        lead_category_id: data.lead_category_id,
      },
    };
    console.log("all previous nodes : ", nodes);

    console.log("new node : ", newNode);
    setNodes([...nodes, newNode]);
  };

  return (
    <div className="chat-container">
      <div className="hover-circles">
        <div className="circle" onClick={() => toggleBoolValue()}>
          <BorderColorOutlinedIcon fontSize="small" color="success" />
        </div>
        <div className="circle" onClick={() => createDuplicate()}>
          <ContentCopyIcon fontSize="small" color="inherit" />
        </div>
        <div className="circle" onClick={deleteNode}>
          <DeleteOutlinedIcon fontSize="medium" color="warning" />
        </div>
      </div>
      <div className="message received box3 sb14">
        <Handle
          type="target"
          isConnectable={true}
          position={Position.Top}
          id={generateUniqueId()}
          style={{
            width: 20,
            height: 20,
            top: -10,
            left: 10,
            backgroundColor: "#09D999",
          }}
        />
        <div className="message-bubble">
          <div style={{ display: "flex", flexDirection: "column", backgroundColor: "#F6F5F3",borderRadius: "0 5% 5% 0", }}>
            <div style={{ display: "flex" }}>
              <div
                style={{
                  height: "40px",
                  backgroundColor: "#F6F5F3",
                  display: "flex",
                  width: "100%",
                  marginBottom: "10px",
                  borderRadius: "0 5% 5% 0",
                }}
              >
                {staff_list.map((staff, index) =>
                  index < 5 ? (
                    <div
                      key={staff.user_id}
                      className="staff-item"
                      style={{
                        zIndex: index,
                        marginLeft: -4 * index,
                        position: "relative",
                        top: "15px",
                        left: "10px",
                      }}
                    >
                      <img
                        style={{
                          height: "40px",
                          width: "40px",
                          borderRadius: "50%",
                        }}
                        className="staff-image"
                        src={staff.staff_profile}
                        alt={`${staff.staff_name}'s profile`}
                      />
                    </div>
                  ) : null
                )}

                {staff_list.length > 5 && (
                  <div
                    className="staff-item more"
                    style={{
                      zIndex: 5,
                      marginLeft: -10 * 5,
                      position: "relative",
                      top: "15px",
                      left: "10px",
                    }}
                  >
                    <div className="more-circle">+{staff_list.length - 5}</div>
                  </div>
                )}

                {/* //! */}
              </div>
            </div>
            <div  style={{ display: "flex", flexDirection: "row" , marginLeft:'10px'}} className='staff-names' >
            {staff_list.map((staff, index) =>
              
                <div
                  key={staff.user_id}
                  className="staff-name"> 
                  <p style={{ color: "black", fontWeight: "bold" }}>
                  {staff.staff_name}{index < staff_list.length - 1 ? ', ' : ''}
                  </p>
                </div>
              
            )}
                  <div style={{width:'90px' ,height:'20px' , backgroundColor:'#8A8988',background: 'linear-gradient(to right, rgba(255, 255, 255, 0), #F6F5F3)' ,top:'85px',right:'20px', position:'absolute' }}> </div>
         
             
          
          </div>
          </div>
          <p
            style={{
              color: "#0063CA",
              margin: "0",
              fontWeight: "lighter",
              marginBottom: "10px",
              marginTop: "10px",
              textAlign: "start",
            }}
          >
            {lead_category}
          </p>
          <p className="commen-text" style={{ marginBottom: "20px" }}>
            {staff_message}
          </p>
        </div>
        <Handle
          type="source"
          isConnectable={true}
          position={Position.Bottom}
          id={generateUniqueId()}
          style={{
            width: 20,
            height: 20,
            bottom: 10,
            backgroundColor: "#8286F9",
            transform: "translateX(625%)",
          }}
        />
      </div>
    </div>
  );
}
export default AssignStaffCanvasNode;
