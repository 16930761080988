import { useCallback, useState } from "react";
import { Handle, Position } from "reactflow";
import { v4 as uuidv4 } from 'uuid';
import { useIdProvider} from '../provider/idProvider';

import demoImage from "../../assets/demo-image.jpg";
import messageIcon from "../../assets/icons/catelog-icon.png";
import deleteIcon from "../../assets/svg/delete_icon.svg";
import editIcon from "../../assets/svg/edit_icon.svg";
import addIcon from "../../assets/svg/add_icon.svg";
import copyIcon from "../../assets/svg/copy_1.svg";
import "../node.css";
//! Provider
import { useMyData } from '../provider/dataProvider';

import './css/textNodeStyle.css';

import ContentCopyIcon from '@mui/icons-material/ContentCopy'; 
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';

function SendCatalogCanvasNode({ id, type, data, }) {
  const { catalog_id ,catalog_title ,footerText,  headerText, buttonText, messageText } = data;
console.log('data : ',data);
  // const [isEditing, setIsEditing] = useState(false);
   //! provider
   const { boolValue, setBoolValue, Editdata, setData, editNodeType, setEditNodeType , itemId, setItemId,positionX, setPositionX,positionY, setPositionY} = useMyData();

  const generateUniqueId = () => {
    return uuidv4();
  };

  const {  
    keyWordList, setKeywordsList,   
     lastUniqueId, setLastUniqueId , 
     canvasTitle, setCanvasTitle , 
     uniqueToken, setUniqueToken, 
     flowId, setFlowId,
      // ! Nodes 
      nodes ,setNodes, onNodesChange,
      // ! edges
      edges, setEdges, onEdgesChange,
      //
  } = useIdProvider();

  const deleteNode=()=>{
    const nodeIndex = nodes.findIndex(node => node.id === id);
       if (nodeIndex === -1) {
       console.warn(`Node with ID "${id}" not found for deletion.`);
       return;
     }

     const updatedNodes = nodes.filter(node => node.id !== id);
     setNodes(updatedNodes); 

     const updatedEdges = edges.filter(edge => edge.source !== id && edge.target !== id);
     setEdges(updatedEdges);
  }

  const toggleBoolValue = () => {
    console.log("===================== CHECK BOOL  ========================");
    console.log(data);
    console.log(type);
    console.log(boolValue);
    console.log(id);
    console.log("===================== CHECK BOOL  ========================");
    setEditNodeType("catalog");
    setData(data);
    setItemId(id);
    setBoolValue(true);
  };

  const getId = () => {
    const id = lastUniqueId + 1;
    setLastUniqueId(id);
    console.log('UNIQUE ID : ', id );
    console.log('PREVIOUS UNIQUE ID : ', lastUniqueId );
    return `${id}`; //dndnode_
  };

  const createDuplicate=()=>{
    let nodIde;
    let randomX;
    let randomY; 
    nodIde = getId();
    console.log('nodIde: ',nodIde);

    const nodeWithId = nodes.find((node) => node.id === id);
    const positionDetails = nodeWithId.position;
      console.log("Position details:", positionDetails);
      randomX = positionDetails.x+ 350;
      randomY = positionDetails.y;

    console.log('id: ',id);

    console.log('===============  DATA =======================',);
    console.log('DATA: ',data);

    console.log('======================================',);

    const newNode = {
      id: nodIde,
      type: "catalog",
      position: {
        x: randomX,
        y: randomY,
      },
      data: {
        catalog_id: data.catalog_id, 
        catalog_title: data.catalog_title,
      },
    };
    console.log('all previous nodes : ', nodes );

    console.log('new node : ', newNode );
    setNodes([...nodes,newNode]);

  }



  return (

    <div className="chat-container">
    <div className="hover-circles">
      <div className="circle"   onClick={() => toggleBoolValue()}> 
      <BorderColorOutlinedIcon fontSize="small" color="success" />
 
      </div>
      <div className="circle"  onClick={() => createDuplicate()}>
        <ContentCopyIcon  fontSize="small" color="inherit"/>
      </div>
      <div className="circle"   onClick={deleteNode}>
        <DeleteOutlinedIcon  fontSize="medium"  color="warning"/>
      </div>
    </div>
    <div className="message received box3 sb14">
       <Handle
          type="target"
          isConnectable={true}
          position={Position.Top}
          id={generateUniqueId()}
          style={{
            width: 20,
            height: 20, 
            top: -10,
            left:10,
            backgroundColor:'#09D999' 
          }}  />
      <div className="message-bubble">
        <div style={{height:'70px', backgroundColor:"#F6F5F3",display:'flex' ,width:'100%' , marginBottom:'10px',   borderRadius: '0 5% 5% 0'}}>
        <img
              src={demoImage}
              alt="Image description"
              style={{ 
                height: "100%",
                width: '90px',
                margin: "0",
                color:'#0063CA',
                borderRadius: '6% 0 0 6%'
              }}  />
              <div style={{marginLeft:'10px' , display:'flex' ,flexDirection:'column' , justifyContent:'center' }}>
        <p className="commen-text" style={{fontWeight:'bold' ,fontSize:'14px'}}>{headerText}</p>
        <p className="commen-text" style={{color:'#666A6D' ,fontSize:'12px', marginTop:'3px'}}>{catalog_title}</p>

                
              </div>

        </div>
        <p className="commen-text">{messageText}</p> 
        <span className="timestamp">{footerText}</span> 
        <hr style={{ border: 'none', borderTop: '1px solid grey', margin: '15px 0 1px  0 ', opacity:'0.5' }} />
          <div style={{display:'flex',alignItems:'center',justifyContent:'center', marginTop:'10px' }}>
          {/* <img
              src={messageIcon}
              alt="Image description"
              style={{ 
                height: "20px",
                margin: "0",
                color:'#0063CA',
              }}  /> */}
              <p style={{color:'#0063CA', margin:'0' , marginLeft:'5px',fontWeight:'lighter' }}>
               {buttonText}
              </p>
          </div>
      </div>
      <Handle
          type="source"
          isConnectable={true}
          position={Position.Bottom}
          id={generateUniqueId()}
          style={{
            width: 20,
            height: 20,
            bottom: 10,  
            backgroundColor:'#8286F9',
            transform: "translateX(625%)",
          }}  />
    </div> 
  </div>
  );
}
export default SendCatalogCanvasNode;
