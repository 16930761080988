import React, { createContext, useState, useContext } from 'react';

const MyDataContext = createContext();

export const MyDataProvider = ({ children }) => {
  const [boolValue, setBoolValue] = useState(false);
  const [Editdata, setData] = useState(null);
  const [editNodeType, setEditNodeType] = useState("");
  const [itemId, setItemId] = useState("");
  const [positionX, setPositionX] = useState("");
  const [positionY, setPositionY] = useState("");

  return (
    <MyDataContext.Provider value={{ boolValue, setBoolValue, Editdata, setData , editNodeType, setEditNodeType, itemId ,setItemId, positionX, setPositionX,positionY, setPositionY}}>
      {children}
    </MyDataContext.Provider>
  );
};

export const useMyData = () => useContext(MyDataContext);
