// import React, { useEffect, useRef, useState } from 'react';
// import WaveSurfer from 'wavesurfer.js';
// import './styles/audioStyle.css';

// function AudioMessage({ audioUrl }) {
//   const waveformRef = useRef(null);
//   const waveSurfer = useRef(null);
//   const [isPlaying, setIsPlaying] = useState(false);

//   useEffect(() => {
//     waveSurfer.current = WaveSurfer.create({
//       container: waveformRef.current,
//       waveColor: '#ddd',
//       progressColor: '#4caf50',
//       height: 80,
//       barWidth: 2,
//     });

//     waveSurfer.current.load(audioUrl);

//     waveSurfer.current.on('ready', () => {
//       console.log('WaveSurfer is ready');
//     });

//     return () => {
//       waveSurfer.current.destroy();
//     };
//   }, [audioUrl]);

//   const togglePlay = () => {
//     setIsPlaying(!isPlaying);
//     waveSurfer.current.playPause();
//   };

//   return (
//     <div className="audio-message">
//       <button onClick={togglePlay} className="play-button">
//         {isPlaying ? 'Pause' : 'Play'}
//       </button>
//       <div ref={waveformRef} className="waveform" />
//     </div>
//   );
// }

// export default AudioMessage;
import React, { useRef, useState } from 'react';
 import './styles/audioStyle.css';

function AudioMessage({ audioUrl }) {
  const audioRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);

  const togglePlay = () => {
    if (isPlaying) {
      audioRef.current.pause();
    } else {
      audioRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  return (
    <div className="audio-message">
      <button onClick={togglePlay} className="play-button">
        {isPlaying ? 'Pause' : 'Play'}
      </button>
      <audio ref={audioRef} src={audioUrl} />
    </div>
  );
}

export default AudioMessage;
