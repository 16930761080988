import React, { createContext, useState, useContext } from 'react';
import ReactFlow, {
  useNodesState,
  useEdgesState,
} from 'reactflow';

const IdContext = createContext();


export const IDProvider = ({ children }) => {
    
    const [keyWordList, setKeywordsList] = useState([]);
    const [lastUniqueId, setLastUniqueId] = useState(1);
    const [canvasTitle, setCanvasTitle] = useState("");
    const [uniqueToken, setUniqueToken] = useState("");
    const [paramUrl, setParamUrl] = useState("");
    const [flowId, setFlowId] = useState();


    const [nodes, setNodes, onNodesChange] = useNodesState([]);
    const [edges, setEdges, onEdgesChange] = useEdgesState([]);


    // position
    const [ previousPositionY, setPositionY] = useState("");
    const [ previouspositionX, setPositionX] = useState("");
    // initialnode
    const [ initialNodeId, setInitialNodeId] = useState("");

    console.log('nodes :',nodes);

    
    return (
      <IdContext.Provider 
      value={{ 
        keyWordList, setKeywordsList, 
        lastUniqueId, setLastUniqueId , 
        canvasTitle, setCanvasTitle ,
        uniqueToken, setUniqueToken,
        paramUrl, setParamUrl,
        flowId, setFlowId,
        // ! Nodes 
        nodes ,setNodes, onNodesChange,
        // ! edges
        edges, setEdges, onEdgesChange,
        //! POSITION
        previousPositionY, setPositionY,
        previouspositionX, setPositionX,
        //! initialNodeId,
        initialNodeId, setInitialNodeId
        }}>
        {children}
      </IdContext.Provider>
    );
  };
  
  export const useIdProvider = () => useContext(IdContext);