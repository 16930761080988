import playButton from "../../assets/play-button.png";
import messageIcon from "../../assets/svg/list-svg.svg";
import deleteIcon from "../../assets/svg/delete_icon.svg";
import editIcon from "../../assets/svg/edit_icon.svg";
import addIcon from "../../assets/svg/add_icon.svg"; //svg/add_icon.svg"
import copyIcon from "../../assets/svg/copy_1.svg";
import { useState  } from "react";

import ".././node.css";
import { v4 as uuidv4 } from "uuid";
import { Handle, Position } from "reactflow";
//! Provider
import { useMyData } from "../provider/dataProvider";
import { useIdProvider } from "../provider/idProvider";

import ContentCopyIcon from '@mui/icons-material/ContentCopy'; 
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import './css/textNodeStyle.css';
import TextWithStyles from '../components/textStyling';

import StarBorderIcon from '@mui/icons-material/StarBorder';
import StarIcon from '@mui/icons-material/Star';

//! component
import NodeTitleValueComponent from "../components/requiredTextComponent.js";

function AskListOptionCanvasNode({ data, id }) {

  const [isExpanded, setIsExpanded] = useState(false);
  const [isSelected, setIsSelected] = useState(false);

  const toggleReadMore = () => {
    setIsExpanded(!isExpanded);
  };
  const {
    questionText,
    footerText,
    buttonTitle,
    selectedOption,
    sections = [],
  } = data;

  const generateUniqueId = () => {
    return uuidv4();
  };

  const {
    keyWordList,
    setKeywordsList,
    lastUniqueId,
    setLastUniqueId,
    canvasTitle,
    setCanvasTitle,
    uniqueToken,
    setUniqueToken,
    flowId,
    setFlowId,
    // ! Nodes
    nodes,
    setNodes,
    onNodesChange,
    // ! edges
    edges,
    setEdges,
    onEdgesChange,
    //! initial node id
    initialNodeId, 
    setInitialNodeId ,
    //
  } = useIdProvider();

  const deleteNode = () => {
    const nodeIndex = nodes.findIndex((node) => node.id === id);
    if (nodeIndex === -1) {
      console.warn(`Node with ID "${id}" not found for deletion.`);
      return;
    }

    const updatedNodes = nodes.filter((node) => node.id !== id);
    setNodes(updatedNodes);

    const updatedEdges = edges.filter(
      (edge) => edge.source !== id && edge.target !== id
    );
    setEdges(updatedEdges);
  };

  const {
    boolValue,
    setBoolValue,
    Editdata,
    setData,
    editNodeType,
    setEditNodeType,
    itemId,
    setItemId,
    positionX,
    setPositionX,
    positionY,
    setPositionY,
  } = useMyData();

  const toggleBoolValue = () => {
    console.log("===================== CHECK BOOL  ========================");
    console.log(data);
    console.log(boolValue);
    console.log(id);
    console.log("===================== CHECK BOOL  ========================");
    setEditNodeType("listOption");
    setData(data);
    setItemId(id);
    setBoolValue(true);
  };

  const getId = () => {
    const id = lastUniqueId + 1;
    setLastUniqueId(id);
    console.log('UNIQUE ID : ', id );
    console.log('PREVIOUS UNIQUE ID : ', lastUniqueId );
    return `${id}`; //dndnode_
  };

  const createDuplicate=()=>{
    let nodIde;
    let randomX;
    let randomY; 
    nodIde = getId();
    console.log('nodIde: ',nodIde);

    const nodeWithId = nodes.find((node) => node.id === id);
    const positionDetails = nodeWithId.position;
      console.log("Position details:", positionDetails);
      randomX = positionDetails.x+ 350;
      randomY = positionDetails.y;

    console.log('id: ',id);
    console.log('data: ',data);

    const _dataValues = JSON.parse(JSON.stringify(data)); 

    _dataValues.sections.forEach(section => {
      section.id = generateUniqueId();
      section.options.forEach(option => {
          option.newSectionId = generateUniqueId();
      });
  });
    console.log('data 2 : ',data);

    const newNode = {
      id: nodIde,
      type: "listOption",
      position: { x: randomX, y: randomY },
      data: {
        ..._dataValues,
        type: "listOption",
      },
    };
    console.log('all previous nodes : ', nodes );

    console.log('new node : ', newNode );
    setNodes([...nodes,newNode]);

  }

  const makeNodeInitial = () => {
    // setPlay(true);
    if(isSelected){
      setInitialNodeId('');
    }else{
      setInitialNodeId(id);
    }
    setIsSelected(!isSelected);
    console.log("===================== CHECK BOOL  ========================");
  };

  return (
    <div className="chat-container" style={{display:'flex' ,flexDirection:'column'}}>
    <div className="hover-circles">

    <div className="circle" onClick={makeNodeInitial}>
          {initialNodeId==id ? (
            <StarIcon fontSize="small" sx={{ color: 'violet' }} />
          ) : (
            <StarBorderIcon fontSize="small" sx={{ color: 'violet' }} />
          )}
        </div>

        
      <div className="circle"   onClick={() => toggleBoolValue()}> 
      <BorderColorOutlinedIcon fontSize="small" color="success" />
 
      </div>
      <div className="circle"  onClick={() => createDuplicate()}>
        <ContentCopyIcon  fontSize="small" color="inherit"/>
      </div>
      <div className="circle"   onClick={deleteNode}>
        <DeleteOutlinedIcon  fontSize="medium"  color="warning"/>
      </div>
    </div>
    <div className="message received box3 sb14 no-shadow" style={{marginBottom :'0px'}}>
       <Handle
          type="target"
          isConnectable={true}
          position={Position.Top}
          id={generateUniqueId()}
          style={{
            width: 20,
            height: 20, 
            top: -10,
            left:10,
            backgroundColor:'#09D999' 
          }}  />
      <div className="message-bubble">
          {/* <p className="commen-text">{questionText}</p>  */}
          <p className="commen-text">
        {/* {isExpanded ? questionText : `${questionText.substring(0, 300)}...`} */}
        {isExpanded ?  <TextWithStyles text={questionText} />  :   <TextWithStyles text={`${questionText.substring(0, 300)}`} /> }
      </p>
      {questionText.length > 300 && (
        <p onClick={toggleReadMore} style={{color:'blue' , cursor:'pointer' , margin:'0' , fontWeight:'normal', textAlign:'left'}}>  {isExpanded ? `Read Less` : `Read More`} </p>
      )}
        <span className="timestamp">{footerText}</span> 
        <hr style={{ border: 'none', borderTop: '1px solid grey', margin: '15px 0 1px  0 ', opacity:'0.5' }} />
          <div style={{display:'flex',alignItems:'center',justifyContent:'center', marginTop:'10px' }}>
          <img
              src={messageIcon}
              alt="Image description"
              style={{ 
                height: "20px",
                margin: "0",
                color:'#0063CA',
              }}  />
              <p style={{
                color:'#0063CA', 
                margin:'0' , 
                marginLeft:'10px',
                fontWeight:'lighter' ,
                maxWidth: '100%',
                overflow: 'hidden',  
                whiteSpace: 'normal',  
                wordWrap: 'break-word',  
                }}>
               {buttonTitle}
              </p>
          </div>
      </div> 
    </div> 
    <div  style={{display:'flex' ,flexDirection:'column'}} > 

       
    
          <div  className="buttons-1" style={
            {display:'flex',
             alignItems:'center',
             marginTop: "5px",
          }}> 
          <div
            style={{ 
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              width: "100%",
            }}  >
            <div style={{ width: "100%" , 
                 border: "none",
                 width: "92%",
                 padding: "13px",
                 borderRadius: "10px",
                 backgroundColor:"white",
                 textAlign:'start',
                 fontWeight:'bold',
                 margin:'0',


             }}> 
           
              {sections.map(section=>(
                <div
                key={section.id}
                >
                  <p style={{
                    color:'#0063CA',
                     margin:'0' , 
                     marginLeft:'5px', 
                     marginTop:'10px', 
                     fontWeight:'bold' ,
                     }}>
               {section.sectionName}
              </p>
                   
                
                {section.options.map((content, index) => (
                <div key={index} style={{display:'flex',alignItems:'center', width:'300px' }}>
                  <div
                    style={{
                      width:'290px', 
                      borderRadius: "5px",
                      display: "flex",
                      paddingTop: "10px",
                    }}  >
                    <div style={{ marginLeft: "8px", width: "100%" }}>
                      <div style={{}}>
                        <div style={{ display: "flex" }}> 
                        </div>
                        <p style={{
                          marginBottom:'5px',
                          marginTop:'5px',
                            
                          }}>{content.title}</p>
                        <p style={{
                          fontSize:'11px',
                          marginTop:'0px',
                          fontWeight:'normal',
                          overflow:'hidden',
                          textOverflow:'ellipsis',
                          whiteSpace:'nowrap',
                          maxWidth:'90%'
                          }} >{content.description}</p>
                      </div>
                    </div>
                  </div>
                  {/* //! */}
                  <div className="add_icon_main" style={{
                    marginLeft:'10px' , 
                    marginRight:'0' ,
                    display:'flex',
                    justifyContent:'end' }}>
        <div style={{   position: 'relative',  display: 'inline-block',}}>
          
             <Handle
            type="source"
            isConnectable={true}
            position={Position.Right}
            id={content.newSectionId}
            style={{
              width: 20,
              height: 20,
              backgroundColor:'#8286F9',
            }}  />
        </div> 
      </div> 
                </div>
              ))}
            </div>
          ))}

                </div>
            </div>
          </div> 
        </div> 
  </div> 
 
  );
}

export default AskListOptionCanvasNode;
