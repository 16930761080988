import React, { useState, useRef, useCallback, useEffect } from "react";
import ReactFlow, {
  ReactFlowProvider,
  addEdge,
  useNodesState,
  useEdgesState,
  Controls,
  getIncomers,
  getOutgoers,
  getConnectedEdges,
  Panel,
  MiniMap,
  useReactFlow,
} from "reactflow";
import axios from "axios";
import "./index123.css";
import "reactflow/dist/style.css";
import Sidebar from "./Slidebar";
import { MyDataProvider } from "./nodes/provider/dataProvider.jsx";
import { useIdProvider } from "./nodes/provider/idProvider.jsx";
// message node
import SentTextMessageCanvasNode from "./nodes/canvasNodes/sentTextMessageCanvasNode.js";
import SentMediaMessageCanvasNode from "./nodes/canvasNodes/sentMediaMessageCanvasNode.js";
import SendTemplateNode from "./nodes/sendTemplateNode.js";
// question node
import QuestionButtonOptionCanvasNode from "./nodes/canvasNodes/questionButtonCanvasNode.js";
import AskListOptionCanvasNode from "./nodes/canvasNodes/listOptionCanvasNode.js";
import AskQuestionCanvasNode from "./nodes/canvasNodes/askQuestionCanvasNode.js";
import SendCatalogCanvasNode from "./nodes/canvasNodes/sendCatalogCanvasNode.js";
import AssignStaffCanvasNode from "./nodes/canvasNodes/assignStaffCanvasNode.js";
import AskLocationCanvasNode from "./nodes/canvasNodes/askLocationCanvasNode.js";
import StatusAlert, { StatusAlertService } from "react-status-alert";
import "./nodes/text-updater-node.css";

import Alert from "@mui/material/Alert";
import ConnectionLine from './nodes/components/connectionLine.jsx'


// import { useIdProvider } from './nodes/provider/idProvider.jsx';

const nodeTypes = {
  text: SentTextMessageCanvasNode,
  sentMeadiaMessage: SentMediaMessageCanvasNode,
  sendTemplateMessage: SendTemplateNode,
  button: QuestionButtonOptionCanvasNode,
  listOption: AskListOptionCanvasNode,
  question: AskQuestionCanvasNode,
  catalog: SendCatalogCanvasNode,
  staff : AssignStaffCanvasNode,
  location : AskLocationCanvasNode,
};

const rfStyle = {
  backgroundColor: "#EEE7E1",  
  // backgroundColor: "#1E1E1E",  
};

const DnDFlow = () => {
  const reactFlowWrapper = useRef(null);
  const [reactFlowInstance, setReactFlowInstance] = useState(null);

  const {
    keyWordList,
    setKeywordsList,
    lastUniqueId,
    setLastUniqueId,
    canvasTitle,
    setCanvasTitle,
    uniqueToken,
    setUniqueToken,
    flowId,
    setFlowId,
    //! url
    paramUrl,
    setParamUrl,

    // ! Nodes
    nodes,
    setNodes,
    onNodesChange,
    // ! edges
    edges,
    setEdges,
    onEdgesChange,
    //
    previousPositionY,
    setPositionY,
    previouspositionX,
    setPositionX,
  } = useIdProvider();

  const getId = () => {
    const id = lastUniqueId + 1;
    setLastUniqueId(id);
    console.log('UNIQUE ID : ', id );
    console.log('PREVIOUS UNIQUE ID : ', lastUniqueId );
    return `${id}`; //dndnode_
  };

  useEffect(() => {
    console.log("================= Initial Function ================");
    fetchData();
  }, []);

console.log('nodes : ',nodes);
console.log('edges : ',edges);

  const fetchData = async () => {
    const urlParams = new URLSearchParams(window.location.search);
    const tokenParam = urlParams.get("token");
    const urlParam = urlParams.get("url");
    setUniqueToken(tokenParam);
    setParamUrl(urlParam);
    try {
      console.log(" urlParam :", urlParam);
      console.log(" uniqueToken :", uniqueToken);
      console.log(" tokenParam :", tokenParam);
      const encodedToken = encodeURIComponent(tokenParam);
      console.log(" encodedToken :", encodedToken);
      //https://myaccount.login2.in/app/index.php/Webhook/addFlow?token=CQdNtjRgDlLOdIWuMwi6Ng==
      // const response = await axios.get(`https://myaccount.login2.in/app/index.php/Webhook/addFlow?token=CQdNtjRgDlLOdIWuMwi6Ng==`);
      const response = await axios.get(
        `https://${urlParam}/index.php/Webhook/addFlow?token=${encodedToken}`
        // `https://myaccount.login2.in/index.php/Webhook/addFlow?token=${encodedToken}`
        // `https://myaccount.login2.in/app/index.php/Webhook/addFlow?token=${tokenParam}`
      );
      console.log(" Initial Function responce");
      console.log(" data :", response.data);
      // setToken(response.data.token);
      setFlowId(response.data.flow_id);
      // setKeywords(response.data.keywords);
      setNodes(response.data.nodes);
      setEdges(response.data.edges);

      if (response.data && response.data.hasOwnProperty("title")) {
        setCanvasTitle(response.data.title);
      } else {
        setCanvasTitle("Untitled");
      }
      //!
      console.log('lent : ', response.data.nodes.length );
      console.log('last node : ',response.data.nodes[ response.data.nodes.length-1] );
      console.log('last node id : ',response.data.nodes[ response.data.nodes.length-1].id );
      console.log('last node id +10: ',response.data.nodes[ response.data.nodes.length-1].id + 10);
      let lastNodeId = response.data.nodes[response.data.nodes.length - 1].id;
let lastNodeIdNumber = parseInt(lastNodeId, 10); // parseInt ensures that it's treated as a number
console.log('last node id +10: ', lastNodeIdNumber + 10);

      setLastUniqueId(lastNodeIdNumber + 10);
      //! PROVIDER
      setKeywordsList(response.data.existingKeywords);
      // setUniqueToken(response.data.token);
    } catch (error) {
      console.log(" Initial Function error");
      console.log(" ERROR :", error);
    }
  };

  // const onConnect = useCallback(
  //   (params) => setEdges((eds) => addEdge(params, eds)),
  //   []
  // );
  //!
  // const onConnect = (params) => {
  //   const { source, target } = params;
 
  //   const targetHasIncomingConnection = edges.some(
  //     (edge) => edge.target === target
  //   );
 
  //   const sourceHasOutgoingConnection = edges.some(
  //     (edge) => edge.source === source
  //   );

  //   if (targetHasIncomingConnection || sourceHasOutgoingConnection) {
  //     StatusAlertService.showWarning("Each node can only have one incoming and one outgoing connection!");
  //     return;
  //   }

  //   setEdges((eds) => addEdge(params, eds));
  // };
  //!
  // const onConnect = (params) => {
  //   const { source, target } = params;
 
  //   const targetHasIncomingConnection = edges.some(
  //     (edge) => edge.target === target
  //   );

  //   if (targetHasIncomingConnection) {
  //     StatusAlertService.showWarning("Each node can only have one incoming connection!");
  //     return;
  //   }

  //   setEdges((eds) => addEdge(params, eds));
  // };
  //!
  const onConnect = (params) => {
    const { source, target, sourceHandle } = params;
   
    const sourceNode = nodes.find(node => node.id === source);
   
    if (!sourceNode) {
      console.error('Source node not found');
      return;
    } 
    const sourceType = sourceNode.type;
  
    console.log('Source node type:', sourceType);
   
    const targetHasIncomingConnection = edges.some(
      (edge) => edge.target === target
    );
  
    // if (targetHasIncomingConnection) {
    //   StatusAlertService.showWarning("Each node can only have one incoming connection!");
    //   return;
    // }
  
    if ( sourceType === 'text' || sourceType ==='sentMeadiaMessage' || sourceType === 'location' || sourceType === 'question'  || sourceType === 'catalog'  || sourceType === 'staff' ) {
      setEdges((eds) => {
        return eds.filter(edge => edge.source !== source);
      });
    }else if ( sourceType ==='listOption' ) { 

    const sourceEdges = edges.filter(edge => edge.source === source && edge.sourceHandle === sourceHandle);
    if (sourceEdges.length > 0) { 
      setEdges((eds) => eds.filter(edge => !(edge.source === source && edge.sourceHandle === sourceHandle)));
    }
      
    }else if ( sourceType ==='button') { 
    const sourceEdges = edges.filter(edge => edge.source === source && edge.sourceHandle === sourceHandle);
    if (sourceEdges.length > 0) { 
      setEdges((eds) => eds.filter(edge => !(edge.source === source && edge.sourceHandle === sourceHandle)));
    }
    }
   
    setEdges((eds) => addEdge(params, eds));
  };
  
  
  //!
  

  const onDragOver = useCallback((event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "move";
  }, []);

  const onDrop = useCallback(
    (event) => {
      event.preventDefault();

      const type = event.dataTransfer.getData("application/reactflow");
      console.log("===================== flow  ========================");
      console.log(event);
      console.log(event.data);
      // console.log( event.data.subTitle);
      console.log("=============================================");

      // check if the dropped element is valid
      if (typeof type === "undefined" || !type) {
        return;
      }

      const position = reactFlowInstance.screenToFlowPosition({
        x: event.clientX,
        y: event.clientY,
      });
      const newNode = {
        id: getId(),
        type,
        position,
        data: { label: `${type}` },
      };

      setNodes((nds) => nds.concat(newNode));
    },
    [reactFlowInstance]
  );
  //! DELETE
  const onNodesDelete = useCallback(
    (deleted) => {
      setEdges(
        deleted.reduce((acc, node) => {
          const incomers = getIncomers(node, nodes, edges);
          const outgoers = getOutgoers(node, nodes, edges);
          const connectedEdges = getConnectedEdges([node], edges);

          const remainingEdges = acc.filter(
            (edge) => !connectedEdges.includes(edge)
          );

          const createdEdges = incomers.flatMap(({ id: source }) =>
            outgoers.map(({ id: target }) => ({
              id: `${source}->${target}`,
              source,
              target,
            }))
          );

          return [...remainingEdges, ...createdEdges];
        }, edges)
      );
    },
    [nodes, edges]
  );
  //
  const onNodeDragStop = (event, node) => {
    setPositionY(node.position.y);
    setPositionX(node.position.x);
  };

  // todo : later add these to addNodeFromSlider function
  const addQuestionNodeFromOutside = (data, id) => {
    console.log(
      "===================== addNodeFromOutside  ========================"
    );
    console.log(data);

    let nodIde;
    console.log("Id : ", id);
    if (id && id.length > 0) {
      console.log("have ID ");

      console.log("Id Length : ", id.length);
      console.log("Id : ", id);
      nodIde = id;
    } else {
      console.log("no ID ");
      nodIde = getId();
    }

    let randomX;
    let randomY;
    const nodeWithId = nodes.find((node) => node.id === id);
    if (nodeWithId) {
      // If the id is found in the nodes array, get its position details
      const positionDetails = nodeWithId.position;
      console.log("Position details:", positionDetails);
      randomX = positionDetails.x;
      randomY = positionDetails.y;
    } else {
      // If the id is not found, generate random position details
      randomX = Math.floor(Math.random() * (window.innerWidth - 200)) + 100;
      randomY = Math.floor(Math.random() * (window.innerHeight - 200)) + 100;
      console.log("Random position details:", { x: randomX, y: randomY });
    }
    // const randomX = Math.floor(Math.random() * (window.innerWidth - 200)) + 100; // Adjust range as needed
    // const randomY = Math.floor(Math.random() * (window.innerHeight - 200)) + 100; // Adjust range as needed

    const {
      headerType,
      bodyText,
      footerText,
      optionType,
      isBodyBold,
      isBodyItalic,
      isBodyStrikethrough,
      buttons,
    } = data;

    const newNode = {
      id: nodIde,
      type: "button",
      position: { x: randomX, y: randomY },
      data: {
        ...data,
      },
    };
    // setNodes((prevNodes) => [...prevNodes, newNode]);
    setNodes((prevNodes) => {
      // todo : check all nodes remove the same id nodes
      // Remove any existing node with the same id
      const filteredNodes = prevNodes.filter((node) => node.id !== id);
      console.log('flterd nodes 1 : ', filteredNodes );
      // Add the new node
      return [...filteredNodes, newNode];
    });
    // generateCustomJSON();
  };

  const addNodeFromSlider = (data, type, id) => {
    console.log(
      "===================== addNodeFromSlider  ========================"
    );
    console.log(data);

    let nodIde;
    console.log("Id : ", id);
    if (id && id.length > 0) {
      console.log("have ID ");

      console.log("Id Length : ", id.length);
      console.log("Id : ", id);
      nodIde = id;
    } else {
      console.log("no ID ");
      nodIde = getId();
      console.log("NEW ID :  ",nodIde);
    }

    let randomX;
    let randomY;
    const nodeWithId = nodes.find((node) => node.id === id);
    if (nodeWithId) {
      // If the id is found in the nodes array, get its position details
      const positionDetails = nodeWithId.position;
      console.log("Position details:", positionDetails);
      randomX = positionDetails.x;
      randomY = positionDetails.y;
    } else if (previousPositionY !== "" && previouspositionX !== "") {
      randomX = previouspositionX + 220;
      randomY = previousPositionY ;
      setPositionY(randomY);
      setPositionX(randomX);
    } else {
      // If the id is not found, generate random position details
      randomX = window.innerWidth / 2;
      randomY = window.innerHeight / 2;
      setPositionY(randomY);
      setPositionX(randomX);
      console.log("Center of the screen:", { x: randomX, y: randomY });
    }
    
    // const randomX = Math.floor(Math.random() * (window.innerWidth - 200)) + 100;
    // const randomY = Math.floor(Math.random() * (window.innerHeight - 200)) + 100;
    let newNode = {};
    switch (type) {
      case "sentMeadiaMessage":
        newNode = {
          id: nodIde,
          type: "sentMeadiaMessage",
          position: { x: randomX, y: randomY },
          data: {
            ...data,
            type: data.mediaType,
          },
        };
        break;
      case "listOption":
        newNode = {
          id: nodIde,
          type: "listOption",
          position: { x: randomX, y: randomY },
          data: {
            ...data,
            type: "listOption",
          },
        };
        break;
      case "question":
        newNode = {
          id: nodIde,
          type: "question",
          position: { x: randomX, y: randomY },
          data: {
            text: data.question,
          },
        };
        break;
      case "text":
        newNode = {
          id: nodIde,
          type: "text",
          position: {
            x: randomX,
            y: randomY,
          },
          data: {
            text: data,
          },
        };
        break;
      case "catalog":
        newNode = {
          id: nodIde,
          type: "catalog",
          position: {
            x: randomX,
            y: randomY,
          },
          data: {
            catalog_id: data.catalog_id, 
            catalog_title: data.catalog_title,
            footerText: data.footerText,
            buttonText: data.buttonText,
            messageText: data.messageText,
            headerText: data.headerText,
          },
        };
        break;
      case "staff":
        newNode = {
          id: nodIde,
          type: "staff",
          position: {
            x: randomX,
            y: randomY,
          },
          data: {
            staff_id: data.staff_id, 
            staff_list: data.staff_list,
            sent_message_to_staff :data.sentMessageToStaff,
            staff_message :data.staffMessage,
            lead_category: data.lead_category,
            lead_category_id: data.lead_category_id,
          },
        };
        break;
      case "location":
        newNode = {
          id: nodIde,
          type: "location",
          position: {
            x: randomX,
            y: randomY,
          },
          data: {
            text: data.location,
          },
        };
        break;
      default:
        break;
    }
    console.log("data : ", data);
    console.log("newNode : ", newNode);
    // setNodes((prevNodes) => [...prevNodes, newNode]);
    console.log('UNIQUE ID 2 : ', nodIde );
    console.log('UNIQUE ID 3 : ', newNode.id );





    setNodes((prevNodes) => {
      // todo : check all nodes remove the same id nodes
      // Remove any existing node with the same id
      const filteredNodes = prevNodes.filter((node) => node.id !== id);
      console.log('flterd nodes : ', filteredNodes );
      // Add the new node
      return [...filteredNodes, newNode];
    });
   
  };

  return (
    <div className="dndflow">
      <MyDataProvider>
        <ReactFlowProvider>
        <StatusAlert />
          <div className="reactflow-wrapper" ref={reactFlowWrapper}>
            <ReactFlow
              nodes={nodes}
              edges={edges}
              onNodesDelete={onNodesDelete}
              onNodesChange={onNodesChange}
              onEdgesChange={onEdgesChange}
              onConnect={onConnect}
              onInit={setReactFlowInstance}
              onNodeDragStop={onNodeDragStop}
              // onInit={setRfInstance}
              onDrop={onDrop}
              onDragOver={onDragOver}
              nodeTypes={nodeTypes}
              // fitView
              style={rfStyle}
              // zoom={initialZoom}
              // connectionLineComponent={ConnectionLine}
            >
              <Panel position="top-left">
                {/* <button onClick={() => createNestedStructure(nodes, edges)}>Save</button> */}
              </Panel>

              <Controls />
              <MiniMap position="bottom-left" style={{marginLeft :'50px'}} />
            </ReactFlow>
          </div>
          <Sidebar
            addQuestionNodeFromOutside={addQuestionNodeFromOutside}
            addNodeFromSlider={addNodeFromSlider}
          />
        </ReactFlowProvider>
      </MyDataProvider>
    </div>
  );
};

export default DnDFlow;

// todo : create a function for givving position and add it in all function
