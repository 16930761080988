import { useCallback, useState, useEffect } from "react";
import { Handle, Position } from "reactflow";

import question from "../../assets/planning.png";
import "../node.css";

import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import axios from "axios";

import { useIdProvider} from '../provider/idProvider.jsx';

import PopUpHeader from '../components/popUpAlertHeader.js';
import MessageOutlinedIcon from '@mui/icons-material/MessageOutlined';



function SentCatalogNode({ onChange, editData }) {
  const [selectedCatalogId, setSelectedCatalogId] = useState("");
  const [catalogList, setCatalogList] = useState([]);
 

  const handleChange = (event) => {
    const catalog_id = event.target.value;
    const catalog1 = catalogList.find(catalog => catalog.catalog_id === catalog_id);
    setSelectedCatalogId(catalog_id);
    console.log('Selected Catalog ID:', catalog_id);
    console.log('Selected Catalog :', catalog1.catalog_title);
    // onValueChange({catalog_id:catalog_id });
    const catalog_title= catalog1.catalog_title;
    const footerText= catalog1.footerText;
    const headerText= catalog1.headerText;
    const messageText= catalog1.messageText;
    const buttonText= catalog1.buttonText;
    // onChange({ catalog_id , catalog_title });
    onChange({ catalog_id , catalog_title , footerText,  headerText, buttonText, messageText  });
 };

  const {  uniqueToken ,paramUrl } = useIdProvider();

  useEffect(() => {
    // data has value
    const hasEditData = Boolean(editData);
    if (hasEditData) {
      const { text } = editData;
      console.log("editData :", editData);
      console.log("EditText :", text);
    }
    fetchData();
  }, [editData]);

  const fetchData = async () => {
    const urlParams = new URLSearchParams(window.location.search);
    const tokenParam = urlParams.get("token");
    try {
      console.log(" uniqueToken :", uniqueToken);
      const response = await axios.get(
        `https://${paramUrl}/index.php/Webhook/getCatalogData?token=${tokenParam}`
        // `https://myaccount.login2.in/index.php/Webhook/getCatalogData?token=${tokenParam}`
        // `https://myaccount.login2.in/index.php/Webhook/getCatalogData?token=CZkipLWmbYoweY3WVhLMow%3D%3D`
      );
      console.log(" Initial Function responce");
      console.log(" data :", response.data);

      if (response.data && Array.isArray(response.data.catalogList)) {
        const parsedCatalogList = response.data.catalogList.map(item => ({
            catalog_id: item.catalog_id,
            catalog_title: item.catalog_title,
            footerText: item.footerText,
            headerText: item.headerText,
            messageText: item.messageText,
            buttonText: item.buttonText,
        }));
        setCatalogList(parsedCatalogList); 

        // setSelectedButtonText(response.data.footerText);
        // setSelectedFooterText(response.data.footerText);
        // setSelectedFooterText(response.data.footerText);
    }
    } catch (error) {
      console.log(" Initial Function error");
      console.log(" ERROR :", error);
    }
  };

  return (
    <div className="text-updater-node" style={{padding:'0'}}>
      {/* <PopUpHeader title= 'Send Catalog'   color= '#039855'  Icon={MessageOutlinedIcon} /> */}
      <div
        style={{
          marginLeft: "10px",
          marginRight: "10px",
          marginTop: "10px",
          padding: "0px",
        }}>
        <div  style={{ display: "flex", flexDirection: "row", alignItems: "start" }}>
          <p
            style={{
              margin: "0",
              fontSize: "11px",
              paddingRight: "5px", 
              marginBottom:'5px', color: "#344054",    fontWeight:'bold' 
            }}>Select Catalog </p>{" "}
          <p style={{
              margin: "0",
              fontSize: "15px",
              paddingRight: "20px",
              color: "#344054",    fontWeight:'bold' 
            }}> *</p>{" "}
        </div>
        {
          catalogList.length === 0 ?
          ( <p style={{color:'red',textAlign:'center'}}>No data in catalog</p>)  :
          (<Box sx={{ minWidth: 120 }}>
          <FormControl fullWidth>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              onChange={handleChange}
              value={selectedCatalogId}>
              {catalogList.map(catalog => (
                        <MenuItem key={catalog.catalog_id} value={catalog.catalog_id}>
                            {catalog.catalog_title}
                        </MenuItem>
                    ))}
            </Select>
          </FormControl>
        </Box>)
        }
        
      </div>
      <div
        style={{
          width: "100%",
          paddingBottom: "10px",
          borderRadius: "5px",
        }}
      ></div>
    </div>
  );
}
export default SentCatalogNode;
// todo : show loading on api call
